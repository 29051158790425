import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { BidGroupCampaignsGrid } from './bid-group-campaigns-grid';
import BidGroupsMetricsChart from './bid-group-metrics-chart';
import { BidGroupPlacementHistoryGrid } from './bid-group-placement-history-grid';
import { BidGroupTargetHistoryGrid } from './bid-group-target-history-grid';

interface BidGroupTabbedPerformanceGridsProps {
  bidGroupAcosTarget?: number;
  breadCrumbParams: any;
}

export const RawBidGroupsTabbedPerformanceGrids = (params: BidGroupTabbedPerformanceGridsProps) => {
  const { bidGroupAcosTarget, breadCrumbParams } = params;

  const theme = useTheme();

  const [campaignTimeSeriesData, setCampaignTimeSeriesData] = useState<any[]>([]);
  const [isLoadingCampaignRows, setIsLoadingCampaignRows] = useState(true);

  const [targetChangesCountByDate, setTargetChangesCountByDate] = useState<{ [date: string]: number }>({});

  const [placementChangesCountByDate, setPlacementChangesCountByDate] = useState<{ [date: string]: number }>({});

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  }, []);

  const getChangesCountByDate = (data: any[]): { [date: string]: number } => {
    const changesCount: { [date: string]: number } = {};

    if (!Array.isArray(data)) {
      return changesCount;
    }

    data.forEach((item) => {
      const date = format(new Date(item.createdAt), 'yyyy-MM-dd');
      changesCount[date] = (changesCount[date] || 0) + 1;
    });

    return changesCount;
  };

  return (
    <Box>
      {!!campaignTimeSeriesData && (
        <Box display="flex" alignItems={'center'} my={'1rem'}>
          <BidGroupsMetricsChart
            timeSeriesData={campaignTimeSeriesData} // set in BidGroupCampaignsGrid
            isMetricsChartLoading={isLoadingCampaignRows}
            bidGroupAcosTarget={bidGroupAcosTarget}
            targetChangesCountByDate={targetChangesCountByDate} // set in BidGroupTargetHistoryGrid
            placementChangesCountByDate={placementChangesCountByDate} // set in BidGroupPlacementHistoryGrid
          />
        </Box>
      )}
      <Box
        pl={2}
        sx={{
          backgroundColor: theme.palette.background.paper,
          pt: '0.5rem',
          borderRadius: '0.5rem',
          mb: '0.5rem',
          mt: '1.5rem'
        }}
      >
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Campaigns" sx={{ pt: 0 }} />
          <Tab label="Target History" sx={{ pt: 0 }} />
          <Tab label="Placement History" sx={{ pt: 0 }} />
        </Tabs>
      </Box>
      <Box>
        {(() => {
          switch (tabIndex) {
            case 0:
              return (
                <BidGroupCampaignsGrid
                  breadCrumbParams={breadCrumbParams}
                  isLoadingCampaignRows={isLoadingCampaignRows}
                  setIsLoadingCampaignRows={setIsLoadingCampaignRows}
                  setCampaignTimeSeriesData={setCampaignTimeSeriesData}
                />
              );
            case 1:
              return (
                <BidGroupTargetHistoryGrid
                  breadCrumbParams={breadCrumbParams}
                  getChangesCountByDate={getChangesCountByDate}
                  setTargetChangesCountByDate={setTargetChangesCountByDate}
                />
              );
            case 2:
              return (
                <BidGroupPlacementHistoryGrid
                  breadCrumbParams={breadCrumbParams}
                  getChangesCountByDate={getChangesCountByDate}
                  setPlacementChangesCountByDate={setPlacementChangesCountByDate}
                />
              );
            default:
              return null;
          }
        })()}
      </Box>
    </Box>
  );
};

export const BidGroupsTabbedPerformanceGrids = React.memo(RawBidGroupsTabbedPerformanceGrids);
