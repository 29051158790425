import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import useSharedApi from '../../hooks/use-shared-api';
import useAmazonApi from '../../../amazon/hooks/use-amazon-api';
import useWalmartSamsClubApi from '../../hooks/use-walmart-sams-club-api';
import { enqueueSnackbar } from 'notistack';
import { Platform } from '../../types/platforms';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

interface ProfileCreationModalProps {
  isOpen: boolean;
  onClose: () => void;
  retailer: string;
  existingPlatformProfileIds: string[];
  setIsPageLoading: (isLoading: boolean) => void;
}

interface ClientData {
  clientName: string;
  // For Walmart and SamsClub only
  advertiserIds: string[];
  // For Amazon only
  profiles: {
    profileId: number;
    accountId: string;
  }[];
  refreshToken: string;
  childClientSecurityGroupName: string;
  childClientId: number;
  parentClientId: number;
  parentClientName: string;
}

const ProfileCreationModal = ({
  isOpen,
  onClose,
  retailer,
  existingPlatformProfileIds,
  setIsPageLoading
}: ProfileCreationModalProps) => {
  const { getAdvertizingClientsFromForeman } = useSharedApi();
  const { createWalmartAndSamsProfile } = useWalmartSamsClubApi();
  const { createProfile } = useAmazonApi();
  const [profileName, setProfileName] = useState<string>('');
  const [advertiserIds, setAdvertiserIds] = useState<string[]>([]);
  const [clients, setClients] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState<string | null>(null);
  const [apiResponse, setApiResponse] = useState<ClientData[]>([]);
  const [existingAdvertiserIds, setExistingAdvertiserIds] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleCreateProfile = async () => {
    if (!selectedClient || !selectedAdvertiser || !profileName) {
      enqueueSnackbar('Please fill out all required fields', { variant: 'warning' });
      return;
    }
    setIsPageLoading(true);

    var childClientSecurityGroupName = apiResponse.find(
      (item) => item.clientName === selectedClient
    )!.childClientSecurityGroupName;

    var childClientId = apiResponse.find((item) => item.clientName === selectedClient)!.childClientId;
    var parentClientId = apiResponse.find((item) => item.clientName === selectedClient)!.parentClientId;
    var ForemanChildClientName = apiResponse.find((item) => item.clientName === selectedClient)!.clientName;

    switch (retailer) {
      case 'Walmart':
      case 'SamsClub':
        var walmartAndSamsPayload = {
          PlatformId: selectedAdvertiser,
          Name: profileName,
          ChildClientSecurityGroupName: childClientSecurityGroupName,
          ChildClientId: childClientId,
          ParentClientId: parentClientId,
          ForemanChildClientName: ForemanChildClientName
        };

        var response = await createWalmartAndSamsProfile(walmartAndSamsPayload);

        if (!response.success) {
          enqueueSnackbar('There was an error creating the profile, please try again or contact support.', { variant: 'error' });
        } else {
          enqueueSnackbar(
            "Profile created successfully. We're retrieving your campaign data. Please refresh the page in 5 minutes to view your campaigns and ensure you assign a budget to them.",
            {
              variant: 'success',
              persist: true
            }
          );

          var profileId = response.body.profileId;
          navigate('/walmart/budgets?profileId=' + profileId);
        }

        break;

      case 'Amazon':
        var amazonPayload = {
          advertiserId: selectedAdvertiser,
          name: profileName,
          ChildClientId: childClientId,
          ParentClientId: parentClientId,
          retailer: retailer,
          ChildClientSecurityGroupName: childClientSecurityGroupName,
          platformId: apiResponse
            .find((item) => item.clientName === selectedClient)!
            .profiles.find((profile) => profile.accountId === selectedAdvertiser)!
            .profileId.toString(),
          refreshToken: apiResponse.find((item) => item.clientName === selectedClient)!.refreshToken
        };

        var response = await createProfile(amazonPayload);

        if (!response.success) {
          enqueueSnackbar('There was an error creating the profile, please try again or contact support.', { variant: 'error' });
        } else {
          enqueueSnackbar('Profile created successfully! Data is expected to flow within the hour', { variant: 'success' });
        }
        break;
      default:
        return;
    }

    setIsPageLoading(false);
    onClose();
  };

  const getAdvertiserIdsByClient = (clientName: string): string[] | null => {
    const client = apiResponse.find((item) => item.clientName === clientName);
    if (!client) return null;

    switch (retailer) {
      case Platform.WALMART.label:
      case Platform.SAMS_CLUB.apiValue:
        return client.advertiserIds;
      case Platform.AMAZON.label:
        return client?.profiles.map((profile) => profile.accountId) || [];
      default:
        return client.advertiserIds;
    }
  };

  const getParentClientName = (clientName: string): string => {
    const client = apiResponse.find((item) => item.clientName === clientName);

    if (!client) {
      return '';
    }

    return client.parentClientName;
  };

  useEffect(() => {
    const fetchClients = async () => {
      setIsPageLoading(true);
      const foremanResponse = await getAdvertizingClientsFromForeman(retailer);
      if (foremanResponse.success) {
        setApiResponse(foremanResponse.body);
        setIsPageLoading(false);
      } else {
        enqueueSnackbar("Couldn't fetch clients. Please try again or contact support.", { variant: 'error' });
        setIsPageLoading(false);
      }

      setIsPageLoading(false);
    };

    fetchClients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (apiResponse.length > 0) {
      const clients = apiResponse.map((item) => item.clientName).sort();
      setClients(clients);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (!selectedClient) {
      return;
    }

    const fetchExistingAdvertiserIds = async () => {
      let existingAdvertiserIds: any = [];
      switch (retailer) {
        case Platform.AMAZON.label:
          existingAdvertiserIds = apiResponse
            .filter((item) => item.profiles.some((profile) => existingPlatformProfileIds.includes(profile.profileId.toString())))
            .flatMap((item) =>
              item.profiles
                .filter((profile) => existingPlatformProfileIds.includes(profile.profileId.toString()))
                .map((profile) => profile.accountId)
            );

          setExistingAdvertiserIds(existingAdvertiserIds);
          break;
        case Platform.WALMART.label:
        case Platform.SAMS_CLUB.apiValue:
          existingAdvertiserIds = apiResponse
            .filter((item) => item.clientName === selectedClient)
            .flatMap((item) => item.advertiserIds.filter((id) => existingPlatformProfileIds.toString().includes(id)));

          setExistingAdvertiserIds(existingAdvertiserIds);

          break;
        default:
          return;
      }
    };

    fetchExistingAdvertiserIds();
  }, [selectedClient, retailer, existingPlatformProfileIds, apiResponse]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add Profile</DialogTitle>
      <DialogContent>
        <Autocomplete
          style={{ marginTop: '10px', marginBottom: '20px', width: '550px' }}
          value={selectedClient || null}
          onChange={(_, newValue) => {
            setSelectedClient(newValue);
            setAdvertiserIds(getAdvertiserIdsByClient(newValue || '') || []);
            setProfileName(getParentClientName(newValue || ''));
            setSelectedAdvertiser(null);
          }}
          options={clients}
          fullWidth
          renderInput={(params) => <TextField {...params} label="Select a client *" />}
        />
        <Autocomplete
          style={{ marginBottom: '20px', width: '550px' }}
          getOptionDisabled={(option) => existingAdvertiserIds.includes(option)}
          value={selectedAdvertiser || null}
          onChange={(_, newValue) => setSelectedAdvertiser(newValue)}
          options={advertiserIds}
          fullWidth
          disabled={!selectedClient}
          renderInput={(params) => <TextField {...params} label="Select an advertiser *" />}
        />
        <TextField
          style={{ width: '550px' }}
          fullWidth
          label="Profile Name *"
          value={profileName}
          onChange={(e) => setProfileName(e.target.value)}
        />
      </DialogContent>
      {retailer === Platform.WALMART.label && (
        <Box
          sx={{
            p: 2,
            ml: 3,
            mr: 3,
            backgroundColor: (theme) => alpha(theme.palette.warning.light, 0.8),
            borderRadius: 1,
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'flex-start'
          }}
        >
          <WarningAmberIcon sx={{ marginRight: 1, color: 'black' }} />
          <Typography variant="body1" color="black">
            If adding an account with active campaigns, assign budgets in Budget Manager to keep them active,otherwise, they will
            be paused.
          </Typography>
        </Box>
      )}
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCreateProfile}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileCreationModal;
