import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import React from 'react';
import { CurrencyInput } from '../../../../shared/components/inputs/currency-input';
import { CampaignStructureType, UserSelectedCampaignType } from '../../../types/campaign-builder-request';
import { BiddingStrategies, BiddablePlacements } from '../../../types/campaign';
import { PlacementBidding } from '../../../components/grid-components/renderer/dynamic-bidding/placement-bidding';
import { convertDateToMonthDayYear } from '../../../../shared/utilities/date-utilities';
import dayjs from 'dayjs';

interface CampaignSettingsProps {
  values: any;
  touched: any;
  errors: any;
  isSubmitting: boolean;
  isValid: boolean;
  dirty: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  userSelectedCampaignType: UserSelectedCampaignType;
}

export const CampaignSettings = (props: CampaignSettingsProps) => {
  const { values, touched, errors, isSubmitting, isValid, dirty, setFieldValue, handleBlur, userSelectedCampaignType } = props;

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Typography variant="h5" component="div" sx={{ mr: 1 }}>
            Campaign Settings
          </Typography>
          {userSelectedCampaignType !== UserSelectedCampaignType.MULTIPLE_ITEMS_MULTIPLE_TARGETS && (
            <Tooltip
              title="These settings will be applied to all campaigns. These settings can be adjusted on a campaign-by-campaign basis in the review step."
              placement="top"
            >
              <InfoIcon color="info" />
            </Tooltip>
          )}
        </Box>

        <Grid container spacing={2}>
          {[
            CampaignStructureType.MULTI_ITEM_MULTI_KEYWORD_CAMPAIGN.toString(),
            CampaignStructureType.MULTI_ITEM_MULTI_PRODUCT_TARGET_CAMPAIGN.toString()
          ].includes(values.campaignStructureType?.toString() ?? '') ? (
            <Grid item xs={3}>
              <TextField
                size="small"
                name="name"
                label="Campaign Name"
                error={touched?.name && Boolean(errors.name)}
                helperText={(touched?.name && Boolean(errors.name) ? errors?.name : '') || ''}
                value={values.name}
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('name', event.target.value)}
              />
            </Grid>
          ) : null}
          <Grid item xs={3}>
            <DatePicker
              label="Start Date"
              value={dayjs(values.startDate)}
              slotProps={{
                actionBar: { actions: ['clear'] },
                textField: {
                  size: 'small',
                  onBlur: handleBlur,
                  name: 'startDate',
                  error: Boolean(errors.startDate),
                  helperText: errors?.startDate || ''
                }
              }}
              onChange={(date) => {
                setFieldValue('startDate', date);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              label="End Date"
              value={dayjs(values.endDate)}
              slotProps={{
                actionBar: { actions: ['clear'] },
                textField: {
                  size: 'small',
                  onBlur: handleBlur,
                  name: 'endDate',
                  error: Boolean(errors.endDate),
                  helperText: errors?.endDate || ''
                }
              }}
              onChange={(date) => {
                setFieldValue('endDate', date);
              }}
              minDate={dayjs(values.startDate)}
            />
          </Grid>
          <Grid item xs={3}>
            <CurrencyInput
              size="small"
              name="Budget"
              label="Budget"
              value={values.dailyBudget}
              onChange={(event) => setFieldValue('dailyBudget', event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ mt: 2 }}>
              <Typography variant="h6" component="div">
                Bidding Strategy
              </Typography>
              <RadioGroup
                value={values.amazonSponsoredProductsDynamicBidding.strategy}
                onChange={(event) => setFieldValue('amazonSponsoredProductsDynamicBidding.strategy', event.target.value)}
              >
                {Object.keys(BiddingStrategies).map((key) => (
                  <FormControlLabel
                    key={key}
                    value={BiddingStrategies[key as keyof typeof BiddingStrategies].value}
                    control={<Radio />}
                    label={BiddingStrategies[key as keyof typeof BiddingStrategies].label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" component="div" sx={{ mt: 2 }}>
              Placement Multipliers
            </Typography>
            {Object.keys(BiddablePlacements).map((key) => (
              <Grid item xs={12} key={key}>
                <PlacementBidding
                  placement={BiddablePlacements[key as keyof typeof BiddablePlacements].value}
                  percentage={
                    values.amazonSponsoredProductsDynamicBidding.placementBidding.find(
                      (placement: any) =>
                        placement?.placement === BiddablePlacements[key as keyof typeof BiddablePlacements].value
                    )?.percentage
                  }
                  setPercentage={(percentage: number | undefined) => {
                    setFieldValue(
                      'amazonSponsoredProductsDynamicBidding.placementBidding',
                      values.amazonSponsoredProductsDynamicBidding.placementBidding.map((placement: any) => {
                        if (placement.placement === BiddablePlacements[key as keyof typeof BiddablePlacements].value) {
                          return {
                            ...placement,
                            percentage
                          };
                        }
                        return placement;
                      })
                    );
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
