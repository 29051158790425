import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Skeleton, Stack } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { chunk, uniq } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MetricsChart from '../../../../shared/components/metrics-chart';
import VectorPerformanceGrid from '../../../../shared/components/performance-grid/vector-performance-grid';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../../shared/types/pageable';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import TargetsBulkActions from '../../../components/grid-components/bulk-actions/targets/targets-bulk-actions';
import { createColumns } from '../../../components/grid-components/column-configurations/target-columns';
import { BreadCrumbParams, useAmazonApi } from '../../../hooks/use-amazon-api-v2';
import { Campaign } from '../../../types/campaign';
import { TargetUpdateDto } from '../../../types/target';

import useDialog from '../../../../shared/hooks/use-dialog';
import useSharedApi from '../../../../shared/hooks/use-shared-api';
import useVectorPerformanceGrid from '../../../../shared/hooks/use-vector-performance-grid';
import { AmazonExpressionTypes } from '../../../types/targeting-refinements';
import CreateTargetsDialog from './create-dialogs/create-targets-dialog';

enum Action {
  None,
  CreateTargets,
  CreateKeywords
}

interface AdGroupTargetsProps {
  profileId: string | null;
  campaignId: string | null;
  adGroupId: string | null;
  campaign: Campaign | null;
}

export const AdGroupTargets: React.FC<AdGroupTargetsProps> = ({ profileId, campaignId, adGroupId, campaign }) => {
  const { getTargets, updateTarget, searchCatalogItems } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [targets, setTargets] = useState<any[]>([]);
  const [productInfo, setProductInfo] = useState<any[] | null>(null);
  const [isLoadingProductInfo, setIsLoadingProductInfo] = useState<boolean>(true);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowCount, setRowCount] = useState<number>(0);

  const { isShowing: isShowingCreateTargetsDialog, toggle: toggleCreateTargetsDialog } = useDialog();

  const [adGroupHasTargets, setAdGroupHasTargets] = useState(false);
  const [campaignTargetTypeIsKeyword, setCampaignTargetTypeIsKeyword] = useState(false);

  const [action, setAction] = useState<Action>(Action.None);

  const breadcrumbParams: BreadCrumbParams[] = useMemo(
    () => [{ profileId }, { campaignId }, { adGroupId }],
    [profileId, campaignId, adGroupId]
  );

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  } = useVectorPerformanceGrid(GridConfigSettings.AMAZON_TARGETS);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
  }>();
  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState<boolean>(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchTargetsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchTargetsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    setIsLoading(true);

    const response = await getTargets([...breadcrumbParams], pageable, beginDate, endDate, true);

    if (response.success && response.body) {
      setTargets(response.body.dataGrid.records);
      setRowCount(response.body.dataGrid.totalFilteredRecords || DEFAULT_ROW_COUNT);
      setTimeSeriesData(response.body.timeSeriesData);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const fetchProductInfo = async () => {
    setIsLoadingProductInfo(true);

    const asins = targets
      .map((target) => {
        return (
          target?.expression?.find((e: any) => e.type.toLowerCase() === AmazonExpressionTypes.asinSameAs.toLowerCase())?.value ??
          target?.expression?.find((e: any) => e.type.toLowerCase() === AmazonExpressionTypes.asinExpandedFrom.toLowerCase())
            ?.value
        );
      })
      .filter((asin: any) => asin);

    const uniqueAsins = uniq(asins);
    if (uniqueAsins.length < 1) {
      setIsLoadingProductInfo(false);
      return;
    }

    const chunkedAsins = chunk(uniqueAsins, 20);

    const requests = chunkedAsins.map((asins) => {
      return searchCatalogItems(asins);
    });

    const responses = await Promise.all(requests);

    const combinedProductInfo = responses.reduce((acc: any, response: any) => {
      return [...acc, ...response.body];
    }, []);

    setProductInfo(combinedProductInfo);
    setIsLoadingProductInfo(false);
  };

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    let updateTargetsRequest: TargetUpdateDto = { targetId: newRow.targetId };

    if (newRow.bid !== oldRow.bid) {
      updateTargetsRequest = { ...updateTargetsRequest, bid: newRow.bid };
    }

    if (newRow.state !== oldRow.state) {
      updateTargetsRequest = { ...updateTargetsRequest, state: newRow.state };
    }

    if (updateTargetsRequest.bid == null && updateTargetsRequest.state == null) {
      return oldRow;
    }

    const response = await updateTarget(updateTargetsRequest);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    const updatedSyncState = response.body[0].syncState;

    return { ...newRow, syncState: updatedSyncState };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = React.useMemo(() => {
    return createColumns(productInfo, isLoadingProductInfo);
  }, [productInfo, isLoadingProductInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  // #region Helper Functions for Create Targets Dialog

  useEffect(() => {
    if (targets && targets.length > 0) {
      setAdGroupHasTargets(true);
      setCampaignTargetTypeIsKeyword(targets[0]?.targetType === 'Keyword');
      fetchProductInfo();
    } else {
      setAdGroupHasTargets(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targets]);

  useEffect(() => {
    if (action !== Action.None) {
      toggleCreateTargetsDialog();
    }
  }, [action]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggleCreateTargetsDialog = () => {
    toggleCreateTargetsDialog();
    setAction(Action.None);
  };

  const handleCreateTargetsClick = () => {
    setCampaignTargetTypeIsKeyword(false);
    setAction(Action.CreateTargets);
  };

  const handleCreateKeywordsClick = () => {
    setCampaignTargetTypeIsKeyword(true);
    setAction(Action.CreateKeywords);
  };

  const renderCreateTargetsButton = () => {
    if (isLoading || !campaign) {
      return <Skeleton width={210} height={40} />;
    }

    if (campaign.targetingType?.toLowerCase() === 'auto') {
      return <Box sx={{ height: '40px', visibility: 'hidden' }}></Box>;
    }

    if (adGroupHasTargets) {
      return (
        <Button variant="contained" startIcon={<AddIcon fontSize="small" />} onClick={handleToggleCreateTargetsDialog}>
          Create Targets
        </Button>
      );
    }

    return (
      <Stack direction="row" spacing={2}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleCreateTargetsClick} sx={{ ml: 2 }}>
          Create Targets
        </Button>
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleCreateKeywordsClick}>
          Create Keywords
        </Button>
      </Stack>
    );
  };

  // #endregion

  if (!settingsLoaded || dateSettingsLoading || !dateSettings) {
    return (
      <DataGridPremium
        rows={[]}
        columns={columns}
        loading={true}
        processRowUpdate={processRowUpdate}
        initialState={{}}
        disableRowGrouping
      />
    );
  }

  return (
    <>
      <Box display="flex" alignItems="center" my={'1%'}>
        <MetricsChart
          timeSeriesData={timeSeriesData}
          isMetricsChartLoading={isLoading}
          chartSettingsKey="AmazonCampaignOverviewAdGroupTargetsChartSetting"
        />
      </Box>
      <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
        <Box sx={{ mr: 'auto' }}></Box>
        {renderCreateTargetsButton()}
      </Box>
      <VectorPerformanceGrid
        apiRef={apiRef}
        processRowUpdate={processRowUpdate}
        loading={isLoading}
        rows={targets}
        columns={columns}
        initialState={initialSettings?.config}
        saveGridConfig={saveGridConfig}
        dateConfig={{
          dateSettings: dateSettings,
          dateSettingsLoading: dateSettingsLoading,
          saveDateConfig: (dateRange: DateRange<Dayjs>) =>
            handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchTargetsData, setDateSettings)
        }}
        bottomMargin={30}
        getRowId={(row: any) => row.targetId}
        bulkActions={<TargetsBulkActions setIsLoading={setIsLoading} />}
        handleFilterModelChange={(newModel: GridFilterModel) => {
          handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchTargetsData);
        }}
        handleSortModelChange={(newModel: GridSortModel) => {
          handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchTargetsData);
        }}
        handlePageChange={(page: number) => {
          handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchTargetsData);
        }}
        handlePageSizeChange={(pageSize: number) => {
          handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchTargetsData);
        }}
        rowCount={rowCount}
        otherDataGridProps={{ autoHeight: targets?.length < 10 }}
      />
      {isShowingCreateTargetsDialog && (
        <CreateTargetsDialog
          profileId={profileId}
          campaignId={campaignId}
          adGroupId={adGroupId}
          campaignType={campaign?.campaignType}
          campaignTargetTypeIsKeyword={campaignTargetTypeIsKeyword}
          isShowing={isShowingCreateTargetsDialog}
          toggle={handleToggleCreateTargetsDialog}
          refreshTargets={() => handleRefreshEntities(settingsLoaded, dateSettings, apiRef, fetchTargetsData)}
          existingTargets={targets}
        />
      )}
    </>
  );
};
