import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Container, IconButton, Paper, Skeleton, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Page from '../../../shared/components/page';
import useDialog from '../../../shared/hooks/use-dialog';
import { useAmazonApi } from '../../hooks/use-amazon-api';
import { BidOptimizationGroupDto, BidOptimizationGroupStatus } from '../../types/bid-optimization-group';
import { getUpdateCampaignRequest } from '../../types/campaign';
import { BidGroupsTabbedPerformanceGrids } from './bid-group-tabbed-performance-grids-with-metrics-chart';
import BidGroupUpdateModal from './bid-group-update-modal';

const BidGroupOverview: FC = () => {
  const { getCampaigns, updateCampaign, getBidOptimizationGroup, updateBidOptimizationGroup } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [bidGroup, setBidGroup] = useState<BidOptimizationGroupDto>();
  const [acosTarget, setAcosTarget] = useState<number | null>(null);

  const [searchParams] = useSearchParams();
  const profileId = searchParams.get('profileId');
  const bidGroupId = searchParams.get('bidGroupId');

  const isInvalidAcosTarget = useMemo(() => {
    return acosTarget !== null && (acosTarget < 0 || acosTarget > 100);
  }, [acosTarget]);

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const changedFields = Object.keys(newRow).filter((key) => newRow[key] !== oldRow[key]);

    if (changedFields.length == 0) {
      return oldRow;
    }

    const updateCampaignRequest = getUpdateCampaignRequest(newRow, oldRow);
    const response = await updateCampaign({ campaignId: newRow.campaignId, ...updateCampaignRequest });

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    const updatedSyncState = response.body[0].syncState;

    return { ...newRow, syncState: updatedSyncState };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBidGroupOverview = useCallback(async () => {
    if (!bidGroupId) {
      return;
    }

    const response = await getBidOptimizationGroup(bidGroupId);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return;
    }

    setBidGroup(response.body);
  }, [bidGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleStatusChange = async (event: any) => {
    const status = event.target.checked ? BidOptimizationGroupStatus.Enabled : BidOptimizationGroupStatus.Disabled;

    const response = await updateBidOptimizationGroup({
      ...bidGroup,
      status: status
    } as BidOptimizationGroupDto);

    if (!response.success) {
      enqueueSnackbar(response.body[0].details, { variant: 'error' });
      return;
    }

    setBidGroup(response.body);
    enqueueSnackbar(`Bid group successfully ${status.toLowerCase()}`, { variant: 'success' });
  };

  const handleAcosTargetChange = async (newValue: number) => {
    if (!bidGroup || isInvalidAcosTarget || bidGroup.acosTarget === newValue) {
      return;
    }

    const response = await updateBidOptimizationGroup({
      ...bidGroup,
      acosTarget: newValue
    } as BidOptimizationGroupDto);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return;
    }

    enqueueSnackbar(`Bid group target ACoS updated${bidGroup ? ' from ' + bidGroup.acosTarget.toString() : ''} to ${newValue}`, {
      variant: 'success'
    });
    setBidGroup(response.body);
  };

  useEffect(() => {
    if (!bidGroup) {
      return;
    }

    setAcosTarget(bidGroup.acosTarget);
  }, [bidGroup]);

  useEffect(() => {
    fetchBidGroupOverview();
  }, [bidGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!profileId || !bidGroupId) {
      navigate('/amazon/campaigns');
    }
  }, [profileId, bidGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  const { toggle: updateModalToggle, isShowing: updateModalIsShowing } = useDialog();

  const handleUpdateModalSave = async (updatedBidGroup: BidOptimizationGroupDto) => {
    const response = await updateBidOptimizationGroup(updatedBidGroup);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return;
    }

    setBidGroup(response.body);
    enqueueSnackbar('Bid group updated successfully', { variant: 'success' });
    updateModalIsShowing && updateModalToggle();
  };

  return (
    <>
      <Page title="Bid Group Overview">
        <Container maxWidth="xl" sx={{ height: '100%' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Box display="flex" alignItems={'center'}>
              <Paper elevation={0} sx={{ p: 2, width: '100%' }}>
                <Box display="flex" flexDirection={'row'} alignItems={'center'}>
                  <Box mr={2}>
                    <Tooltip
                      title={bidGroup !== null ? `Currently ${bidGroup?.status.toLowerCase()}` : null}
                      enterDelay={1000}
                      enterNextDelay={1000}
                    >
                      <Switch
                        key={`${bidGroup?.id}-status-toggle`}
                        checked={bidGroup?.status === BidOptimizationGroupStatus.Enabled}
                        color="primary"
                        size="small"
                        onChange={handleStatusChange}
                      />
                    </Tooltip>
                  </Box>
                  {!!bidGroup ? (
                    <Box display="flex" flexDirection={'column'} alignItems={'left'}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="h5" mr={2}>
                          {bidGroup?.name}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Skeleton variant="text" width={250} height={40} />
                  )}
                  <Box
                    sx={{
                      mr: 'auto'
                    }}
                  ></Box>
                  <Box mr={2} display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                    <TextField
                      label="Target ACoS Percentage"
                      InputLabelProps={{ shrink: true }}
                      name="acosTarget"
                      value={acosTarget}
                      type="number"
                      InputProps={{
                        inputProps: { min: 1 },
                        endAdornment: (
                          <Typography color={'text.secondary'} variant="body1">
                            %
                          </Typography>
                        )
                      }}
                      sx={{ width: '100%' }}
                      error={isInvalidAcosTarget}
                      helperText={isInvalidAcosTarget ? 'Enter a valid ACoS target' : null}
                      onChange={(event) =>
                        setAcosTarget(isNaN(parseInt(event.target.value)) ? null : parseInt(event.target.value))
                      }
                      onBlur={(event) =>
                        isNaN(parseInt(event.target.value))
                          ? setAcosTarget(bidGroup?.acosTarget ?? null)
                          : handleAcosTargetChange(parseInt(event.target.value))
                      }
                    />
                    <IconButton onClick={updateModalToggle}>
                      <SettingsIcon />
                    </IconButton>
                  </Box>

                  <Box>
                    {bidGroup &&
                      (updateModalToggle ? (
                        <BidGroupUpdateModal
                          bidGroup={bidGroup}
                          isOpen={updateModalIsShowing}
                          onClose={updateModalToggle}
                          onSave={handleUpdateModalSave}
                        />
                      ) : null)}
                  </Box>
                </Box>
              </Paper>
            </Box>
            {!!bidGroup && (
              <BidGroupsTabbedPerformanceGrids
                breadCrumbParams={[{ campaignBidOptimizationGroupId: bidGroupId }]}
                bidGroupAcosTarget={bidGroup.acosTarget}
              />
            )}
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default BidGroupOverview;
