import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import Page from '../../../shared/components/page';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import { BreadCrumbParams, useAdsApi } from '../../../shared/hooks/use-wmt-map-updated-api';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { Platform } from '../../../shared/types/platforms';
import { getUpdateCampaignRequest } from '../../../shared/types/walmart-sams-club/campaign';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { CAMPAIGN_COLUMNS } from '../../components/grid-components/column-configurations/campaign-columns';

const Campaigns: FC = () => {
  const { getCampaigns, updateCampaign, getCampaignTotals } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [campaignTotals, setCampaignTotals] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  let [searchParams] = useSearchParams();
  let profileId = useMemo(() => (searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null), [searchParams]);

  const breadcrumbParams: BreadCrumbParams[] = useMemo(() => [{ profileId }], [profileId]);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange
  } = useVectorPerformanceGrid(GridConfigSettings.SAMS_CLUB_CAMPAIGN);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchCampaignsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCampaignsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    if (!breadcrumbParams) {
      return;
    }

    setIsLoading(true);

    const [campaignsResponse, totalsResponse] = await Promise.all([
      getCampaigns([...breadcrumbParams], pageable, beginDate, endDate, true),
      getCampaignTotals([...breadcrumbParams], pageable, beginDate, endDate)
    ]);

    if (campaignsResponse.success) {
      setCampaigns(campaignsResponse.body?.records);
      setRowCount(campaignsResponse.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(campaignsResponse.errorMessage, { variant: 'error' });
    }

    if (totalsResponse.success) {
      setCampaignTotals(totalsResponse.body);
    } else {
      enqueueSnackbar(totalsResponse.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const updateCampaignRequest = getUpdateCampaignRequest(newRow, oldRow);

    if (!updateCampaignRequest) {
      return oldRow;
    }

    setIsLoading(true);

    const response = await updateCampaign(updateCampaignRequest);

    setIsLoading(false);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    return newRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Page title="Campaigns">
        <Container maxWidth="xl" style={{ height: '93vh', marginTop: '10px' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Box display="flex" alignItems={'center'}>
              <Typography variant="h4">Campaigns</Typography>
              <Box
                sx={{
                  mr: 'auto'
                }}
              ></Box>
              <Link
                to={`/${Platform.SAMS_CLUB.value}/campaigns/create${profileId ? `?profileId=${profileId}` : ''}`}
                component={RouterLink}
                style={{ textDecoration: 'none' }}
              >
                <Button startIcon={<AddIcon fontSize="small" />} variant="contained">
                  Create Campaign
                </Button>
              </Link>
            </Box>
            {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
              <DataGridPremium
                rows={[]}
                disableRowGrouping
                columns={CAMPAIGN_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
              />
            ) : (
              <>
                <VectorPerformanceGrid
                  apiRef={apiRef}
                  loading={isLoading}
                  processRowUpdate={processRowUpdate}
                  rows={campaigns}
                  columns={CAMPAIGN_COLUMNS}
                  initialState={initialSettings?.config}
                  saveGridConfig={saveGridConfig}
                  dateConfig={{
                    dateSettings: dateSettings,
                    dateSettingsLoading: dateSettingsLoading,
                    saveDateConfig: (dateRange: DateRange<Dayjs>) =>
                      handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchCampaignsData, setDateSettings)
                  }}
                  getRowId={(row: any) => row.campaignId}
                  getRowClassName={(params) => `row-status--${params.row.status}`}
                  handleFilterModelChange={(newModel: GridFilterModel) => {
                    handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                  }}
                  handleSortModelChange={(newModel: GridSortModel) => {
                    handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                  }}
                  handlePageChange={(page: number) => {
                    handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                  }}
                  handlePageSizeChange={(pageSize: number) => {
                    handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                  }}
                  rowCount={rowCount}
                  aggregatedTotals={campaignTotals}
                />
              </>
            )}
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default Campaigns;
