import SettingsIcon from '@mui/icons-material/Settings';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { KeywordHarvestingSettings, KEYWORD_HARVESTING_COLUMNS, SETTINGS_TYPES } from '../../types/keyword-harvesting';
import { Filter, Pageable, buildPageableFilters } from '../../../shared/types/pageable';
import KeywordApplyConfirmationDialog from './confirm-apply-keywords-dialog';
import { KeywordHarvestingSettingsDialog } from './keyword-harvesting-dialog';

interface UpdateSettingsAndPreviewKeywordsProps {
  settings: KeywordHarvestingSettings;
}

export const UpdateSettingsAndPreviewKeywords = (props: UpdateSettingsAndPreviewKeywordsProps) => {
  const { settings: settingsFromProps } = props;

  let [searchParams, setSearchParams] = useSearchParams();
  let profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;
  let campaignId = searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null;

  const {
    getKeywords,
    getKeywordHarvestingSettingsForCampaign,
    getPreviewOfHarvestedKeywords,
    updateKeywordHarvestingSettings,
    applyHarvestedKeywords,
    getCampaign
  } = useAdsApi();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewOfHarvestedKeywords, setPreviewOfHarvestedKeywords] = useState([]);
  const [existingKeywords, setExistingKeywords] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [settings, setSettings] = useState<KeywordHarvestingSettings>(settingsFromProps);
  const [keywordHarvestingDialogOpen, setKeywordHarvestingDialogOpen] = useState(false);
  const [openApplyConfirmation, setOpenApplyConfirmation] = useState(false);
  const [campaign, setCampaign] = useState<any>();

  useEffect(() => {
    if (!profileId || !campaignId) {
      return;
    }

    const pageableFilters: Filter[] = buildPageableFilters({ ...{ profileId, campaignId } });

    const pageable = {
      filters: pageableFilters,
      sorts: [],
      offset: 0,
      limit: 250
    } as Pageable;

    const initialize = async () => {
      setIsLoading(true);
      await Promise.all([fetchPreviewOfHarvestedKeywords(), fetchCampaign(), fetchExistingKeywords(pageable)]);
      setIsLoading(false);
    };

    initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, campaignId]);

  const fetchPreviewOfHarvestedKeywords = async () => {
    if (!campaignId) {
      return;
    }

    const response = await getPreviewOfHarvestedKeywords(campaignId);

    if (response.success) {
      setPreviewOfHarvestedKeywords(response.body.records);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const fetchExistingKeywords = async (pageable: Pageable) => {
    if (!campaignId) {
      return;
    }

    const response = await getKeywords(pageable);

    if (response.success) {
      setExistingKeywords(response.body.records);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const fetchCampaign = async () => {
    if (!profileId || !campaignId) {
      return;
    }

    const response = await getCampaign(profileId, campaignId);

    if (response.success) {
      setCampaign(response.body);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const handleSubmit = async (updatedSettings: KeywordHarvestingSettings): Promise<void> => {
    if (!campaignId) {
      return;
    }

    setIsSubmitting(true);

    const response = await updateKeywordHarvestingSettings(updatedSettings);

    if (response.success) {
      setSettings(response.body);
      await fetchPreviewOfHarvestedKeywords();
      enqueueSnackbar('Keyword Harvesting settings updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsSubmitting(false);
  };

  const handleApply = async () => {
    if (!campaignId) {
      return;
    }

    setIsSubmitting(true);

    const response = await applyHarvestedKeywords(campaignId);

    if (response.success) {
      enqueueSnackbar('Keywords applied successfully', { variant: 'success' });
      navigate(`/walmart/keywords?profileId=${profileId}&campaignId=${campaignId}`);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsSubmitting(false);
  };

  const handleClose = () => {
    setOpenApplyConfirmation(false);
  };

  return (
    <Container>
      {isLoading ? (
        <Box>
          <Box sx={{ height: '40px' }}>
            <Skeleton sx={{ height: '40px' }} />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Skeleton sx={{ height: '40px' }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton sx={{ height: '40px' }} />
            </Grid>
          </Grid>

          <Skeleton sx={{ height: '800px' }} />
          <Skeleton sx={{ height: '400px' }} />
        </Box>
      ) : (
        <Box>
          <Box sx={{ my: 2 }}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5">
                  Harvested Keywords for {campaign?.name} {settings.isAutomationEnabled ? '' : '(Preview)'}
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={() => setOpenApplyConfirmation(true)}>
                  Apply Keywords to Campaign
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Typography variant="h6">Harvesting Rules:</Typography>
            <IconButton onClick={() => setKeywordHarvestingDialogOpen(true)}>
              <SettingsIcon />
            </IconButton>
          </Box>
          <Box>
            {!!settings &&
              Object.entries(settings)
                .filter(([key, value]) => key === 'defaultBid' || key === 'applyAsEnabled')
                .map(([key, value]) => (
                  <Chip
                    key={key}
                    label={`${SETTINGS_TYPES.find((settingType) => settingType.value === key)?.label}: ${
                      key === 'defaultBid' ? '$' : ''
                    }${value}`}
                    sx={{ m: 0.5 }}
                  />
                ))}
          </Box>
          <Divider sx={{ m: 1 }} />
          <Box sx={{ overflowX: 'auto' }}>
            <Typography variant="caption" sx={{ display: 'flex' }}>
              Keyword Text Does Not Contain:
            </Typography>
            {!!settings &&
              settings.keywordTextDoesNotContain?.length > 0 &&
              (settings.keywordTextDoesNotContain as string[]).map((keyword: string) => (
                <Chip key={keyword} label={`${keyword}`} sx={{ m: 0.5 }} />
              ))}
          </Box>
          <Divider sx={{ m: 1 }} />
          <Box sx={{ mb: 2 }}>
            <Typography variant="caption" sx={{ display: 'flex' }}>
              Metric Rules:
            </Typography>
            <Box>
              {!!settings &&
                Object.entries(settings)
                  .filter(([key, value]) => key === 'metricMinimums')
                  .map(([key, value]) =>
                    value.map((metric: any) => (
                      <Chip key={metric.metricType} label={`${metric.metricType}: ${metric.value}`} sx={{ m: 0.5 }} />
                    ))
                  )}
            </Box>
          </Box>
          <Box sx={{ height: '400px' }}>
            <DataGrid
              rows={previewOfHarvestedKeywords}
              columns={KEYWORD_HARVESTING_COLUMNS}
              getRowId={(row: { keywordText: string; clicks: number; cost: number }) =>
                row?.keywordText + row?.clicks + row?.cost
              }
              loading={isLoading}
            />
          </Box>
          {isLoading ? null : (
            <Formik initialValues={settings} onSubmit={handleSubmit} enableReinitialize>
              {({ values, isSubmitting, isValid, dirty, setFieldValue, resetForm }) => (
                <Form>
                  <Divider sx={{ my: 6 }} />
                  {existingKeywords.length > 0 ? (
                    <Box sx={{ height: '400px', my: 5 }}>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        Existing Keywords for {campaign?.name}
                      </Typography>
                      <DataGrid
                        rows={existingKeywords}
                        columns={KEYWORD_HARVESTING_COLUMNS}
                        getRowId={(row: { keywordId: string }) => row?.keywordId}
                        loading={isLoading}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ hy: 5 }}>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        No existing keywords for {campaign?.name}
                      </Typography>
                    </Box>
                  )}
                  {keywordHarvestingDialogOpen ? (
                    <KeywordHarvestingSettingsDialog
                      open={keywordHarvestingDialogOpen}
                      onClose={() => {
                        resetForm();
                        setKeywordHarvestingDialogOpen(false);
                      }}
                      handleSubmit={handleSubmit}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  ) : null}
                  {openApplyConfirmation ? (
                    <KeywordApplyConfirmationDialog
                      isOpen={openApplyConfirmation}
                      onClose={handleClose}
                      onApply={handleApply}
                      profileId={profileId || 0}
                      campaignId={campaignId || 0}
                    />
                  ) : null}
                </Form>
              )}
            </Formik>
          )}
        </Box>
      )}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};
