import { TooltipProps, Tooltip, tooltipClasses, styled } from '@mui/material';
import { GridEditInputCell, GridPreProcessEditCellProps, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { AMAZON_TARGET_MINIMUM_BID } from '../../../types/target';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      height: '100%'
    }
  })
);

function BidEditInputCell(props: GridRenderEditCellParams) {
  const { error } = props;

  let typeSafeProps: GridRenderEditCellParams = { ...props, error: typeof props.error === 'string' && props.error !== '' };

  return (
    <StyledTooltip open={!!error} title={error}>
      <span style={{ height: '100%', width: '100%' }}>
        <GridEditInputCell
          {...typeSafeProps}
          sx={{ border: 1, borderColor: !!error ? 'error.main' : 'success.main', borderRadius: '4px' }}
        />
      </span>
    </StyledTooltip>
  );
}

export function renderEditBid(params: GridRenderEditCellParams) {
  return <BidEditInputCell {...params} />;
}

const validateBid = async (params: GridPreProcessEditCellProps) => {
  if (Number(params.props.value < AMAZON_TARGET_MINIMUM_BID)) {
    return 'Bid must be greater than or equal to $' + AMAZON_TARGET_MINIMUM_BID;
  }

  return '';
};

export const preProcessBidEditCellProps = async (params: GridPreProcessEditCellProps) => {
  const errorMessage = await validateBid(params);
  return { ...params.props, error: errorMessage };
};
