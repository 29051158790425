import { Box, Button, CircularProgress, Divider, TextField, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { State } from '../../../types/campaign-builder-request';
import { ProductMetadataRequest } from '../../../types/product-metadata';
import AddAsinsAlert from './add-asins-alert';
import { CampaignItemsTable } from './campaign-items-table';
import { CampaignBuilderItem } from '../../../types/campaign-builder-item';

interface CampaignTypeStepProps {
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  profileId: string | null;
}

// max according to https://advertising.amazon.com/API/docs/en-us/product-metadata#tag/Product-Selector/operation/ProductMetadata
const MAX_ITEMS = 300;

export const AddItems: FC<CampaignTypeStepProps> = (props) => {
  const { values, setFieldValue, profileId, ...other } = props;

  const [invalidAsins, setInvalidAsins] = useState<string[]>([]);
  const [userInputItemIds, setUserInputItemIds] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [itemSearchIsLoading, setItemSearchIsLoading] = useState(false);
  const [inputError, setInputError] = useState<string | null>(null);

  const { getProductMetadata } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [showStep, setShowStep] = useState<boolean>(true);

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  const validateItemCount = (input: string): boolean => {
    const asins = input
      .replace(/,\s+/g, ',')
      .split(/[\n,\s+]/)
      .filter((asin) => asin.trim());
    const existingItemsCount = values.items.length;
    const newUniqueAsins = asins.filter((asin) => !values.items.some((item: any) => item.asin === asin));
    const totalCount = existingItemsCount + newUniqueAsins.length;

    if (totalCount > MAX_ITEMS) {
      setInputError(
        `Cannot add more than ${MAX_ITEMS} items. Currently at ${existingItemsCount} items, trying to add ${newUniqueAsins.length} new items.`
      );
      return false;
    }

    setInputError(null);
    return true;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setUserInputItemIds(newValue);
    validateItemCount(newValue);
  };

  const addItems = async () => {
    if (!profileId) {
      enqueueSnackbar('Please select a Profile before adding Items', { variant: 'error' });
      return;
    }

    if (!validateItemCount(userInputItemIds)) {
      enqueueSnackbar(`Cannot exceed ${MAX_ITEMS} items limit`, { variant: 'error' });
      return;
    }

    setItemSearchIsLoading(true);

    const asins = userInputItemIds.replace(/,\s+/g, ',').split(/[\n,\s+]/);
    const newAsins = asins.filter((asin) => !values.items.some((item: any) => item.asin === asin) && asin);

    // If user has not input any new item ids don't call api
    if (newAsins.length < 1) {
      setItemSearchIsLoading(false);
      return;
    }

    const requestData: ProductMetadataRequest = {
      asins: newAsins,
      adType: 'SP',
      pageSize: MAX_ITEMS,
      pageIndex: 0,
      checkItemDetails: true,
      checkEligibility: true
    };

    const response = await getProductMetadata(profileId, requestData);

    if (response.success) {
      const foundProductMetadataItems = response.body?.productMetadataList;

      const foundItems = foundProductMetadataItems.map((item: CampaignBuilderItem) => {
        return {
          asin: item.asin,
          title: item.title,
          imageUrl: item.imageUrl,
          eligibilityStatus: item.eligibilityStatus,
          state: State.ENABLED,
          bid: 0,
          keywords: [],
          targetingClauses: [],
          itemNickname: null
        };
      });

      setInvalidAsins(newAsins.filter((asin: string) => !foundItems.some((item: any) => item.asin === asin)));

      setFieldValue('items', [...values.items, ...foundItems]);

      setIsAlertOpen(true);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
    setItemSearchIsLoading(false);
  };

  const removeItem = (item: CampaignBuilderItem) => {
    setIsAlertOpen(false);
    const filteredItems = values.items.filter((campaignItem: CampaignBuilderItem) => campaignItem.asin !== item.asin);
    setFieldValue('items', filteredItems);
  };

  return (
    <Box display={'flex'}>
      <Box sx={{ width: '20%', pr: 2 }}>
        <TextField
          id="campaignItemIds"
          label="Items"
          onChange={handleInputChange}
          placeholder="Enter ASINs separated by new lines"
          multiline
          value={userInputItemIds}
          rows={15}
          fullWidth
          error={!!inputError}
          helperText={inputError}
          inputProps={{
            style: {
              fontSize: '12px'
            }
          }}
          sx={{ mb: 2 }}
        />
        {!profileId ? (
          <Tooltip title="Please select a Profile before adding Items">
            <span>
              <Button disabled>Add Items</Button>
            </span>
          </Tooltip>
        ) : (
          <Button variant="outlined" onClick={addItems} disabled={!userInputItemIds}>
            Add Items
          </Button>
        )}
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ width: '80%', pl: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" sx={{ mb: 2 }} onClick={() => setFieldValue('items', [])}>
            Remove All Items
          </Button>
        </Box>
        <AddAsinsAlert
          invalidAsins={invalidAsins}
          successfullyAddedItems={values.items}
          isOpen={isAlertOpen}
          onClose={() => setIsAlertOpen(false)}
        />
        {!itemSearchIsLoading ? (
          <CampaignItemsTable values={values} removeItem={removeItem} setFieldValue={setFieldValue} />
        ) : (
          <Box display={'flex'} alignItems="center" justifyContent={'center'} sx={{ height: '100%' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};
