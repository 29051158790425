import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DateRange } from '@mui/x-date-pickers-pro';
import { format } from 'date-fns';
import locale from 'date-fns/locale/en-US';
import dayjs, { Dayjs } from 'dayjs';

export enum Month {
  January = 'january',
  February = 'february',
  March = 'march',
  April = 'april',
  May = 'may',
  June = 'june',
  July = 'july',
  August = 'august',
  September = 'september',
  October = 'october',
  November = 'november',
  December = 'december'
}

export const decrementDateRange = (dateRange: DateRange<Dayjs>, numberOfDays: number): DateRange<Dayjs> => {
  const startDate = dateRange[0] ? dateRange[0].subtract(numberOfDays, 'day') : dayjs().subtract(numberOfDays, 'day');
  const endDate = dateRange[1] ? dateRange[1].subtract(numberOfDays, 'day') : dayjs();

  return [startDate, endDate];
};

export const incrementDateRange = (dateRange: DateRange<Dayjs>, numberOfDays: number): DateRange<Dayjs> => {
  const startDate = dateRange[0] ? dateRange[0].add(numberOfDays, 'day') : dayjs();
  const endDate = dateRange[1] ? dateRange[1].add(numberOfDays, 'day') : dayjs();

  return [startDate, endDate];
};
const dateAdapter = new AdapterDateFns({ locale });

export const metricsChartDateFormat = (timeSeriesCategories?: string[]): string => {
  if (
    !timeSeriesCategories ||
    timeSeriesCategories.every((category: string) => category.includes('-') && category.split('-').length === 3)
  ) {
    return 'MM/dd/yyyy';
  }

  return 'MM/yyyy';
};

export const convertDateToMonthDayYear = (date: any): string => {
  // Helper function to format from array to mm/dd/yyyy string.
  const formatDateFromValues = (values: string[]): string => {
    if (values[0] === '9999') {
      values[0] = '2099';
    }
    return `${values[1]}/${values[2]}/${values[0]}`;
  };

  // Check if date is a Unix timestamp (in milliseconds)
  if (typeof date === 'number' || (typeof date === 'string' && /^\d+$/.test(date))) {
    // Convert to Date object considering the date as UTC
    date = new Date(Number(date));
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  }

  if (typeof date === 'string') {
    // Check for 'yyyy-mm-dd' format.
    if (date.includes('-')) {
      const values = date.split('-');
      if (values.length === 3) {
        return formatDateFromValues(values);
      }
    }

    // Check for 'mm/dd/yyyy' format (return it as is if valid).
    if (date.includes('/')) {
      const values = date.split('/');
      if (values.length === 3) {
        return date;
      }
    }
  }

  // If input is a Date object.
  if (date instanceof Date && !isNaN(date.getTime())) {
    if (dateAdapter && typeof dateAdapter.format === 'function') {
      return dateAdapter.format(date, 'keyboardDate');
    } else {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${month}/${day}/${year}`;
    }
  }

  return '';
};

export const formatDate = (date: any, dateFormat: string = 'MM/dd/yyyy'): string => {
  return format(new Date(date), dateFormat);
};

export const convertDateToYearMonthDay = (date: any): string => {
  // If input is a string in the format "mm/dd/yyyy" or "yyyy-mm-dd".
  if (typeof date === 'string') {
    const values = date.includes('-') ? date.split('-') : date.split('/');

    // Ensure there are 3 parts to the date.
    if (values.length !== 3) {
      return '';
    }

    // Convert potential '9999' year value to '2099'.
    if (values[0] === '9999' || values[2] === '9999') {
      values[0] = '2099';
    }

    // If the input is 'mm/dd/yyyy', the values are already in the correct order.
    if (date.includes('/')) {
      return `${values[2]}-${values[0].padStart(2, '0')}-${values[1].padStart(2, '0')}`;
    }

    // If the input is 'yyyy-mm-dd', return as is.
    return date;
  }

  // If input is a Date object.
  if (date instanceof Date && !isNaN(date.getTime())) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  return '';
};

export const convertDateToMonthNameDayYear = (date: any): string => {
  const formattedDate = convertDateToMonthDayYear(date);

  // Convert 'mm/dd/yyyy' to 'month day, year'
  const [monthStr, dayStr, yearStr] = formattedDate.split('/');
  const month = parseInt(monthStr, 10) - 1; // Convert to number and adjust for zero-based index
  const day = parseInt(dayStr, 10);
  const year = parseInt(yearStr, 10); // Convert to number

  const monthName = new Date(year, month, day).toLocaleString('en-US', { month: 'short' });

  return `${monthName} ${day}, ${year}`;
};

export const eightDaysAgo = dayjs().subtract(8, 'day').format('YYYY-MM-DD');
export const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
export const DATE_SETTINGS_KEY = 'DateSettings';

export interface DateConfig {
  beginDate: string;
  endDate: string;
  datePickerIncrementInDays: number;
}

export const DEFAULT_DATE_CONFIG: DateConfig = {
  beginDate: eightDaysAgo,
  endDate: yesterday,
  datePickerIncrementInDays: 7
};
