import { Box, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { useState, useEffect } from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { isEqual } from 'lodash';

interface DateRangePickerProps {
  initialDateRange?: DateRange<Dayjs>;
  onChange?: (dateRange: DateRange<Dayjs>) => void;
  maxDate?: Dayjs;
  disableLeftDivider?: boolean;
}

const timeRanges = [
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 14 days', value: 14 },
  { label: 'Last 30 days', value: 30 },
  { label: 'Last 6 months', value: 180 },
  { label: 'Year To Date', value: 'ytd' },
  { label: 'Month To Date', value: 'mtd' },
  { label: 'Custom Range', value: 'custom' }
];

const defaultDateRange: DateRange<Dayjs> = [dayjs().subtract(7, 'day'), dayjs()];

export default function GridDateRangePicker(props: DateRangePickerProps) {
  const { initialDateRange, onChange } = props;
  const maxDate = props.maxDate || dayjs(); // default to today

  const [value, setValue] = React.useState<DateRange<Dayjs>>(initialDateRange || defaultDateRange);
  const [incrementInDays, setIncrementInDays] = useState<number | string>(7);
  const [disablePrevious, setDisablePrevious] = useState(false);
  const [disableNext, setDisableNext] = useState(false);

  const getDateDifference = (start: Dayjs, end: Dayjs): number => {
    return end.diff(start, 'day');
  };

  const getIncrementValue = (start: Dayjs, end: Dayjs): number | string => {
    const diff = getDateDifference(start, end);

    if (start.isSame(dayjs().startOf('year')) && end.isSame(dayjs(), 'day')) {
      return 'ytd';
    }

    if (start.isSame(dayjs().startOf('month')) && end.isSame(dayjs(), 'day')) {
      return 'mtd';
    }

    const matchingRange = timeRanges.find((range) => range.value === diff);

    return matchingRange ? matchingRange.value : 'custom';
  };

  const updateDateRange = (newRange: DateRange<Dayjs>) => {
    setValue(newRange);
    onChange?.(newRange);
    if (newRange[0] && newRange[1]) {
      const newIncrement = getIncrementValue(newRange[0], newRange[1]);
      setIncrementInDays(newIncrement);
    }
    updateButtonStates(newRange);
  };

  const handleIncrementChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setIncrementInDays(newValue);

    let startDate: Dayjs, endDate: Dayjs;

    if (newValue === 'ytd') {
      startDate = dayjs().startOf('year');
      endDate = dayjs();
    } else if (newValue === 'mtd') {
      startDate = dayjs().startOf('month');
      endDate = dayjs();
    } else {
      endDate = dayjs();
      startDate = endDate.subtract(Number(newValue), 'day');
    }

    updateDateRange([startDate, endDate]);
  };

  const handleDateRangeChange = (newRange: DateRange<Dayjs>) => {
    if (newRange[0] && newRange[1]) {
      updateDateRange(newRange);
    }
  };

  const updateButtonStates = (dateRange: DateRange<Dayjs>) => {
    if (!dateRange[0] || !dateRange[1]) {
      return;
    }

    const diff = getDateDifference(dateRange[0], dateRange[1]);
    const previousStart = dateRange[0].subtract(diff, 'day');
    const nextEnd = dateRange[1].add(diff, 'day');

    setDisablePrevious(previousStart.isBefore('2000-01-01'));
    setDisableNext(nextEnd.isAfter(dayjs()));
  };

  const moveRange = (direction: 'previous' | 'next') => {
    if (!value[0] || !value[1]) {
      return;
    }

    const diff = getDateDifference(value[0], value[1]);
    let newStart: Dayjs, newEnd: Dayjs;

    if (direction === 'previous') {
      newStart = value[0].subtract(diff, 'day');
      newEnd = value[1].subtract(diff, 'day');
    } else {
      newStart = value[0].add(diff, 'day');
      newEnd = value[1].add(diff, 'day');
    }

    updateDateRange([newStart, newEnd]);
  };

  useEffect(() => {
    if (initialDateRange && initialDateRange[0] && initialDateRange[1]) {
      const initialIncrement = getIncrementValue(initialDateRange[0], initialDateRange[1]);
      setIncrementInDays(initialIncrement);
      updateButtonStates(initialDateRange);
    }
  }, [initialDateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          padding: '3px'
        }}
      >
        <IconButton
          size="large"
          sx={{
            borderRadius: '0px'
          }}
          onClick={() => moveRange('previous')}
          role="decrease-date-range"
          disabled={disablePrevious}
        >
          <ArrowLeftIcon />
        </IconButton>
        <Select
          value={incrementInDays.toString()}
          label={'Date Range'}
          role={'increment-select'}
          sx={{
            minWidth: '140px',
            fontSize: '14px'
          }}
          notched={false}
          onChange={handleIncrementChange}
          size="small"
        >
          {timeRanges.map((timeRange) => (
            <MenuItem key={timeRange.value} value={timeRange.value.toString()} role={'increment-option'}>
              {timeRange.value === incrementInDays ? timeRange.label.replace('Last ', '') : timeRange.label}
            </MenuItem>
          ))}
        </Select>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            slots={{
              field: SingleInputDateRangeField
            }}
            slotProps={{
              textField: { size: 'small' }
            }}
            calendars={2}
            sx={{ width: 'auto', minWidth: '225px', mx: 1 }}
            value={value}
            onChange={handleDateRangeChange}
            maxDate={maxDate}
          />
        </LocalizationProvider>
        <IconButton
          size="large"
          sx={{ borderRadius: '0px' }}
          onClick={() => moveRange('next')}
          disabled={disableNext}
          role="increase-date-range"
        >
          <ArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
