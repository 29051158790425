import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import VectorPerformanceGrid from '../../../../shared/components/performance-grid/vector-performance-grid';
import useDatelessVectorPerformanceGrid from '../../../../shared/hooks/use-dateless-vector-performance-grid';
import useDialog from '../../../../shared/hooks/use-dialog';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../../shared/types/pageable';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import { CAMPAIGN_NEGATIVE_TARGET_COLUMNS } from '../../../components/grid-components/column-configurations/campaign-negative-target-columns';
import { BreadCrumbParams, useAmazonApi } from '../../../hooks/use-amazon-api-v2';
import { CampaignNegativeTarget } from '../../../types/negative-target';
import CreateCampaignNegativeKeywordsDialog from './create-dialogs/create-campaign-negative-keywords-dialog';
import CreateNegativeTargetingClausesDialog from './create-dialogs/create-negative-targeting-clauses-dialog';

interface OverviewNegativeTargetsProps {
  campaignId: string | null;
  campaignType?: string;
}

export const OverviewCampaignNegativeTargets = (props: OverviewNegativeTargetsProps) => {
  const { campaignId, campaignType } = props;

  const breadcrumbParams: BreadCrumbParams[] = useMemo(() => [{ campaignId }], [campaignId]);

  const { enqueueSnackbar } = useSnackbar();
  const { getCampaignNegativeTargets } = useAmazonApi();

  const [campaignNegativeTargets, setCampaignNegativeTargets] = useState<CampaignNegativeTarget[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  const {
    initializeDatelessPerformanceGrid,
    saveGridConfig,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  } = useDatelessVectorPerformanceGrid(GridConfigSettings.AMAZON_OVERVIEW_NEGATIVE_TARGETS);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  useEffect(() => {
    initializeDatelessPerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      fetchEntity: fetchCampaignNegativeTargets
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCampaignNegativeTargets = async (pageable: Pageable) => {
    if (!breadcrumbParams) {
      return;
    }

    setIsLoading(true);

    const response = await getCampaignNegativeTargets([...breadcrumbParams], pageable);

    if (response.success) {
      setCampaignNegativeTargets(response?.body?.records);
      setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    // Execute PUT here

    return newRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { isShowing: isNegativeKeywordsDialogShowing, toggle: toggleNegativeKeywordsDialog } = useDialog();
  const { isShowing: isNegativeProductTargetsDialogShowing, toggle: toggleNegativeProductTargetsDialog } = useDialog();

  return (
    <Box style={{ height: '93vh', marginTop: '10px' }}>
      {/* Since this is a nested component whose parent has `Container`, use `Box` so we don't double up on margins. */}
      <Stack spacing={1} style={{ height: '100%' }}>
        <Box display="flex" alignItems={'center'}>
          <Typography variant="h6">Negative Targets</Typography>
          <Box
            sx={{
              mr: 'auto'
            }}
          ></Box>
          <Button startIcon={<AddIcon fontSize="small" />} onClick={() => toggleNegativeKeywordsDialog()}>
            Add Negative Keywords
          </Button>
          <Button startIcon={<AddIcon fontSize="small" />} onClick={() => toggleNegativeProductTargetsDialog()}>
            Add Negative Product Targets
          </Button>
        </Box>
        {!settingsLoaded ? (
          <DataGridPremium
            rows={[]}
            columns={CAMPAIGN_NEGATIVE_TARGET_COLUMNS}
            loading={true}
            processRowUpdate={processRowUpdate}
            initialState={{}}
            key={uuidv4()}
            disableRowGrouping
          />
        ) : (
          <VectorPerformanceGrid
            apiRef={apiRef}
            processRowUpdate={processRowUpdate}
            loading={isLoading}
            rows={campaignNegativeTargets}
            columns={CAMPAIGN_NEGATIVE_TARGET_COLUMNS}
            initialState={initialSettings?.config}
            saveGridConfig={saveGridConfig}
            disableGridDateRangePicker={true}
            getRowId={(row: any) => row.campaignNegativeTargetId}
            handleFilterModelChange={(newModel: GridFilterModel) => {
              handleFilterModelChange(newModel, settingsLoaded, apiRef, fetchCampaignNegativeTargets);
            }}
            handleSortModelChange={(newModel: GridSortModel) => {
              handleSortModelChange(newModel, settingsLoaded, apiRef, fetchCampaignNegativeTargets);
            }}
            handlePageChange={(page: number) => {
              handlePageChange(page, settingsLoaded, apiRef, fetchCampaignNegativeTargets);
            }}
            handlePageSizeChange={(pageSize: number) => {
              handlePageSizeChange(pageSize, settingsLoaded, apiRef, fetchCampaignNegativeTargets);
            }}
            rowCount={rowCount}
          />
        )}
        {isNegativeKeywordsDialogShowing && (
          <CreateCampaignNegativeKeywordsDialog
            open={isNegativeKeywordsDialogShowing}
            toggle={toggleNegativeKeywordsDialog}
            campaignId={campaignId}
            campaignType={campaignType}
            refreshNegativeTargets={() => handleRefreshEntities(settingsLoaded, apiRef, fetchCampaignNegativeTargets)}
          />
        )}
        {isNegativeProductTargetsDialogShowing && (
          <CreateNegativeTargetingClausesDialog
            open={isNegativeProductTargetsDialogShowing}
            isCampaignNegativeTarget={true}
            toggle={toggleNegativeProductTargetsDialog}
            refreshNegativeTargets={() => handleRefreshEntities(settingsLoaded, apiRef, fetchCampaignNegativeTargets)}
          />
        )}
        {isNegativeProductTargetsDialogShowing && (
          <CreateNegativeTargetingClausesDialog
            open={isNegativeProductTargetsDialogShowing}
            isCampaignNegativeTarget={true}
            toggle={toggleNegativeProductTargetsDialog}
            refreshNegativeTargets={() => handleRefreshEntities(settingsLoaded, apiRef, fetchCampaignNegativeTargets)}
          />
        )}
      </Stack>
    </Box>
  );
};
