import { Container, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridRowModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import KeywordBulkActions from '../../../shared/components/grid-components/bulk-actions/keywords/keyword-bulk-actions';
import Page from '../../../shared/components/page';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useDatelessVectorPerformanceGrid from '../../../shared/hooks/use-dateless-vector-performance-grid';
import useAdsApi, { BreadCrumbParams } from '../../../shared/hooks/use-wmt-map-updated-api';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { buildUpdateNegativeKeywordRequest } from '../../../shared/types/walmart-sams-club/keyword';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { NEGATIVE_KEYWORD_COLUMNS } from '../../components/grid-components/column-configurations/negative-keyword-columns';

const NegativeKeywords: FC = () => {
  const { getNegativeKeywords, updateNegativeKeywords } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [negativeKeywords, setNegativeKeywords] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  let [searchParams] = useSearchParams();
  let profileId = useMemo(() => (searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null), [searchParams]);
  let campaignId = useMemo(
    () => (searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null),
    [searchParams]
  );
  let adGroupId = useMemo(() => (searchParams.get('adGroupId') ? Number(searchParams.get('adGroupId')) : null), [searchParams]);

  const breadcrumbParams: BreadCrumbParams[] = useMemo(
    () => [{ profileId }, { campaignId }, { adGroupId }],
    [profileId, campaignId, adGroupId]
  );

  const {
    initializeDatelessPerformanceGrid,
    saveGridConfig,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange
  } = useDatelessVectorPerformanceGrid(GridConfigSettings.SAMS_CLUB_NEGATIVE_KEYWORD);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  useEffect(() => {
    initializeDatelessPerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      fetchEntity: fetchNegativeKeywordsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchNegativeKeywordsData = async (pageable: Pageable) => {
    setIsLoading(true);

    const response = await getNegativeKeywords([...breadcrumbParams], pageable);

    if (response.success) {
      setNegativeKeywords(response.body?.records);
      setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.body, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const processRowUpdate = useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
    setIsLoading(true);

    const response = await updateNegativeKeywords([buildUpdateNegativeKeywordRequest(newRow)]);

    setIsLoading(false);

    if (response.success && response.body.every((responseCode: any) => responseCode.code.toLowerCase() === 'success')) {
      return newRow;
    }

    enqueueSnackbar(response.body[0].details, { variant: 'error' });

    return oldRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Page title="Negative Keywords">
        <Container maxWidth="xl" style={{ height: '93vh' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Typography variant="h4">Negative Keywords</Typography>
            {!settingsLoaded ? (
              <DataGridPremium
                rows={[]}
                columns={NEGATIVE_KEYWORD_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
                key={uuidv4()}
                disableRowGrouping
              />
            ) : (
              <VectorPerformanceGrid
                apiRef={apiRef}
                disableGridDateRangePicker={true}
                processRowUpdate={processRowUpdate}
                loading={isLoading}
                rows={negativeKeywords}
                columns={NEGATIVE_KEYWORD_COLUMNS}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                getRowId={(row: any) => row.keywordId}
                getRowClassName={(params) => `row-status--${params.row.state}`}
                bulkActions={<KeywordBulkActions setIsLoading={setIsLoading} disableUpdateKeywordBids />}
                handleFilterModelChange={(newModel: any) =>
                  handleFilterModelChange(newModel, settingsLoaded, apiRef, fetchNegativeKeywordsData)
                }
                handleSortModelChange={(newModel: any) =>
                  handleSortModelChange(newModel, settingsLoaded, apiRef, fetchNegativeKeywordsData)
                }
                handlePageSizeChange={(pageSize: number) =>
                  handlePageSizeChange(pageSize, settingsLoaded, apiRef, fetchNegativeKeywordsData)
                }
                handlePageChange={(page: number) => handlePageChange(page, settingsLoaded, apiRef, fetchNegativeKeywordsData)}
                rowCount={rowCount}
              />
            )}
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default NegativeKeywords;
