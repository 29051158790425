import { useEffect, useState } from 'react';
import useSharedApi from './use-shared-api';

export interface UserSeriesData {
  selectedSeries: string[];
}

export const useUserSeries = (settingsKey: string) => {
  const { getUserSetting, updateUserSetting } = useSharedApi();
  const [userSeriesData, setUserSeriesData] = useState<UserSeriesData | null>(null);

  useEffect(() => {
    fetchUserSeriesData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUserSeriesData = async () => {
    try {
      const response = await getUserSetting(settingsKey);

      if (response.success && response.body) {
        const userData: UserSeriesData = JSON.parse(response.body);
        setUserSeriesData(userData);
      } else {
        setUserSeriesData({ selectedSeries: [] });
      }
    } catch (error) {
      console.error('Error fetching user series data:', error);
    }
  };

  const saveUserSeriesData = async (selectedSeries: string[]) => {
    try {
      const userData: UserSeriesData = { selectedSeries };
      const response = await updateUserSetting(settingsKey, JSON.stringify(userData));

      if (response.success) {
        setUserSeriesData(userData);
      }
    } catch (error) {
      console.error('Error saving user series data:', error);
    }
  };

  return {
    userSeriesData,
    saveUserSeriesData
  };
};
