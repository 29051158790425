import { breadcrumbsClasses } from '@mui/material';
import { API_AUTH_SCOPE, API_ROOT } from '../../config';
import { useAuth } from '../../shared/hooks/use-auth';
import { Comparator, defaultPageable, Pageable } from '../../shared/types/pageable';
import { Platform } from '../../shared/types/platforms';
import { ResponseObject, executeGetRequest, executePostRequest, executePutRequest } from '../../shared/utilities/fetch-utilities';
import { UpdateCampaignRequest } from '../types/campaign';
import { TargetUpdateDto } from '../types/target';
import { AdGroupBuilderCreateDto, UpdateAdGroupRequest } from '../types/ad-group';

// #region - Param / Pageable Helpers

export const buildPageableParams = (params: any, pageable: Pageable | null | undefined): string => {
  let result = '';

  result += serializeParams(params);
  result += serializePageableParams(!!pageable ? pageable : defaultPageable);

  return result;
};

export function serializePageableParams(params: Pageable): string {
  let result = '';

  if (params.limit) {
    result += `&limit=${params.limit}`;
  }

  if (params.offset) {
    result += `&offset=${params.offset}`;
  }

  if (params.filters) {
    params.filters?.map((filter) => {
      if (filter) {
        if (filter.comparator === 'isAnyOf') {
          filter.value = (filter.value as unknown as string[]).join('|');
        }
        result += `&filters=${encodeURIComponent(JSON.stringify(filter))}`;
      }
    });
  }

  if (params.sorts) {
    params.sorts?.map((sort) => {
      if (sort) {
        result += `&sorts=${encodeURIComponent(JSON.stringify(sort))}`;
      }
    });
  }

  return result;
}

export const serializeParams = (params: any): string => {
  return Object.keys(params)
    .map((key) => (params[key] ? `${key}=${params[key]}` : ''))
    .join('&');
};

export interface BreadCrumbParams {
  [key: string]: string | number | boolean | null | undefined;
}

export interface FetchEntityParams {
  beginDate?: string | null;
  endDate?: string | null;
  shouldIncludeChartMetrics?: boolean;
}

export const buildQueryString = (breadcrumbParams: BreadCrumbParams[], params: FetchEntityParams, pageable: Pageable): string => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value != null) {
      queryParams.append(key, value.toString());
    }
  });

  if (pageable.limit) queryParams.append('limit', pageable.limit.toString());
  if (pageable.offset) queryParams.append('offset', pageable.offset.toString());

  pageable.filters?.forEach((filter) => {
    queryParams.append('filters', JSON.stringify(filter));
  });

  // inject breadcrumb params into filters
  breadcrumbParams.forEach((breadcrumbParam) => {
    const filter = {
      column: Object.keys(breadcrumbParam)[0],
      comparator: Comparator.Equals,
      value: breadcrumbParam[Object.keys(breadcrumbParam)[0]]
    };

    queryParams.append('filters', JSON.stringify(filter));
  });

  pageable.sorts?.forEach((sort) => {
    queryParams.append('sorts', JSON.stringify(sort));
  });

  return queryParams.toString();
};

// #endregion

export const useAmazonApi = () => {
  const { acquireToken } = useAuth();

  const getProfiles = async (pageable: Pageable, beginDate?: string | null, endDate?: string | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/profiles/getAllWithMetrics${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaigns = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable?: Pageable,
    beginDate?: string | null,
    endDate?: string | null,
    shouldIncludeChartMetrics?: boolean
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate, shouldIncludeChartMetrics } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/campaigns/getAllWithMetrics?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaign = async (campaignId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/campaigns/getById/${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const updateCampaign = async (requestBody: UpdateCampaignRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/unified/campaigns/update`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getCampaignsWithoutBidGroup = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable?: Pageable,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/GetCampaignsWithoutBidGroup?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignBudgetGroupOverview = async (campaignBudgetGroupId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/Budget/GetCampaignBudgetGroupOverview?campaignBudgetGroupId=${campaignBudgetGroupId}`,
      authToken: authResponse.accessToken
    });
  };

  const getAdGroups = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable: Pageable,
    beginDate?: string | null,
    endDate?: string | null,
    shouldIncludeChartMetrics?: boolean
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate, shouldIncludeChartMetrics } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/adGroups/getAllWithMetrics?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const buildAdGroup = async (requestBody: AdGroupBuilderCreateDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/campaignBuilder/buildAdGroup`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const updateAdGroup = async (requestBody: UpdateAdGroupRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/unified/adGroups/update`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const getAds = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable: Pageable,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/ads/getAllWithMetrics?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const getTargets = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable?: Pageable,
    beginDate?: string | null,
    endDate?: string | null,
    shouldIncludeChartMetrics?: boolean
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate, shouldIncludeChartMetrics } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/targets/getAllWithMetrics?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const updateTarget = async (requestBody: TargetUpdateDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/unified/targets/update`,
      authToken: authResponse.accessToken,
      body: requestBody
    });
  };

  const searchCatalogItems = async (asins: string[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${Platform.AMAZON.value}/passthrough/searchCatalogItems`,
      authToken: authResponse.accessToken,
      body: asins
    });
  };

  const getBidOptimizationTargetHistory = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable: Pageable,
    beginDate?: string,
    endDate?: string
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/GetTargetHistory?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const getBidOptimizationPlacementHistory = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable: Pageable,
    beginDate?: string,
    endDate?: string
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/Unified/BidOptimizationGroups/GetPlacementHistory?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignNegativeTargets = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable: Pageable
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(breadcrumbParams, {} as FetchEntityParams, pageable || defaultPageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/campaignNegativeTargets/getAll?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const getNegativeTargets = async (breadcrumbParams: BreadCrumbParams[] = [], pageable: Pageable): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(breadcrumbParams, {} as FetchEntityParams, pageable || defaultPageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/negativeTargets/getAll?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const getPlacementMetrics = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable?: Pageable,
    campaignId?: string | null,
    beginDate?: string | null,
    endDate?: string | null,
    shouldIncludeChartMetrics?: boolean
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { campaignId, beginDate, endDate, shouldIncludeChartMetrics } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/unified/campaigns/getPlacementMetrics?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  return {
    getProfiles,
    getCampaigns,
    getCampaign,
    updateCampaign,
    getCampaignsWithoutBidGroup,
    getCampaignBudgetGroupOverview,
    getAdGroups,
    buildAdGroup,
    updateAdGroup,
    getAds,
    getTargets,
    updateTarget,
    searchCatalogItems,
    getBidOptimizationTargetHistory,
    getBidOptimizationPlacementHistory,
    getCampaignNegativeTargets,
    getNegativeTargets,
    getPlacementMetrics
  };
};
