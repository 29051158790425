import React, { useEffect, useState, useCallback } from 'react';
import { Backdrop, Box, CircularProgress, Container, Grid, Skeleton, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Page from '../../../../page';
import useAdsApi from '../../../../../hooks/use-walmart-sams-club-api';
import { Campaign } from '../../../../../types/walmart-sams-club/campaign';
import {
  InitialReviewState,
  orderByReviewIdDesc,
  Review as TReview,
  SbReviewRequest as WalmartSbaReviewRequest
} from '../../../../../types/walmart-sams-club/review';
import { CreateReviewDialog } from './create-review-dialog';
import { CancelReviewDialog } from './cancel-review-dialog';
import { ItemStatusTable, KeywordStatusTable } from '../review-status-tables';
import { AdGroupMediaDetails } from './ad-group-media-details';
import { StyledConnector, StyledStepIcon } from '../review-stepper';
import { buildPageableFilters, Pageable } from '../../../../../types/pageable';

export const SponsoredVideoReview: React.FC = () => {
  const {
    getSbaReviews,
    getCampaign,
    getAds,
    getKeywords,
    getAdGroups,
    getAdGroupMedia,
    walmartCreateSbaReview,
    cancelSbaReviews
  } = useAdsApi();

  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [adGroupMedia, setAdGroupMedia] = useState(null);
  const [ads, setAds] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const [review, setReview] = useState<TReview>(InitialReviewState);
  const [isLoading, setIsLoading] = useState(true);
  const [isReviewDialogOpen, setIsReviewDialogOpen] = useState(false);
  const [isCancelReviewDialogOpen, setIsCancelReviewDialogOpen] = useState(false);

  const profileId = Number(searchParams.get('profileId'));
  const campaignId = Number(searchParams.get('campaignId'));
  const [adGroupId, setAdGroupId] = useState<number | null>(Number(searchParams.get('adGroupId')) || null);

  useEffect(() => {
    if (!profileId || !campaignId) {
      navigate('/campaigns');
    }
  }, [profileId, campaignId, navigate]);

  const fetchData = useCallback(async () => {
    if (!profileId || !campaignId || !adGroupId) {
      return;
    }

    setIsLoading(true);
    try {
      const pageable: Pageable = {
        filters: buildPageableFilters({ profileId, campaignId, adGroupId }),
        sorts: [],
        offset: 0,
        limit: 250
      };

      const [campaignResponse, reviewResponse, adResponse, keywordResponse, adGroupMediaResponse] = await Promise.all([
        getCampaign(profileId, campaignId),
        getSbaReviews(profileId, campaignId, adGroupId),
        getAds(pageable),
        getKeywords(pageable),
        getAdGroupMedia(campaignId)
      ]);

      if (campaignResponse.success) {
        setCampaign(campaignResponse.body);
      }

      if (reviewResponse.success) {
        const reviewArray = reviewResponse.body;
        reviewArray.sort(orderByReviewIdDesc);
        setReview(reviewArray[0] || InitialReviewState);
      }

      if (adResponse.success) {
        setAds(adResponse?.body?.records);
      }

      if (keywordResponse.success) {
        setKeywords(keywordResponse?.body?.records);
      }

      if (adGroupMediaResponse.success) {
        setAdGroupMedia(adGroupMediaResponse?.body?.[0]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      enqueueSnackbar('Failed to fetch data', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, campaignId, adGroupId]);

  useEffect(() => {
    if (!adGroupId) {
      getAdGroups(profileId, campaignId, null, null).then((response) => {
        if (response.success && response.body.length > 0) {
          const newAdGroupId = response.body[0].adGroupId;
          setAdGroupId(newAdGroupId);
          setSearchParams((prev) => {
            prev.set('adGroupId', newAdGroupId.toString());
            return prev;
          });
        }
      });
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, campaignId, adGroupId]);

  const handleCreateReview = async () => {
    setIsLoading(true);
    try {
      const reviewRequest: WalmartSbaReviewRequest = {
        adGroupId: adGroupId?.toString() || '',
        campaignId: campaignId?.toString() || ''
      };
      const response = await walmartCreateSbaReview(profileId, reviewRequest);
      if (response.success) {
        await fetchData();
        enqueueSnackbar('Review created successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to create review', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error creating review:', error);
      enqueueSnackbar('Failed to create review', { variant: 'error' });
    } finally {
      setIsLoading(false);
      setIsReviewDialogOpen(false);
    }
  };

  const handleCancelReview = async () => {
    setIsLoading(true);
    try {
      const cancelRequest = {
        reviewId: review?.reviewId.toString(),
        campaignId: campaignId?.toString() || '',
        adGroupId: adGroupId?.toString() || ''
      };
      const response = await cancelSbaReviews(profileId, cancelRequest);
      if (response.success) {
        await fetchData();
        enqueueSnackbar('Review cancelled successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to cancel review', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error cancelling review:', error);
      enqueueSnackbar('Failed to cancel review', { variant: 'error' });
    } finally {
      setIsLoading(false);
      setIsCancelReviewDialogOpen(false);
    }
  };

  const reviewSteps = [
    { key: 0, states: ['cancelled', undefined, null], label: 'Unsubmitted' },
    { key: 1, states: ['pending', 'inprogress'], label: 'Pending' },
    { key: 2, states: ['complete'], label: 'Complete' }
  ];

  const activeStep = reviewSteps.findIndex((step) => step.states.includes(review?.reviewStatus?.toLowerCase()));

  return (
    <Page title={`Review Status - ${campaign?.name}`}>
      <Container maxWidth="xl">
        <Container maxWidth="md" sx={{ mt: 2, mb: 4 }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="h4" sx={{ mb: 2 }}>
                {isLoading ? <Skeleton width={500} /> : campaign?.name}
              </Typography>
            </Grid>
          </Grid>

          <Stepper activeStep={activeStep} connector={<StyledConnector />} sx={{ mb: 2 }}>
            {reviewSteps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel StepIconComponent={StyledStepIcon}>
                  <Typography
                    variant={index === activeStep ? 'body1' : 'body2'}
                    sx={{ fontWeight: index === activeStep ? 'bold' : 'normal' }}
                  >
                    {step.label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {adGroupMedia && (
            <Box mt={2}>
              <AdGroupMediaDetails
                adGroupMedia={adGroupMedia}
                review={review}
                onCreate={() => setIsReviewDialogOpen(true)}
                onCancel={() => setIsCancelReviewDialogOpen(true)}
              />
            </Box>
          )}

          {ads && (
            <Box mt={2}>
              <ItemStatusTable items={ads} review={review} />
            </Box>
          )}

          {keywords && (
            <Box mt={2}>
              <KeywordStatusTable keywords={keywords} review={review} />
            </Box>
          )}
        </Container>
      </Container>

      <CreateReviewDialog open={isReviewDialogOpen} onClose={() => setIsReviewDialogOpen(false)} onSave={handleCreateReview} />
      <CancelReviewDialog
        open={isCancelReviewDialogOpen}
        onClose={() => setIsCancelReviewDialogOpen(false)}
        onSubmit={handleCancelReview}
      />
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
};
