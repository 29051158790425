import { Route, Routes } from 'react-router-dom';
import AuthGuard from '../components/auth-guard';
import { DashboardLayout } from '../dashboard/dashboard-layout';
import AuthorizationRequired from '../pages/401';
import NotFound from '../pages/404';
import { amazonRoutes, deprecatedRoutes, samsClubRoutes, walmartRoutes } from './routes-objects';

const renderRoutes = (routesArray: any[], condition = true) => {
  return condition ? routesArray.map((route) => <Route {...route} />) : null;
};

const Routing = () => {
  return (
    <Routes>
      <Route element={<AuthGuard />}>
        <Route element={<DashboardLayout />}>
          {renderRoutes(walmartRoutes)}
          {renderRoutes(deprecatedRoutes)}
          {renderRoutes(amazonRoutes)}
          {renderRoutes(samsClubRoutes)}
        </Route>
      </Route>
      {/* catch-all routes */}
      <Route path="unauthorized" element={<AuthorizationRequired />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routing;
