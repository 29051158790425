import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';
import { hasValue } from '../utilities/general-utilities';

export const DEFAULT_ROW_COUNT = 250;

export const ROWS_PER_PAGE_OPTIONS = [25, 50, 100, DEFAULT_ROW_COUNT, 500]; //mui quirk -> this array must contain the value from rowsPerPage
export interface Pageable {
  limit: number;
  offset: number;
  filters?: Filter[];
  sorts?: Sort[];
}

export const defaultPageable: Pageable = {
  limit: DEFAULT_ROW_COUNT,
  offset: 0
};

export function buildPageableFilters(items: any) {
  const filters: Filter[] = Object.keys(items)
    .filter((key) => items[key] !== undefined && items[key] !== null)
    .map((key) => ({
      column: key,
      comparator: 'Equals',
      value: items[key].toString()
    }));

  return filters;
}

export const convertGridFilterModelToCustomFilters = (filterModel: GridFilterModel, searchableColumns?: string[]) => {
  let filters: Filter[] = [];

  const newFilters: Filter[] = filterModel.items
    .filter((item) => hasValue(item.value)) //this is making sure only filters with values are included
    .map((item) => {
      return {
        column: item.field,
        comparator: convertOperatorToComparator(item.operator),
        value: item.value || ''
      };
    });

  filters = [...newFilters];

  if (
    filterModel.quickFilterValues !== null &&
    filterModel.quickFilterValues !== undefined &&
    filterModel.quickFilterValues.length > 0 &&
    searchableColumns &&
    searchableColumns.length > 0
  ) {
    const combinedQuickFilterValue = filterModel.quickFilterValues.join(' ');

    searchableColumns.forEach((column) => {
      const quickFilter: Filter = {
        column: column, // Apply to the searchable column
        comparator: 'contains', // Comparator logic for quick filters
        value: combinedQuickFilterValue
      };

      filters.push(quickFilter);
    });
  }

  return filters;
};

export const convertToGridFilterModel = (filters: Filter[]): GridFilterModel => {
  const items = filters.map((filter) => {
    return {
      field: filter.column,
      operator: convertComparatorToOperator(filter.comparator as Comparator),
      value: filter.value
    };
  });

  return { items };
};

export const processSortsFromGrid = (sortModel: GridSortModel) => {
  const newSorts: Sort[] = sortModel
    .filter((item) => item.field !== undefined && item.field !== null)
    .map((item) => {
      return {
        column: item.field,
        direction: item.sort === 'desc' ? SortDirections.Descending : SortDirections.Ascending
      };
    });

  return newSorts;
};

export interface Filter {
  column: string;
  comparator: string; //pipe
  value: string;
}

export interface Sort {
  column: string;
  direction: string; //Ascending, Descending
}

export enum SortDirections {
  Ascending = 'Ascending',
  Descending = 'Descending'
}

export enum Comparator {
  Equals = 'Equals',
  NotEquals = 'NotEquals',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEquals = 'GreaterThanOrEquals',
  LessThan = 'LessThan',
  LessThanOrEquals = 'LessThanOrEquals'
}

export function convertOperatorToComparator(operator: any): Comparator {
  switch (operator) {
    case '=':
    case 'is':
      return Comparator.Equals;

    case '!=':
    case 'not':
      return Comparator.NotEquals;

    case '>':
    case 'after':
      return Comparator.GreaterThan;

    case '>=':
    case 'onOrAfter':
      return Comparator.GreaterThanOrEquals;

    case '<':
    case 'before':
      return Comparator.LessThan;

    case '<=':
    case 'onOrBefore':
      return Comparator.LessThanOrEquals;

    default:
      return operator;
  }
}

function convertComparatorToOperator(comparator: Comparator): string {
  switch (comparator) {
    case Comparator.Equals:
      return 'is';

    case Comparator.NotEquals:
      return 'not';

    case Comparator.GreaterThan:
      return '>';

    case Comparator.GreaterThanOrEquals:
      return '>=';

    case Comparator.LessThan:
      return '<';

    case Comparator.LessThanOrEquals:
      return '<=';

    default:
      return comparator;
  }
}

export function isGridFilterModel(obj: any): obj is GridFilterModel {
  return obj != null && typeof obj === 'object' && Array.isArray(obj.items) && typeof obj.logicOperator === 'string';
}
