import { Box, Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Page from '../../../../shared/components/page';
import CustomTabPanel from '../../../../shared/components/CustomTabPanel';
import { useAmazonApi } from '../../../hooks/use-amazon-api';
import { AdGroup } from '../../../types/ad-group';
import { Campaign, AmazonCampaignType, TargetingTypeValue } from '../../../types/campaign';
import AdGroupHarvesting from './overview-ad-group-harvesting';
import { AdGroupNegativeTargets } from './overview-ad-group-negative-targets';
import { AdGroupTargets } from './ad-group-targets';
import { OverviewAds } from './overview-ads';

const AdGroupOverview: FC = () => {
  const { getAdGroup, getCampaign } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();
  const profileId = searchParams.get('profileId');
  const campaignId = searchParams.get('campaignId');
  const adGroupId = searchParams.get('adGroupId');

  const [adGroup, setAdGroup] = useState<AdGroup | null>(null);
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  useEffect(() => {
    const fetchAdGroup = async () => {
      if (!adGroupId) {
        return;
      }

      const response = await getAdGroup(adGroupId);

      if (response.success) {
        setAdGroup(response.body);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }
    };

    fetchAdGroup();
  }, [adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchCampaign = async () => {
      if (!campaignId) {
        return;
      }

      const response = await getCampaign(campaignId);

      if (response.success) {
        setCampaign(response.body);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }
    };

    fetchCampaign();
  }, [campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
  };

  const shouldRenderHarvesting = () => {
    return (
      campaign?.campaignType === AmazonCampaignType.SPONSORED_PRODUCTS &&
      campaign?.targetingType &&
      campaign.targetingType.toUpperCase() !== TargetingTypeValue.AUTO
    );
  };

  return (
    <Page title="Ad Group Overview">
      <Container maxWidth="xl" style={{ height: '93vh', marginTop: '10px' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'} justifyContent={'center'}>
            <Typography variant="h6" mr={2}>
              Ad Group: {adGroup?.name || 'Loading...'}
            </Typography>
            <Box sx={{ mr: 'auto' }}></Box>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTabIndex} onChange={handleTabChange}>
              <Tab label="Targets" />
              <Tab label="Negative Targets" />
              <Tab label="Ads" />
              {shouldRenderHarvesting() && <Tab label="Harvesting" />}
            </Tabs>
          </Box>
          <CustomTabPanel value={currentTabIndex} index={0}>
            <AdGroupTargets profileId={profileId} campaignId={campaignId} adGroupId={adGroupId} campaign={campaign} />
          </CustomTabPanel>
          <CustomTabPanel value={currentTabIndex} index={1}>
            <AdGroupNegativeTargets adGroupId={adGroupId} />
          </CustomTabPanel>
          <CustomTabPanel value={currentTabIndex} index={2}>
            <OverviewAds campaignType={campaign?.campaignType} />
          </CustomTabPanel>
          {shouldRenderHarvesting() && (
            <CustomTabPanel value={currentTabIndex} index={3}>
              <AdGroupHarvesting adGroupId={adGroupId} />
            </CustomTabPanel>
          )}
        </Stack>
      </Container>
    </Page>
  );
};

export default AdGroupOverview;
