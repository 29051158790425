import { Dashboard } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import CampaignIcon from '@mui/icons-material/Campaign';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import FlagIcon from '@mui/icons-material/Flag';
import History from '@mui/icons-material/History';
import KeyIcon from '@mui/icons-material/Key';
import LocalPlayIcon from '@mui/icons-material/LocalPlay';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PaidIcon from '@mui/icons-material/Paid';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MovieIcon from '@mui/icons-material/Movie';
import { ReactNode } from 'react';
import samsClubLogo from '../images/platform-logos/Sams-Club-logo.png';
import walmartLogo from '../images/platform-logos/Walmart-Symbol.png';
import amazonLogo from '../images/platform-logos/amazon-logo.png';
import { amazonRoutes, samsClubRoutes, walmartRoutes } from '../routing/routes-objects';
import { Platform } from './platforms';

export interface Item {
  title: string;
  children?: Item[];
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
  target?: string;
  disabled?: boolean;
}

export interface Section {
  title: string;
  items: Item[];
}

export const PLATFORMS = [
  {
    name: Platform.WALMART.label,
    logo: walmartLogo,
    logoBackgroundColor: '#1a75cf',
    path: Platform.WALMART.value
  },
  {
    name: Platform.AMAZON.label,
    logo: amazonLogo,
    logoBackgroundColor: '#fff',
    path: Platform.AMAZON.value
  },
  {
    name: Platform.SAMS_CLUB.label,
    logo: samsClubLogo,
    logoBackgroundColor: '#0062AD',
    path: Platform.SAMS_CLUB.value
  }
];

export const getWalmartSidebarSections = (profileName?: string): Section[] => [
  {
    title: 'General',
    items: [
      {
        title: 'Profiles',
        path: walmartRoutes.find((route) => route.key === 'walmart-profiles')?.path,
        icon: <BusinessIcon fontSize="small" />
      },
      {
        title: 'Campaigns',
        path: walmartRoutes.find((route) => route.key === 'walmart-campaigns')?.path,
        icon: <CampaignIcon fontSize="small" />
      },
      {
        title: 'Ads',
        path: walmartRoutes.find((route) => route.key === 'walmart-ads')?.path,
        icon: <LocalPlayIcon fontSize="small" />
      },
      {
        title: 'Keywords',
        path: walmartRoutes.find((route) => route.key === 'walmart-keywords')?.path,
        icon: <KeyIcon fontSize="small" />
      },
      {
        title: 'Video Manager',
        path: walmartRoutes.find((route) => route.key === 'video-manager')?.path,
        icon: <MovieIcon fontSize="small" />
      },
      {
        title: 'Budgets',
        path: walmartRoutes.find((route) => route.key === 'walmart-budgets')?.path,
        icon: <PaidIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Analysis',
    items: [
      {
        title: 'Impression Share',
        path: profileName
          ? `https://ember.harvestgroup.com/dashboards/6980?Report+Date=30+day+ago+for+30+day&Searched+Keyword=&+Client+Name=${profileName}`
          : 'https://ember.harvestgroup.com/dashboards/6980',
        target: '_blank',
        icon: <OpenInNewIcon fontSize="small" />
      },
      {
        title: 'Branded Spend Tracker',
        path: profileName
          ? `https://ember.harvestgroup.com/dashboards/7312?+Calendar+Date=30+day+ago+for+30+day&+Client+Name=${profileName}`
          : 'https://ember.harvestgroup.com/dashboards/7312',
        target: '_blank',
        icon: <OpenInNewIcon fontSize="small" />
      },
      {
        title: 'Search Insights',
        path: walmartRoutes.find((route) => route.key === 'walmart-search-insights')?.path,
        icon: <QueryStatsIcon fontSize="small" />
      }
    ]
  }
];

export const getAmazonSidebarSections = (): Section[] => [
  {
    title: 'Home',
    items: [
      {
        title: 'Dashboard',
        path: amazonRoutes.find((route) => route.key === 'amazon-dashboard')?.path,
        icon: <Dashboard fontSize="small" />
      }
    ]
  },
  {
    title: 'Ad Management',
    items: [
      {
        title: 'Profiles',
        path: amazonRoutes.find((route) => route.key === 'amazon-profiles')?.path,
        icon: <BusinessIcon fontSize="small" />
      },
      {
        title: 'Campaigns',
        path: amazonRoutes.find((route) => route.key === 'amazon-campaigns')?.path,
        icon: <CampaignIcon fontSize="small" />
      },
      {
        title: 'Targets',
        path: amazonRoutes.find((route) => route.key === 'amazon-targets')?.path,
        icon: <FlagIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Integrated Commerce',
    items: [
      {
        title: 'Business Overview',
        path: 'https://ember.harvestgroup.com/dashboards/4823?+Client+Name=&Calendar+Date=last+week&Ordered+or+Shipped+Metrics+Toggle=Ordered&Category=&Data+Source=MANUFACTURING%2CSELLER+CENTRAL%2CNULL&Subcategory=&Brand=&Subbrand=&+ASIN=&Selling+Program=&Amazon+Week=&Active+Status+%28For+Content+Health+Score%29=',
        target: '_blank',
        icon: <OpenInNewIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Tools',
    items: [
      {
        title: 'Budgets',
        path: amazonRoutes.find((route) => route.key === 'amazon-budgets')?.path,
        icon: <PaidIcon fontSize="small" />
      },
      {
        title: 'Bid Groups',
        path: amazonRoutes.find((route) => route.key === 'amazon-bid-optimization-groups')?.path,
        icon: <CurrencyExchangeIcon fontSize="small" />
      },
      {
        title: 'History Logs',
        path: amazonRoutes.find((route) => route.key === 'amazon-audit-logs')?.path,
        icon: <History fontSize="small" />
      },
      {
        title: 'Audit Dashboard',
        path: 'https://ember.harvestgroup.com/dashboards/4581?Calendar+Date=1+month+ago+for+1+month&+Client+Name=&+Ad+Type=',
        target: '_blank',
        icon: <OpenInNewIcon fontSize="small" />
      }
      // HIDE Automated Action UNTIL WE ARE READY TO RELEASE TO USERS
      // {
      //   title: 'Automated Actions',
      //   path: amazonRoutes.find((route) => route.key === 'amazon-automated-actions')?.path,
      //   icon: <BoltIcon fontSize="small" />
      // }
    ]
  },
  {
    title: 'Market Intelligence',
    items: [
      {
        title: 'Share of Search',
        path: 'https://ember.harvestgroup.com/dashboards/899?Keywords=&+Client+Name=&ASIN=&Data+Scrape+Date=7+day&Promoted=&Data+Scrape+Scheduled+Time=',
        target: '_blank',
        icon: <OpenInNewIcon fontSize="small" />
      }
    ]
  }
];

export const getSamsClubSidebarSections = (): Section[] => [
  {
    title: 'General',
    items: [
      {
        title: 'Profiles',
        path: samsClubRoutes.find((route) => route.key === 'sams-club-profiles')?.path,
        icon: <BusinessIcon fontSize="small" />
      },
      {
        title: 'Campaigns',
        path: samsClubRoutes.find((route) => route.key === 'sams-club-campaigns')?.path,
        icon: <CampaignIcon fontSize="small" />
      },
      {
        title: 'Ads',
        path: samsClubRoutes.find((route) => route.key === 'sams-club-ads')?.path,
        icon: <LocalPlayIcon fontSize="small" />
      },
      {
        title: 'Keywords',
        path: samsClubRoutes.find((route) => route.key === 'sams-club-keywords')?.path,
        icon: <KeyIcon fontSize="small" />
      },
      {
        title: 'Negative Keywords',
        path: samsClubRoutes.find((route) => route.key === 'sams-club-negative-keywords')?.path,
        icon: <KeyIcon fontSize="small" sx={{ transform: 'scale(-1, -1)' }} />
      },
      {
        title: 'Budgets',
        path: samsClubRoutes.find((route) => route.key === 'sams-club-budgets')?.path,
        icon: <PaidIcon fontSize="small" />
      }
    ]
  }
];

export type PlatformSidebarSections = {
  [PlatformValue: string]: () => Section[];
};

export const platformSidebarSections: PlatformSidebarSections = {
  [Platform.WALMART.value]: getWalmartSidebarSections,
  [Platform.AMAZON.value]: getAmazonSidebarSections,
  [Platform.SAMS_CLUB.value]: getSamsClubSidebarSections
};
