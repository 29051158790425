import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import VectorPerformanceGrid from '../../../../shared/components/performance-grid/vector-performance-grid';
import useDatelessVectorPerformanceGrid from '../../../../shared/hooks/use-dateless-vector-performance-grid';
import useDialog from '../../../../shared/hooks/use-dialog';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../../shared/types/pageable';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import { caseInsensitiveEquals } from '../../../../shared/utilities/string-utilities';
import { NEGATIVE_TARGET_COLUMNS } from '../../../components/grid-components/column-configurations/negative-target-columns';
import { BreadCrumbParams, useAmazonApi } from '../../../hooks/use-amazon-api-v2';
import { TargetType } from '../../../types/target';
import CreateNegativeKeywordsDialog from './create-dialogs/create-negative-keywords-dialog';
import CreateNegativeTargetingClausesDialog from './create-dialogs/create-negative-targeting-clauses-dialog';

interface AdGroupNegativeTargetsProps {
  adGroupId: string | null;
}

export const AdGroupNegativeTargets: React.FC<AdGroupNegativeTargetsProps> = ({ adGroupId }) => {
  const { getNegativeTargets, getCampaign, getTargets } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [targets, setTargets] = useState<any[]>([]);
  const [campaign, setCampaign] = useState<any>({});

  const [negativeTargets, setNegativeTargets] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowCount, setRowCount] = useState<number>(0);

  let [searchParams] = useSearchParams();
  let campaignId = useMemo(() => searchParams.get('campaignId'), [searchParams]); // not used as breadcrumb params, only for fetching campaign

  const breadcrumbParams: BreadCrumbParams[] = useMemo(() => [{ adGroupId }], [adGroupId]);

  const {
    initializeDatelessPerformanceGrid,
    saveGridConfig,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  } = useDatelessVectorPerformanceGrid(GridConfigSettings.AMAZON_OVERVIEW_NEGATIVE_TARGETS);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
  }>();

  useEffect(() => {
    initializeDatelessPerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      fetchEntity: fetchAllEntities
    });
  }, [adGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAllEntities = async (pageable: Pageable) => {
    await Promise.all([fetchNegativeTargets(pageable), fetchTargets(pageable), fetchCampaign()]);
  };

  const fetchNegativeTargets = async (pageable: Pageable) => {
    if (!breadcrumbParams) {
      return;
    }

    setIsLoading(true);

    const response = await getNegativeTargets([...breadcrumbParams], pageable);

    if (response.success && response.body) {
      setNegativeTargets(response?.body?.records);
      setRowCount(response?.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  // Adding extra fetches for targets and campaign
  const fetchTargets = async (pageable: Pageable) => {
    if (!breadcrumbParams) {
      return;
    }

    const response = await getTargets([...breadcrumbParams], pageable);

    if (response.success) {
      setTargets(response.body.dataGrid.records);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const fetchCampaign = async () => {
    if (!campaignId) {
      return;
    }

    const response = await getCampaign(campaignId);

    if (response.success) {
      setCampaign(response.body);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const { isShowing: isNegativeKeywordsDialogShowing, toggle: toggleNegativeKeywordsDialog } = useDialog();
  const { isShowing: isNegativeProductTargetsDialogShowing, toggle: toggleNegativeProductTargetsDialog } = useDialog();

  const shouldDisableAddNegativeKeywordsButton = () => {
    if (isLoading) {
      return true;
    }

    if (campaign?.targetingType === 'Auto') {
      return false;
    }

    if (!targets || targets.length === 0) {
      return true;
    }

    return !targets.some((target) => caseInsensitiveEquals(target.targetType, TargetType.Keyword));
  };

  const shouldDisableAddNegativeProductTargetsButton = () => {
    if (isLoading) {
      return true;
    }

    if (campaign?.targetingType === 'Auto') {
      return false;
    }

    if (!targets || targets.length === 0) {
      return true;
    }

    return targets.some((target) => target.targetType === 'Keyword');
  };

  return (
    <Box my={'0.5rem'} sx={{ height: '76vh' }}>
      <Box display="flex" alignItems={'center'}>
        <Box sx={{ mr: 'auto' }}></Box>
        <Button
          startIcon={<AddIcon fontSize="small" />}
          onClick={toggleNegativeKeywordsDialog}
          disabled={shouldDisableAddNegativeKeywordsButton()}
        >
          Add Negative Keywords
        </Button>
        <Button
          startIcon={<AddIcon fontSize="small" />}
          onClick={toggleNegativeProductTargetsDialog}
          disabled={shouldDisableAddNegativeProductTargetsButton()}
        >
          Add Negative Product Targets
        </Button>
      </Box>
      {!settingsLoaded ? (
        <DataGridPremium
          rows={[]}
          columns={NEGATIVE_TARGET_COLUMNS}
          loading={true}
          processRowUpdate={() => {}}
          initialState={{}}
          disableRowGrouping
          key={uuidv4()}
        />
      ) : (
        <VectorPerformanceGrid
          apiRef={apiRef}
          loading={isLoading}
          rows={negativeTargets}
          columns={NEGATIVE_TARGET_COLUMNS}
          initialState={initialSettings?.config}
          saveGridConfig={saveGridConfig}
          disableGridDateRangePicker={true}
          bottomMargin={30}
          getRowId={(row: any) => row.negativeTargetId}
          handleFilterModelChange={(newModel: GridFilterModel) => {
            handleFilterModelChange(newModel, settingsLoaded, apiRef, fetchNegativeTargets);
          }}
          handleSortModelChange={(newModel: GridSortModel) => {
            handleSortModelChange(newModel, settingsLoaded, apiRef, fetchNegativeTargets);
          }}
          handlePageChange={(page: number) => {
            handlePageChange(page, settingsLoaded, apiRef, fetchNegativeTargets);
          }}
          handlePageSizeChange={(pageSize: number) => {
            handlePageSizeChange(pageSize, settingsLoaded, apiRef, fetchNegativeTargets);
          }}
          rowCount={rowCount}
          processRowUpdate={() => {}}
        />
      )}

      <CreateNegativeKeywordsDialog
        open={isNegativeKeywordsDialogShowing}
        toggle={toggleNegativeKeywordsDialog}
        adGroupId={adGroupId}
        refreshNegativeTargets={() => handleRefreshEntities(settingsLoaded, apiRef, fetchNegativeTargets)}
      />
      <CreateNegativeTargetingClausesDialog
        open={isNegativeProductTargetsDialogShowing}
        isCampaignNegativeTarget={false}
        toggle={toggleNegativeProductTargetsDialog}
        refreshNegativeTargets={() => handleRefreshEntities(settingsLoaded, apiRef, fetchNegativeTargets)}
      />
    </Box>
  );
};
