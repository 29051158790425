import { Container, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridRowModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import KeywordBulkActions from '../../../shared/components/grid-components/bulk-actions/keywords/keyword-bulk-actions';
import Page from '../../../shared/components/page';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useSharedApi from '../../../shared/hooks/use-shared-api';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import useAdsApi, { BreadCrumbParams } from '../../../shared/hooks/use-wmt-map-updated-api';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { buildUpdateKeywordRequest } from '../../../shared/types/walmart-sams-club/keyword';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { BidConfirmationDialog } from '../../../walmart/components/grid-components/bid-confirmation-dialog';
import { KEYWORD_COLUMNS } from '../../components/grid-components/column-configurations/keyword-columns';

const Keywords: FC = () => {
  const { getKeywords, updateKeyword } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [keywords, setKeywords] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [promiseArguments, setPromiseArguments] = useState<any>(null);

  let [searchParams] = useSearchParams();
  let profileId = useMemo(() => (searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null), [searchParams]);
  let campaignId = useMemo(
    () => (searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null),
    [searchParams]
  );
  let adGroupId = useMemo(() => (searchParams.get('adGroupId') ? Number(searchParams.get('adGroupId')) : null), [searchParams]);

  const breadcrumbParams: BreadCrumbParams[] = useMemo(
    () => [{ profileId }, { campaignId }, { adGroupId }],
    [profileId, campaignId, adGroupId]
  );

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange
  } = useVectorPerformanceGrid(GridConfigSettings.SAMS_CLUB_KEYWORD);

  const apiRef = useGridApiRef();

  const { getUserSetting, updateUserSetting } = useSharedApi();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchKeywordsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchKeywordsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    setIsLoading(true);

    const response = await getKeywords([...breadcrumbParams], pageable, beginDate, endDate);

    if (response.success) {
      setKeywords(response.body.records);
      setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar('Failed to get keywords', { variant: 'error' });
    }

    setIsLoading(false);
  };

  const processRowUpdate = useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
    if (newRow.bid === oldRow.bid && newRow.state === oldRow.state) {
      return oldRow;
    }

    //Setting the promiseArguments will open the confirmation dialog if the bid is greater than suggested max bid
    if (Number(newRow.bid) > 10) {
      return new Promise<GridRowModel>((resolve, reject) => {
        setPromiseArguments({ resolve, reject, newRow, oldRow });
      });
    }

    setIsLoading(true);

    const response = await updateKeyword(newRow.profileId, buildUpdateKeywordRequest(newRow));

    setIsLoading(false);

    if (response.success && response.body.every((responseCode: any) => responseCode.code.toLowerCase() === 'success')) {
      return newRow;
    }

    enqueueSnackbar(response.body[0].details, { variant: 'error' });

    return oldRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    setIsLoading(true);

    const response = await updateKeyword(newRow.profileId, buildUpdateKeywordRequest(newRow));

    setIsLoading(false);

    if (response.success && response.body.every((responseCode: any) => responseCode.code.toLowerCase() === 'success')) {
      resolve(newRow);
    } else {
      enqueueSnackbar(response.body[0].details, { variant: 'error' });
      reject(oldRow);
    }

    setPromiseArguments(null);
  };

  return (
    <>
      <Page title="Keywords">
        <Container maxWidth="xl" style={{ height: '93vh' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Typography variant="h4">Keywords</Typography>
            {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
              <DataGridPremium
                rows={[]}
                columns={KEYWORD_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
                key={uuidv4()}
                disableRowGrouping
              />
            ) : (
              <VectorPerformanceGrid
                apiRef={apiRef}
                processRowUpdate={processRowUpdate}
                loading={isLoading}
                rows={keywords}
                columns={KEYWORD_COLUMNS}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                dateConfig={{
                  dateSettings: dateSettings,
                  dateSettingsLoading: dateSettingsLoading,
                  saveDateConfig: (dateRange: DateRange<Dayjs>) =>
                    handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchKeywordsData, setDateSettings)
                }}
                getRowId={(row: any) => row.keywordId}
                getRowClassName={(params) => `row-status--${params.row.state}`}
                bulkActions={<KeywordBulkActions setIsLoading={setIsLoading} />}
                handleFilterModelChange={(newModel: GridFilterModel) => {
                  handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchKeywordsData);
                }}
                handleSortModelChange={(newModel: GridSortModel) => {
                  handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchKeywordsData);
                }}
                handlePageChange={(page: number) => {
                  handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchKeywordsData);
                }}
                handlePageSizeChange={(pageSize: number) => {
                  handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchKeywordsData);
                }}
                rowCount={rowCount}
              />
            )}
          </Stack>
        </Container>
      </Page>
      <BidConfirmationDialog handleNo={handleNo} handleYes={handleYes} promiseArguments={promiseArguments} />
    </>
  );
};

export default Keywords;
