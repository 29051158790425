import React, { useEffect, useState, useCallback } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Tooltip,
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Page from '../../../page';
import { useAdsApi } from '../../../../hooks/use-walmart-sams-club-api';
import useDialog from '../../../../hooks/use-dialog';
import { WalmartAdItem } from '../../../../../walmart/types/ad';
import { Campaign } from '../../../../types/walmart-sams-club/campaign';
import {
  isSbEligibleForReview,
  orderByReviewIdDesc,
  Review as TReview,
  ReviewStepProps
} from '../../../../types/walmart-sams-club/review';
import { StyledConnector, StyledStepIcon } from './review-stepper';
import CompletedReview from './review-steps/completed-review';
import ProposalStep from './review-steps/proposal-review';
import UnsubmittedReviewStep from './review-steps/unsubmitted-review';
import { Keyword } from '../../../../types/walmart-sams-club/keyword';
import { ReviewStep, SBProfile } from '../../../../types/walmart-sams-club/sb-profile';
import { Filter, Pageable, buildPageableFilters } from '../../../../types/pageable';
import { Platform } from '../../../../types/platforms';
import { enqueueSnackbar } from 'notistack';

const MAX_SB_KEYWORD_LIMIT = 600;

const Review: React.FC = () => {
  const { getSbaReviews, getCampaign, getSbaProfiles, getAds, getKeywords, getAdGroups } = useAdsApi();
  const { toggle: toggleDialog, isShowing: dialogIsShowing, dynamicKey } = useDialog();
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeStep, setActiveStep] = useState<ReviewStep>(ReviewStep.Unsubmitted);
  const [isLoading, setIsLoading] = useState(true);

  const profileId = Number(searchParams.get('profileId'));
  const campaignId = Number(searchParams.get('campaignId'));
  const [adGroupId, setAdGroupId] = useState<number | null>(Number(searchParams.get('adGroupId')) || null);

  const [review, setReview] = useState<TReview>({
    adGroupId: 0,
    campaignId: 0,
    reviewComments: [{ commentType: '', comments: '' }],
    reviewId: 0,
    reviewStatus: ''
  });

  const [campaign, setCampaign] = useState<Campaign>();
  const [firstProfile, setFirstProfile] = useState<SBProfile>();
  const [secondProfile, setSecondProfile] = useState<SBProfile>();
  const [ads, setAds] = useState<Array<WalmartAdItem>>([]);
  const [keywords, setKeywords] = useState<Array<Keyword>>([]);

  const [reviewHistory, setReviewHistory] = useState<Array<TReview>>([]);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);

  const fetchData = useCallback(async () => {
    if (!profileId || !campaignId || !adGroupId) {
      return;
    }

    setIsLoading(true);

    try {
      const pageableFilters: Filter[] = buildPageableFilters({ profileId, campaignId, adGroupId });
      const pageable: Pageable = {
        filters: pageableFilters,
        sorts: [],
        offset: 0,
        limit: MAX_SB_KEYWORD_LIMIT // Keywords are the most numerous entity we fetch here, so we'll use this as the limit
      };

      const [sbReviewResponse, campaignResponse, sbProfileResponse, adResponse, keywordResponse] = await Promise.all([
        getSbaReviews(profileId, campaignId, adGroupId),
        getCampaign(profileId, campaignId),
        getSbaProfiles(profileId, campaignId, adGroupId),
        getAds(pageable),
        getKeywords(pageable)
      ]);

      if (sbReviewResponse.success) {
        const reviewArray = sbReviewResponse.body;
        if (reviewArray.length > 0) {
          reviewArray.sort(orderByReviewIdDesc);
          setReview(reviewArray[0]);
          setReviewHistory(reviewArray);
          setActiveStep(
            reviewArray[0].reviewStatus === 'Complete'
              ? ReviewStep.Complete
              : reviewArray[0].reviewStatus === 'Cancelled'
                ? ReviewStep.Unsubmitted
                : ReviewStep.Pending
          );
        } else {
          setActiveStep(ReviewStep.Unsubmitted);
        }
      }

      if (campaignResponse.success) {
        setCampaign(campaignResponse.body);
      }

      if (sbProfileResponse.success) {
        if (sbProfileResponse.body[0]) {
          setFirstProfile(sbProfileResponse.body[0]);
        }
        if (sbProfileResponse.body[1]) {
          setSecondProfile(sbProfileResponse.body[1]);
        }
      }

      if (adResponse.success) {
        setAds(adResponse.body.records);
      }

      if (keywordResponse.success) {
        setKeywords(keywordResponse.body.records);
      }
    } catch (error) {
      enqueueSnackbar('Error fetching data:', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, campaignId, adGroupId]);

  useEffect(() => {
    if (!profileId || !campaignId) {
      return;
    }

    if (!adGroupId) {
      getAdGroups(profileId, campaignId, null, null).then((response) => {
        if (response.success && response.body.length > 0) {
          const newAdGroupId = response.body[0].adGroupId;
          setAdGroupId(newAdGroupId);
          setSearchParams((prev) => {
            prev.set('adGroupId', newAdGroupId.toString());
            return prev;
          });
        }
      });
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, campaignId, adGroupId]);

  const isReady = isSbEligibleForReview({
    firstProfile: firstProfile,
    secondProfile: secondProfile,
    adItems: ads,
    keywords: keywords,
    retailerPlatform: Platform.WALMART.value
  });

  const stepProps: ReviewStepProps = {
    review,
    campaign,
    firstProfile,
    secondProfile,
    ads,
    keywords,
    isReady,
    profileId,
    campaignId,
    adGroupId,
    toggleDialog,
    dialogIsShowing,
    dynamicKey,
    refreshParent: fetchData
  };

  const reviewSteps = [
    {
      key: ReviewStep.Unsubmitted,
      state: 'unsubmitted',
      label:
        review?.reviewStatus === 'Cancelled' ? 'Cancelled' : activeStep > ReviewStep.Unsubmitted ? 'Submitted' : 'Unsubmitted',
      content: <UnsubmittedReviewStep {...stepProps} />
    },
    {
      key: ReviewStep.Pending,
      state: 'pending',
      label: 'Pending',
      content: <ProposalStep {...stepProps} />
    },
    {
      key: ReviewStep.Complete,
      state: 'complete',
      label: 'Complete',
      content: <CompletedReview {...stepProps} />
    }
  ];

  const LoadingSkeleton = () => (
    <>
      <Skeleton />
      <Card sx={{ mb: 0, mt: 2, px: 2 }}>
        <CardHeader
          title={
            <Typography variant="h6">
              <Skeleton height={100} />
            </Typography>
          }
          disableTypography={true}
          sx={{ py: 0 }}
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ height: '155px' }}>
            <Skeleton height={50} width={'50%'} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ mt: 2 }}>
        <Skeleton height={60} />
      </Box>
    </>
  );

  return (
    <Page title={`Review Status - ${campaign?.name}`}>
      <Box>
        <Container maxWidth="xl">
          <Container maxWidth="md" sx={{ mt: 2 }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h4">{isLoading ? <Skeleton width={500} /> : campaign?.name}</Typography>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    {reviewHistory.length > 1 && !isLoading && (
                      <Tooltip title="Review History" placement="bottom">
                        <IconButton aria-label="history" onClick={() => setHistoryDialogOpen(true)}>
                          <HistoryIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h5">{isLoading ? <Skeleton width={100} /> : 'Review Status'}</Typography>
              </Grid>
              <Grid item>
                {activeStep === ReviewStep.Pending && !isLoading && (
                  <Tooltip title="Refresh page" placement="bottom">
                    <IconButton aria-label="refresh" onClick={() => fetchData()}>
                      <RefreshIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>

            <Box sx={{ mt: 5 }}>
              {!isLoading ? (
                <>
                  <Stepper activeStep={activeStep} connector={<StyledConnector />} sx={{ mb: 2 }}>
                    {reviewSteps.map((step, index) => (
                      <Step key={step.label}>
                        <StepLabel StepIconComponent={StyledStepIcon}>
                          <Typography
                            variant={index === activeStep ? 'body1' : 'body2'}
                            sx={{ fontWeight: index === activeStep ? 'bold' : 'normal' }}
                          >
                            {step.label}
                          </Typography>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  {reviewSteps.find((step) => step.key === activeStep)?.content}
                </>
              ) : (
                <LoadingSkeleton />
              )}
            </Box>
          </Container>
        </Container>
        <Dialog open={historyDialogOpen} onClose={() => setHistoryDialogOpen(false)} maxWidth="lg">
          <DialogTitle>Review History</DialogTitle>
          <DialogContent>
            <List>
              {reviewHistory.map((review, index) => (
                <ListItem disablePadding key={review.reviewId}>
                  <ListItemText
                    primary={`${index + 1}: ${review.reviewId}${index === 0 ? ' (Most recent)' : ''}`}
                    secondary={review?.reviewStatus}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>
      </Box>
    </Page>
  );
};

export default Review;
