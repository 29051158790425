import { GridFilterModel } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { enqueueSnackbar } from 'notistack';
import {
  DEFAULT_ROW_COUNT,
  Filter,
  Pageable,
  Sort,
  convertGridFilterModelToCustomFilters,
  processSortsFromGrid
} from '../types/pageable';
import { IGridConfigSettingItem } from '../utilities/grid-config-settings';
import useSharedApi from './use-shared-api';

interface InitializeDatelessPerformanceGridProps {
  setInitialSettings: React.Dispatch<
    React.SetStateAction<
      | {
          pageable?: Pageable;
          config: GridInitialStatePremium;
          shouldShowChart?: boolean;
        }
      | undefined
    >
  >;
  setSettingsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  fetchEntity: (pageable: Pageable) => Promise<void>;
}

const useDatelessVectorPerformanceGrid = (gridSetting: IGridConfigSettingItem) => {
  const { getUserSetting, updateUserSetting } = useSharedApi();

  const initializeDatelessPerformanceGrid = async ({
    setInitialSettings,
    setSettingsLoaded,
    fetchEntity
  }: InitializeDatelessPerformanceGridProps) => {
    fetchGridConfig(gridSetting)
      .then((loadedGridConfig) => {
        let updatedFilters: Filter[] = [];
        let updatedSorts: Sort[] = [];

        if (loadedGridConfig?.filter?.filterModel) {
          const filtersFromConfig: Filter[] = convertGridFilterModelToCustomFilters(loadedGridConfig.filter.filterModel);
          updatedFilters = updatedFilters.concat(filtersFromConfig);
        }

        if (loadedGridConfig?.sorting?.sortModel) {
          updatedSorts = processSortsFromGrid(loadedGridConfig.sorting.sortModel);
        }

        const loadedPageable: Pageable = {
          filters: updatedFilters,
          sorts: updatedSorts,
          limit: loadedGridConfig?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
          offset: loadedGridConfig?.pagination?.paginationModel?.page || 0
        };

        setInitialSettings({
          pageable: loadedPageable,
          config: loadedGridConfig
        });
        setSettingsLoaded(true);

        return { loadedPageable };
      })
      .then(({ loadedPageable }: { loadedPageable: Pageable }) => {
        fetchEntity(loadedPageable);
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  const fetchGridConfig = async (currentGridSetting: IGridConfigSettingItem) => {
    let loadedGridConfig: GridInitialStatePremium;

    const response = await getUserSetting(currentGridSetting.SettingsKey);

    if (response.success && response.body && typeof response.body === 'string') {
      const gridConfig = JSON.parse(response.body);
      loadedGridConfig = gridConfig;
    } else {
      loadedGridConfig = currentGridSetting.DefaultSetting;
    }

    return loadedGridConfig;
  };

  const saveGridConfig = async (gridConfig: GridInitialStatePremium) => {
    const response = await updateUserSetting(gridSetting.SettingsKey, JSON.stringify(gridConfig));
    return response;
  };

  const handleFilterModelChange = async (newModel: GridFilterModel, settingsLoaded: boolean, apiRef: any, fetchEntity: any) => {
    if (!settingsLoaded) return;

    if (newModel?.items?.some((filter: any) => !filter?.value)) {
      return; // Stop execution if any filter has no value
    }

    const currentState = apiRef.current.exportState();

    const updatedPageable: Pageable = {
      filters: currentState?.filter?.filterModel ? convertGridFilterModelToCustomFilters(currentState?.filter?.filterModel) : [],
      sorts: currentState?.sorting?.sortModel ? processSortsFromGrid(currentState.sorting.sortModel) : [],
      limit: currentState?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
      offset: currentState?.pagination?.paginationModel?.page || 0
    };

    fetchEntity(updatedPageable);
    saveGridConfig(currentState);
  };

  const handleSortModelChange = async (newModel: any, settingsLoaded: boolean, apiRef: any, fetchEntity: any) => {
    if (!settingsLoaded) return;

    const currentState = apiRef.current.exportState();

    const updatedPageable: Pageable = {
      filters: currentState?.filter?.filterModel ? convertGridFilterModelToCustomFilters(currentState?.filter?.filterModel) : [],
      sorts: currentState?.sorting?.sortModel ? processSortsFromGrid(currentState.sorting.sortModel) : [],
      limit: currentState?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
      offset: currentState?.pagination?.paginationModel?.page || 0
    };

    fetchEntity(updatedPageable);
    saveGridConfig(currentState);
  };

  const handlePageSizeChange = async (newPageSize: number, settingsLoaded: boolean, apiRef: any, fetchEntity: any) => {
    if (!settingsLoaded) return;

    const currentState = apiRef.current.exportState();

    const updatedPageable: Pageable = {
      filters: currentState?.filter?.filterModel ? convertGridFilterModelToCustomFilters(currentState?.filter?.filterModel) : [],
      sorts: currentState?.sorting?.sortModel ? processSortsFromGrid(currentState.sorting.sortModel) : [],
      limit: newPageSize,
      offset: currentState?.pagination?.paginationModel?.page || 0
    };

    fetchEntity(updatedPageable);
    saveGridConfig(currentState);
  };

  const handlePageChange = async (newPage: number, settingsLoaded: boolean, apiRef: any, fetchEntity: any) => {
    if (!settingsLoaded) return;

    const currentState = apiRef.current.exportState();

    const updatedPageable: Pageable = {
      filters: currentState?.filter?.filterModel ? convertGridFilterModelToCustomFilters(currentState?.filter?.filterModel) : [],
      sorts: currentState?.sorting?.sortModel ? processSortsFromGrid(currentState.sorting.sortModel) : [],
      limit: currentState?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
      offset: newPage
    };

    fetchEntity(updatedPageable);
    saveGridConfig(currentState);
  };

  const handleRefreshEntities = async (settingsLoaded: boolean, apiRef: any, fetchEntity: any) => {
    if (!settingsLoaded) return;

    const currentState = apiRef.current.exportState();

    const updatedPageable: Pageable = {
      filters: currentState?.filter?.filterModel ? convertGridFilterModelToCustomFilters(currentState?.filter?.filterModel) : [],
      sorts: currentState?.sorting?.sortModel ? processSortsFromGrid(currentState.sorting.sortModel) : [],
      limit: currentState?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
      offset: currentState?.pagination?.paginationModel?.page || 0
    };

    fetchEntity(updatedPageable);
    saveGridConfig(currentState);
  };

  return {
    initializeDatelessPerformanceGrid,
    saveGridConfig,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  };
};

export default useDatelessVectorPerformanceGrid;
