import CachedIcon from '@mui/icons-material/Cached';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import ErrorIcon from '@mui/icons-material/Error';
import PreviewIcon from '@mui/icons-material/Preview';
import Info from '@mui/icons-material/Info';
import { ResponseObject } from '../../utilities/fetch-utilities';
import { Campaign } from './campaign';
import { WalmartAdItem } from '../../../walmart/types/ad';
import { Keyword, minWalmartSamsClubAdItems } from './keyword';
import { SBProfile } from './sb-profile';
import { SamsClubAdItem } from '../../../sams-club/types/ad';
import { Platform, PlatformValue } from '../platforms';

export const REVIEW_STATUS_OPTIONS = [
  {
    label: 'Pending',
    value: 'pending',
    icon: PreviewIcon
  },
  {
    label: 'In Progress',
    value: 'inProgress',
    icon: CachedIcon
  },
  {
    label: 'Complete',
    value: 'complete',
    icon: CheckIcon
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
    icon: DoDisturbIcon
  }
];

export const ITEM_STATUS_OPTIONS = [
  {
    label: 'Unreviewed',
    value: 'pending',
    icon: Info
  },
  {
    label: 'Approved',
    value: 'approved',
    icon: CheckIcon
  },
  {
    label: 'Rejected',
    value: 'rejected',
    icon: ErrorIcon
  }
];

export interface ReviewComments {
  commentType?: string;
  comments?: string;
}

export interface Review {
  adGroupId: number;
  campaignId: number;
  reviewComments: ReviewComments[];
  reviewId: number;
  reviewStatus: string;
}

export interface ReviewStepProps {
  review: Review;
  campaign?: Campaign;
  firstProfile?: SBProfile;
  secondProfile?: SBProfile;
  ads: WalmartAdItem[] | SamsClubAdItem[];
  keywords: Keyword[];
  isReady: boolean;
  profileId: number | null;
  campaignId: number | null;
  adGroupId: number | null;
  toggleDialog: () => void;
  dialogIsShowing: boolean;
  dynamicKey: number;
  refreshParent?: () => void;
}

export interface SbReviewRequest {
  campaignId: string;
  adGroupId: string;
}

export interface CancelSbReviewRequest {
  campaignId: string;
  adGroupId: string;
  reviewId: string;
}

export const isSbResponseEligibleForReview = (response: ResponseObject) => {
  const { adGroups, keywords, adItems, sbaProfile, sbaProfileImage, campaign } = response.body;

  return (
    adGroups?.every((adGroup: any) => adGroup?.code?.toLowerCase() === 'success') &&
    keywords?.every((keyword: any) => keyword?.code?.toLowerCase() === 'success') &&
    adItems?.every((adItem: any) => adItem?.code?.toLowerCase() === 'success') &&
    sbaProfileImage?.code?.toLowerCase() === 'success' &&
    sbaProfile?.code?.toLowerCase() === 'success' &&
    campaign?.code?.toLowerCase() === 'success' &&
    keywords.length >= 1 &&
    adItems.length >= 2
  );
};

interface isSbEligibleForReviewProps {
  firstProfile?: SBProfile | null;
  secondProfile?: SBProfile | null;
  keywords: Keyword[];
  adItems: WalmartAdItem[] | SamsClubAdItem[];
  retailerPlatform: typeof Platform.WALMART.value | typeof Platform.SAMS_CLUB.value;
}

export const isSbEligibleForReview = (props: isSbEligibleForReviewProps) => {
  const atLeastOneProfileExists = !!props.firstProfile || !!props.secondProfile;
  const hasMinimumAdItems = props.adItems.length >= minWalmartSamsClubAdItems[props.retailerPlatform];
  const hasMinimumKeywords = props.keywords.length >= 1;

  return atLeastOneProfileExists && hasMinimumKeywords && hasMinimumAdItems;
};

export function orderByReviewIdDesc(a: { reviewId: number }, b: { reviewId: number }) {
  //Walmart/Sam's sends reviews back such that the last item is the most recent
  //We will currently sort by reviewId, which is currently an auto-increment number
  if (a.reviewId > b.reviewId) {
    return -1;
  }

  if (a.reviewId < b.reviewId) {
    return 1;
  }

  return 0;
}

export const InitialReviewState: Review = {
  adGroupId: 0,
  campaignId: 0,
  reviewComments: [{ commentType: '', comments: '' }],
  reviewId: 0,
  reviewStatus: ''
};
