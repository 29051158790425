import LinkIcon from '@mui/icons-material/Link';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams, getGridSingleSelectOperators } from '@mui/x-data-grid-premium';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';
import { renderEntityId } from '../../../../shared/components/grid-components/renderer/renderEntityId';
import { ExtendedGridColDef } from '../../../../shared/types/grid-types';
import { Platform } from '../../../../shared/types/platforms';
import { renderAdActions } from '../../../../walmart/components/grid-components/renderer/ad/renderActiveToggle';
import { renderAdGroupStatus } from '../../../../walmart/components/grid-components/renderer/ad/renderAdGroupStatus';
import {
  preProcessBidEditCellProps,
  renderEditBid
} from '../../../../walmart/components/grid-components/renderer/ad/renderEditBid';
import { renderAdStatus } from '../../../../walmart/components/grid-components/renderer/ad/renderStatus';
import { renderAdNameWithActions } from '../renderer/ads/render-ad-name-with-actions';
import { METRIC_COLUMNS } from './metric-columns';

export const AD_COLUMNS: ExtendedGridColDef[] = [
  {
    field: 'actions',
    headerName: 'Active',
    filterable: false,
    type: 'actions',
    width: 80,
    getActions: renderAdActions
  },
  {
    field: 'itemImageUrl',
    headerName: 'Item Image',
    width: 160,
    minWidth: 60,
    editable: false,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Tooltip
          placement="bottom-start"
          title={
            <Box display="flex" sx={{ opacity: 1 }}>
              <LinkIcon color="primary" />
              <Link href={params.row.itemPageUrl} target="_blank" rel="noopener">
                <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 300, textDecoration: 'underline' }}>
                  {params.row.itemPageUrl}
                </Typography>
              </Link>
            </Box>
          }
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'background.default'
              }
            }
          }}
        >
          <Link href={params.row.itemPageUrl} target="_blank" rel="noopener">
            <img
              src={params.value}
              style={{
                height: 40,
                width: 40
              }}
            />
          </Link>
        </Tooltip>
      );
    }
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 400,
    minWidth: 160,
    editable: false,
    renderCell: renderAdNameWithActions(Platform.SAMS_CLUB.value)
  },
  {
    field: 'campaignName',
    headerName: 'Campaign Name',
    width: 300,
    minWidth: 160,
    editable: false
  },
  {
    field: 'adGroupName',
    headerName: 'Ad Group Name',
    width: 300,
    minWidth: 160,
    editable: false
  },
  {
    field: 'adGroupStatus',
    headerName: 'Ad Group Status',
    filterable: false,
    width: 160,
    minWidth: 105,
    renderCell: renderAdGroupStatus
  },
  {
    field: 'itemId',
    headerName: 'Item Id',
    type: 'string',
    width: 160,
    minWidth: 100,
    editable: false,
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'status',
    headerName: 'Active Status',
    type: 'singleSelect',
    valueOptions: ['Enabled', 'Disabled'],
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    width: 160,
    minWidth: 105,
    renderCell: renderAdStatus
  },
  {
    field: 'bid',
    headerName: 'Bid',
    width: 100,
    minWidth: 60,
    type: 'number',
    headerAlign: 'left',
    editable: true,
    valueFormatter: renderAsCurrencyFormatter,
    preProcessEditCellProps: preProcessBidEditCellProps,
    cellClassName: () => {
      return 'numeric-edit-cell';
    },
    renderEditCell: renderEditBid
  },
  {
    field: 'adItemId',
    headerName: 'Ad Item Id',
    type: 'number',
    width: 160,
    minWidth: 90,
    editable: false,
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'adGroupId',
    headerName: 'Ad Group Id',
    type: 'number',
    width: 160,
    minWidth: 80,
    editable: false,
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'campaignId',
    headerName: 'Campaign Id',
    type: 'number',
    width: 160,
    minWidth: 80,
    editable: false,
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'reviewStatus',
    headerName: 'Review Status',
    type: 'singleSelect',
    valueOptions: ['Pending', 'Approved', 'Rejected'],
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    width: 160,
    minWidth: 120,
    editable: false
  },
  {
    field: 'reviewReason',
    headerName: 'Review Reason',
    width: 160,
    minWidth: 120,
    editable: false
  },
  {
    field: 'itemPageUrl',
    headerName: 'Item Page URL',
    width: 160,
    minWidth: 120,
    editable: false
  },
  ...METRIC_COLUMNS
];
