import { useEffect, useState, forwardRef, ChangeEvent } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  TextField,
  Typography,
  SxProps,
  Theme,
  Collapse,
  IconButton,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { alpha } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { CurrencyInput } from '../../../../shared/components/inputs/currency-input';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { samsClubCampaignTypes } from '../../../types/campaign';
import DeleteIcon from '@mui/icons-material/Delete';
import { readFile, loadImage, blobUrlToFile } from '../../../../shared/utilities/image-utilities';
import { budgetTypes } from '../../../../shared/types/walmart-sams-club/campaign';
import dayjs from 'dayjs';

interface ProfileAutoCompleteProps {
  profiles: any[];
  handleProfileChange: (event: any, value: any) => void;
  profileId: number | null;
}

export const ProfileAutocomplete = (props: ProfileAutoCompleteProps) => {
  const { profiles, handleProfileChange, profileId } = props;

  return (
    <Autocomplete
      disablePortal
      options={profiles}
      getOptionLabel={(profile) => profile.name}
      onChange={handleProfileChange}
      value={profiles.find((profile: { profileId: any }) => profile.profileId === profileId) || null}
      sx={{ width: 300, mb: 2 }}
      renderInput={(params) => <TextField {...params} size="small" label="Profile" />}
    />
  );
};

interface FormikInputProps {
  formik: any;
}

export const CampaignNameTextField = (props: any) => {
  const { formik } = props;

  return (
    <TextField
      error={Boolean(formik.touched.name && formik.errors.name)}
      fullWidth
      helperText={formik.touched.name && formik.errors.name}
      label="Campaign Name"
      name="name"
      size="small"
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.name}
    />
  );
};

const StartDatePicker = (props: FormikInputProps) => {
  const { formik } = props;
  const startDateError = Boolean(formik.touched.startDate && formik.errors.startDate);

  return (
    <DatePicker
      label="Start Date"
      value={dayjs(formik.values.startDate)}
      slotProps={{
        actionBar: { actions: ['clear'] },
        textField: {
          onBlur: formik.handleBlur,
          error: startDateError,
          name: 'startDate',
          size: 'small',
          sx: { mr: 2 },
          helperText: (formik.touched.startDate && formik.errors.startDate) || ' '
        }
      }}
      onChange={(date) => {
        formik.setFieldValue('startDate', date);
        formik.setFieldTouched('startDate', true, false);
      }}
      onAccept={(date) => {
        formik.setFieldTouched('startDate', true, false);
      }}
    />
  );
};

const EndDatePicker = (props: FormikInputProps) => {
  const { formik } = props;

  return (
    <DatePicker
      label="End Date"
      value={dayjs(formik.values.endDate)}
      slotProps={{
        actionBar: { actions: ['clear'] },
        textField: {
          onBlur: formik.handleBlur,
          helperText: (formik.touched.endDate && formik.errors.endDate) || ' ',
          name: 'endDate',
          size: 'small',
          error: Boolean(formik.touched.endDate && formik.errors.endDate)
        }
      }}
      onChange={(date) => {
        formik.setFieldValue('endDate', date);
        formik.setFieldTouched('endDate', true, false);
      }}
      onAccept={(date) => {
        formik.setFieldTouched('endDate', true, false);
      }}
    />
  );
};

export const DateRangeSettings = (props: FormikInputProps) => {
  const { formik } = props;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StartDatePicker formik={formik} />
      </LocalizationProvider>
      <Box sx={{ ml: 0 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <EndDatePicker formik={formik} />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export const BudgetTypeSelector = (props: FormikInputProps) => {
  const { formik } = props;

  return (
    <TextField
      error={Boolean(formik.touched.budgetType && formik.errors.budgetType)}
      fullWidth
      size="small"
      label="Budget Type"
      name="budgetType"
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      select
      value={formik.values.budgetType}
    >
      {budgetTypes.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

const DailyBudgetInput = (props: FormikInputProps) => {
  const { formik } = props;

  return (
    <CurrencyInput
      label="Daily Budget"
      value={formik.values.dailyBudget}
      onChange={(event) => {
        formik.setFieldValue('dailyBudget', event.target.value);
      }}
      name="dailyBudget"
      fullWidth
      sx={{ mt: 2, mr: 2, maxWidth: '200px' }}
      size="small"
      error={Boolean(formik.touched.dailyBudget && formik.errors.dailyBudget)}
      helperText={(formik.touched.dailyBudget && formik.errors.dailyBudget) || ' '}
      onBlur={formik.handleBlur}
      disabled={!['daily', 'both'].includes(formik.values.budgetType)}
    />
  );
};

const TotalBudgetInput = (props: FormikInputProps) => {
  const { formik } = props;

  return (
    <CurrencyInput
      label="Total Budget"
      value={formik.values.totalBudget}
      onChange={(event) => {
        formik.setFieldValue('totalBudget', event.target.value);
      }}
      name="totalBudget"
      fullWidth
      sx={{ mt: 2, mr: 2, maxWidth: '200px' }}
      size="small"
      error={Boolean(formik.touched.totalBudget && formik.errors.totalBudget)}
      helperText={(formik.touched.totalBudget && formik.errors.totalBudget) || ' '}
      onBlur={formik.handleBlur}
      disabled={!['total', 'both'].includes(formik.values.budgetType)}
    />
  );
};

export const BudgetSettings = (props: FormikInputProps) => {
  const { formik } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BudgetTypeSelector formik={formik} />
      </Grid>
      <Grid item xs={12}>
        {['daily', 'both'].includes(formik.values.budgetType) ? <DailyBudgetInput formik={formik} /> : null}
        {['total', 'both'].includes(formik.values.budgetType) ? <TotalBudgetInput formik={formik} /> : null}
      </Grid>
    </Grid>
  );
};

interface CustomTypeOptionRadioButtonProps {
  checked?: boolean;
  handleClick: () => void;
  typeOptionValue: string;
  typeOptionLabel: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

export const CustomTypeOptionRadioButton = forwardRef(function CustomTypeOptionRadioButton(
  props: CustomTypeOptionRadioButtonProps,
  ref
) {
  const { checked, handleClick, typeOptionValue, typeOptionLabel, disabled, sx, ...other } = props;
  return (
    <Box {...other} ref={ref}>
      <Card
        sx={[
          {
            alignItems: 'center',
            cursor: 'pointer',
            display: 'flex',
            px: 1,
            pr: 2,
            ...(checked && {
              borderColor: 'primary.main',
              borderWidth: 2,
              backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
              m: '-1px'
            }),
            ...(disabled && {
              borderColor: 'grey',
              borderWidth: 2,
              backgroundColor: 'grey',
              m: '-1px',
              cursor: 'not-allowed'
            })
          },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
        onClick={disabled ? undefined : handleClick}
        variant="outlined"
      >
        <Radio checked={checked} color="primary" disabled={disabled} />
        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle1">{typeOptionLabel}</Typography>
        </Box>
      </Card>
    </Box>
  );
});

export const CampaignTypeSettings = (props: FormikInputProps) => {
  const { formik } = props;

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} sx={{ display: 'flex' }}>
      <Grid item sx={{ mb: 2 }}>
        <FormControl sx={{ width: '250px' }}>
          <InputLabel>Campaign Type</InputLabel>
          <Select
            value={formik.values.campaignType}
            label="Campaign Type"
            onChange={(event) => {
              formik.setFieldValue('campaignType', event.target.value);
            }}
          >
            {samsClubCampaignTypes.map((typeOption) => (
              <MenuItem key={typeOption.value} value={typeOption.value}>
                {typeOption.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const SBProfileBuilder = (props: FormikInputProps) => {
  const { formik } = props;

  const [selectedImage, setSelectedImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState('');
  const [dynamicInputKey, setDynamicInputKey] = useState(0);

  useEffect(() => {
    if (!!selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    if (!!formik.values.logoUrl) {
      setImageUrl(formik.values.logoUrl);
      blobUrlToFile(formik.values.logoUrl).then((imageFile) => {
        formik.setFieldValue('imageFile', imageFile);
        readFile(imageFile).then((resultDataUri) => {
          formik.setFieldValue('logoDataUri', resultDataUri);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.logoUrl]);

  function handleResetImageItems() {
    setSelectedImage(undefined);
    setImageUrl('');
    //update dynamic 'key' to force rerender on uncontrolled input[type="file"] to ensure it's value is cleared
    setDynamicInputKey((previousValue) => previousValue + 1);
    formik.setErrors({ logoDataUri: undefined });
  }

  const handleImageOnChange = async (changeEvent: ChangeEvent<HTMLInputElement>) => {
    if (!changeEvent.target.files) {
      return;
    }

    const MAX_FILE_SIZE = 200000;
    const MAX_WIDTH = 300;
    const MAX_HEIGHT = 180;

    const uploadedImage = changeEvent.target.files[0];

    setSelectedImage(uploadedImage);
    formik.setErrors({ logoDataUri: undefined });
    formik.setFieldValue('logoDataUri', '');

    readFile(uploadedImage).then((resultDataUri) => {
      if (uploadedImage.size > MAX_FILE_SIZE) {
        formik.setErrors({ logoDataUri: 'Logo images cannot be more than 200kb' });
        return;
      }

      loadImage(resultDataUri).then((image) => {
        if (image.height > MAX_HEIGHT || image.width > MAX_WIDTH) {
          formik.setErrors({ logoDataUri: 'Image must be 300px wide and 180px tall' });
          return;
        }

        formik.setFieldValue('logoDataUri', resultDataUri);
      });
    });
  };

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
      <Grid item sx={{ mt: 1 }}>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item xs={3}>
            <Button variant="contained" color="primary" startIcon={<AddAPhotoIcon />} component="label">
              <input
                //by updating the key, we ensure that the input rerenders & it's value is 'cleared'
                key={dynamicInputKey}
                accept="image/*"
                type="file"
                id="select-image"
                style={{ display: 'none' }}
                name="logoImg"
                onChange={handleImageOnChange}
              />
              Upload Image
            </Button>
            <Typography variant="caption" display="block" color="error">
              {!!selectedImage && formik.errors.logoDataUri}
            </Typography>
          </Grid>
          <Grid item>
            <Collapse in={!!imageUrl}>
              <Grid container>
                <Grid item>
                  <img src={imageUrl} alt={!!selectedImage ? selectedImage.name : ''} height="90px" />
                </Grid>
                <Grid item>
                  {' '}
                  <IconButton aria-label="delete" onClick={handleResetImageItems}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ flex: 1 }}>
        {' '}
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              error={Boolean(formik.touched.searchAmpName && formik.errors.searchAmpName)}
              fullWidth
              helperText={formik.touched.searchAmpName && formik.errors.searchAmpName}
              label="Brand Name"
              name="searchAmpName"
              size="small"
              sx={{ width: '100%' }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.searchAmpName}
            />
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <TextField
              error={Boolean(formik.touched.headlineText && formik.errors.headlineText)}
              fullWidth
              helperText={formik.touched.headlineText && formik.errors.headlineText}
              label="Headline Text"
              name="headlineText"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.headlineText}
            />
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <TextField
              error={Boolean(formik.touched.clickUrl && formik.errors.clickUrl)}
              fullWidth
              helperText={formik.touched.clickUrl && formik.errors.clickUrl}
              label="Click URL"
              name="clickUrl"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InsertLinkIcon />
                  </InputAdornment>
                )
              }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.clickUrl}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
