import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useCurrentRetailerPlatform } from '../../../../../hooks/use-current-retailer-platform';
import { toCamelCase } from '../../../../../utilities/string-utilities';

interface CreateReviewDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

export const CreateReviewDialog = ({ open, onClose, onSave }: CreateReviewDialogProps) => {
  const currentRetailer = useCurrentRetailerPlatform();

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Create Review</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>
            By clicking "Submit Review", Vector will automatically send a review to{' '}
            <strong>{toCamelCase(currentRetailer)}</strong> for approval.
          </p>
          <p>Come back to this page to see the status of your review. You can also cancel the review at any time.</p>
        </DialogContentText>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" onClick={onSave}>
            Submit Review
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
