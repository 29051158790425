import { AppBar, AppBarProps, Box, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FC, useEffect, useState } from 'react';
import { Menu as MenuIcon } from '../../icons/menu';
import { AccountButton } from './account-button';
import { ApiHealthCheckDisplay } from './api-health-check-display';
import SettingsButton from './settings-button';
import AmazonBreadcrumbs from '../../components/page-components/amazon-bread-crumbs';
import WalmartAndSamsClubBreadCrumbs from '../../components/page-components/walmart-and-sams-club-breadcrumbs';
('../../components/page-components/breadcrumbs');
import { useCurrentRetailerPlatform } from '../../hooks/use-current-retailer-platform';
import { useLocation } from 'react-router';
import { Platform, PlatformValue } from '../../types/platforms';

interface DashboardNavbarProps extends AppBarProps {
  onOpenSidebar?: () => void;
  isSidebarOpen?: boolean;
}

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
        boxShadow: theme.shadows[3]
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        boxShadow: 'none'
      })
}));

export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onOpenSidebar, isSidebarOpen, ...other } = props;
  const [currentPlatform, setCurrentPlatform] = useState(useCurrentRetailerPlatform());

  const location = useLocation();

  useEffect(() => {
    setCurrentPlatform(location.pathname.split('/')[1] as PlatformValue);
  }, [location]);

  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: isSidebarOpen ? 220 : 70
          },
          width: {
            lg: `calc(100% - ${isSidebarOpen ? 220 : 70}px)`
          },
          transition: isSidebarOpen ? 'left 0.22s ease-in-out' : 'left 0.2s ease-in-out'
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            left: 0,
            px: 2
          }}
          variant="dense"
        >
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          {currentPlatform === Platform.AMAZON.value && <AmazonBreadcrumbs />}
          {(currentPlatform === Platform.WALMART.value || currentPlatform === Platform.SAMS_CLUB.value) && (
            <WalmartAndSamsClubBreadCrumbs />
          )}
          <ApiHealthCheckDisplay retailerPlatform={currentPlatform} />
          <Box sx={{ flexGrow: 1 }} />
          <SettingsButton />
          <AccountButton />
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};
