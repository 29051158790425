import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState, useEffect } from 'react';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import { useSnackbar } from 'notistack';
import AmazonAddNegativeTargetingClauses from './amazon-add-negative-targeting-clauses';

interface CreateNegativeTargetingClausesDialogProps {
  open: boolean;
  toggle: () => void;
  refreshNegativeTargets?: () => void;
  isCampaignNegativeTarget: boolean;
}

const CreateNegativeTargetingClausesDialog: React.FC<CreateNegativeTargetingClausesDialogProps> = ({
  open,
  toggle,
  refreshNegativeTargets,
  isCampaignNegativeTarget
}) => {
  const [negativeTargetingClauses, setNegativeTargetingClauses] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mounted, setMounted] = useState(false);

  const { createNegativeTargets, createCampaignNegativeTargets } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (!open) {
      setNegativeTargetingClauses([]);
      setIsSubmitting(false);
    }
  }, [open]);

  const handleSubmit = async () => {
    if (!mounted) return;
    setIsSubmitting(true);

    try {
      if (negativeTargetingClauses && negativeTargetingClauses.length > 0) {
        let response;
        if (isCampaignNegativeTarget) {
          response = await createCampaignNegativeTargets(negativeTargetingClauses);
        } else {
          response = await createNegativeTargets(negativeTargetingClauses);
        }

        if (response.success) {
          enqueueSnackbar('Negative Keywords created successfully!', { variant: 'success' });
          refreshNegativeTargets?.();
          toggle();
        } else {
          enqueueSnackbar(response.errorMessage, { variant: 'error' });
        }
      }
    } catch (error: any) {
      enqueueSnackbar(`Error occurred: ${error?.message}`, { variant: 'error' });
    } finally {
      if (mounted) {
        setIsSubmitting(false);
      }
    }
  };

  const handleClose = () => {
    if (!isSubmitting) {
      toggle();
    }
  };

  // Only render the dialog if the component is mounted to avoid re renders
  if (!mounted) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      sx={{
        '& .MuiDialog-paper': {
          margin: 2
        }
      }}
    >
      <DialogTitle>Negative Product Targeting</DialogTitle>
      <DialogContent>
        <Box>
          <AmazonAddNegativeTargetingClauses
            negativeTargetingClauses={negativeTargetingClauses}
            isCampaignNegativeTarget={isCampaignNegativeTarget}
            setNegativeTargetingClauses={setNegativeTargetingClauses}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || negativeTargetingClauses.length === 0}
        >
          Add Negative Targets
        </Button>
      </DialogActions>
      {isSubmitting && (
        <Backdrop
          open
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: '#fff',
            position: 'absolute'
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Dialog>
  );
};

export default React.memo(CreateNegativeTargetingClausesDialog);
