import { Dispatch, SetStateAction } from 'react';
import { Review } from './review';

export interface SBProfileBuilderRequest {
  searchAmpName: string;
  headlineText: string;
  clickUrl: string;
  logoDataUri?: string;
}

export interface SBProfileUpdateRequest extends Partial<SBProfileBuilderRequest> {
  campaignId: number;
  adGroupId: number;
  sbaProfileId: number;
  status?: string;
}

export interface SBProfile {
  searchAmpName: string;
  headlineText: string;
  clickUrl: string;
  logoUrl: string;
  reviewStatus: string;
  reviewReason: string | null;
  sbaProfileId: number;
  adGroupId: number;
  status: string;
  code: string;
  details: string | null;
}

export interface UpdateSBProfile extends SBProfile {
  logoDataUri?: string;
}

export const DefaultSBProfile: SBProfile = {
  searchAmpName: '',
  headlineText: '',
  clickUrl: '',
  logoUrl: '',
  reviewStatus: '',
  reviewReason: '',
  sbaProfileId: -1,
  adGroupId: -1,
  status: 'disabled',
  code: '',
  details: ''
};

export const SBProfileStatus = {
  ENABLED: 'Enabled',
  DISABLED: 'Disabled'
};

export interface ManageSBProfilesProps {
  firstProfile?: SBProfile;
  secondProfile?: SBProfile;
  profileId: number;
  campaignId: number;
  adGroupId: number;
  refreshParent: () => void;
  setCurrentDialogState: Dispatch<SetStateAction<string>>;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  review?: Review;
}

export enum ReviewStep {
  Unsubmitted = 0,
  Pending = 1,
  Complete = 2
}
