import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_DETAIL_PANEL_TOGGLE_FIELD } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

export interface IGridConfigSettingItem {
  SettingsKey: string;
  DefaultSetting: GridInitialStatePremium;
}

export interface IGridConfigSetting {
  [key: string]: IGridConfigSettingItem;
}

export const GridConfigSettings: IGridConfigSetting = {
  WALMART_PROFILE: {
    SettingsKey: 'WalmartProfileGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'name'] },
      columns: {
        columnVisibilityModel: {
          platformName: false
        }
      }
    }
  },
  WALMART_CAMPAIGN: {
    SettingsKey: 'WalmartCampaignGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'isActiveInVector', 'name'] },
      columns: {
        orderedFields: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'isActiveInVector', 'name', 'status', 'startDate', 'endDate'],
        columnVisibilityModel: {
          averageDailyHoursEnabled: false,
          averageDailyHoursInBudget: false,
          averageDailyHoursOutOfBudget: false,
          averageHourlyAdSpend: false,
          averageHourlyAttributedRevenue: false,
          averageDailyMissedAdSpend: false,
          averageDailyMissedAttributedRevenue: false,
          totalHoursEnabled: false,
          totalHoursInBudget: false,
          totalHoursOutOfBudget: false
        }
      }
    }
  },
  WALMART_AD: {
    SettingsKey: 'WalmartAdGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions', 'name'] },
      columns: {
        columnVisibilityModel: {
          adId: false,
          adGroupId: false,
          campaignId: false,
          reviewStatus: false,
          itemPageUrl: false
        }
      }
    }
  },
  WALMART_KEYWORD: {
    SettingsKey: 'WalmartKeywordGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions', 'keywordText'] },
      columns: {
        columnVisibilityModel: {
          campaignId: false,
          adGroupId: false,
          keywordId: false,
          status: false,
          keywordCategory: false,
          costPerConversion: false
        }
      }
    }
  },
  WALMART_NEGATIVE_KEYWORD: {
    SettingsKey: 'WalmartNegativeKeywordGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions', 'keywordText'] },
      columns: {
        columnVisibilityModel: {
          campaignId: false,
          adGroupId: false,
          keywordId: false,
          status: false
        }
      }
    }
  },
  WALMART_PACING: {
    SettingsKey: 'WalmartPacingGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'campaignName'] },
      sorting: {
        sortModel: [{ field: 'pacing', sort: 'asc' }]
      },
      aggregation: {
        model: {
          currentMonthSpend: 'sum',
          currentAssignedBudget: 'sum',
          currentMonthBudget: 'sum',
          projectedMonthBudget: 'sum'
        }
      },
      columns: {
        columnVisibilityModel: {
          targetPacingPercentage: false,
          actualPacingPercentage: false,
          campaignId: false,
          averageDailySpend: false
        }
      }
    }
  },
  WALMART_CAMPAIGN_BUDGET: {
    SettingsKey: 'WalmartCampaignBudgetGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: ['campaignName'] },
      columns: {
        dimensions: {
          campaignName: { maxWidth: -1, minWidth: 150, width: 200 }
        }
      },
      aggregation: {
        model: {
          january: 'sumNoLabel',
          february: 'sumNoLabel',
          march: 'sumNoLabel',
          april: 'sumNoLabel',
          may: 'sumNoLabel',
          june: 'sumNoLabel',
          july: 'sumNoLabel',
          august: 'sumNoLabel',
          september: 'sumNoLabel',
          october: 'sumNoLabel',
          november: 'sumNoLabel',
          december: 'sumNoLabel',
          total: 'sumNoLabel'
        }
      }
    }
  },
  WALMART_CLIENT_BUDGET: {
    SettingsKey: 'WalmartClientBudgetGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: ['budgetItemName'] },
      columns: {
        dimensions: {
          budgetItemName: { maxWidth: -1, minWidth: 150, width: 200 }
        }
      }
    }
  },
  SAMS_CLUB_PROFILE: {
    SettingsKey: 'SamsClubProfileGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'name'] }
    }
  },
  SAMS_CLUB_CAMPAIGN: {
    SettingsKey: 'SamsClubCampaignGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'isActiveInVector', 'name'] },
      columns: {
        orderedFields: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'isActiveInVector', 'name', 'status', 'startDate', 'endDate']
      }
    }
  },
  SAMS_CLUB_AD: {
    SettingsKey: 'SamsClubAdGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions', 'name'] },
      columns: {
        columnVisibilityModel: {
          adId: false,
          adGroupId: false,
          campaignId: false,
          reviewStatus: false,
          itemPageUrl: false
        }
      }
    }
  },
  SAMS_CLUB_KEYWORD: {
    SettingsKey: 'SamsClubKeywordGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions', 'keywordText'] },
      columns: {
        columnVisibilityModel: {
          campaignId: false,
          adGroupId: false,
          keywordId: false,
          status: false,
          keywordCategory: false,
          costPerConversion: false
        }
      }
    }
  },
  SAMS_CLUB_NEGATIVE_KEYWORD: {
    SettingsKey: 'SamsClubNegativeKeywordGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions', 'keywordText'] },
      columns: {
        columnVisibilityModel: {
          campaignId: false,
          adGroupId: false,
          keywordId: false,
          status: false
        }
      }
    }
  },

  AMAZON_PROFILE: {
    SettingsKey: 'AmazonProfileGridSetting',
    DefaultSetting: {
      rowGrouping: { model: [] },
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'name'] },
      columns: {
        columnVisibilityModel: { profileId: false, costPerConversion: false, clickThroughRate: true, units: false },
        orderedFields: [
          GRID_CHECKBOX_SELECTION_COL_DEF.field,
          'name',
          'profileId',
          'cost',
          'attributedRevenue',
          'returnOnAdSpend',
          'impressions',
          'clicks',
          'clickThroughRate',
          'units',
          'orders',
          'conversionRate',
          'costPerClick',
          'newToBrandOrders',
          'newToBrandRevenue',
          'newToBrandUnits',
          'costPerConversion'
        ],
        dimensions: {
          name: { maxWidth: -1, minWidth: 50, width: 160 },
          profileId: { maxWidth: -1, minWidth: 50, width: 314, flex: 0 },
          cost: { maxWidth: -1, minWidth: 50, width: 100 },
          attributedRevenue: { maxWidth: -1, minWidth: 50, width: 100 },
          returnOnAdSpend: { maxWidth: -1, minWidth: 50, width: 100 },
          impressions: { maxWidth: -1, minWidth: 50, width: 100 },
          clicks: { maxWidth: -1, minWidth: 50, width: 100 },
          clickThroughRate: { maxWidth: -1, minWidth: 50, width: 100 },
          units: { maxWidth: -1, minWidth: 50, width: 100 },
          orders: { maxWidth: -1, minWidth: 50, width: 100 },
          conversionRate: { maxWidth: -1, minWidth: 50, width: 100 },
          costPerClick: { maxWidth: -1, minWidth: 50, width: 100 },
          newToBrandOrders: { maxWidth: -1, minWidth: 50, width: 100 },
          newToBrandRevenue: { maxWidth: -1, minWidth: 50, width: 110 },
          newToBrandUnits: { maxWidth: -1, minWidth: 50, width: 100 },
          costPerConversion: { maxWidth: -1, minWidth: 50, width: 100 }
        }
      },
      preferencePanel: { open: false },
      filter: { filterModel: { items: [], quickFilterValues: [] } },
      pagination: { paginationModel: { page: 0, pageSize: 250 } }
    }
  },
  AMAZON_CAMPAIGN: {
    SettingsKey: 'AmazonCampaignGridSetting',
    DefaultSetting: {
      rowGrouping: { model: [] },
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'active', 'name'] },
      columns: {
        columnVisibilityModel: {
          profileId: false,
          campaignId: false,
          syncError: false,
          endDate: false,
          impressions: false,
          costPerConversion: false,
          amazonSponsoredProductsDynamicBidding: false,
          clickThroughRate: false,
          orders: false,
          campaignBidOptimizationGroupId: false
        },
        orderedFields: [
          GRID_CHECKBOX_SELECTION_COL_DEF.field,
          'active',
          'name',
          'startDate',
          'endDate',
          'profileId',
          'campaignId',
          'state',
          'syncError',
          'campaignType',
          'targetingType',
          'dailyBudget',
          'cost',
          'attributedRevenue',
          'returnOnAdSpend',
          'impressions',
          'clicks',
          'clickThroughRate',
          'orders',
          'conversionRate',
          'costPerClick',
          'costPerConversion',
          'amazonSponsoredProductsDynamicBidding'
        ],
        dimensions: {
          __check__: { maxWidth: -1, minWidth: 50, width: 50 },
          active: { maxWidth: -1, minWidth: 50, width: 62 },
          name: { maxWidth: -1, minWidth: 50, width: 299.5 },
          startDate: { maxWidth: -1, minWidth: 50, width: 97.109375 },
          endDate: { maxWidth: -1, minWidth: 50, width: 140 },
          profileId: { maxWidth: -1, minWidth: 50, width: 100 },
          campaignId: { maxWidth: -1, minWidth: 50, width: 100 },
          state: { maxWidth: -1, minWidth: 50, width: 117.8671875 },
          syncError: { maxWidth: -1, minWidth: 50, width: 140 },
          campaignType: { maxWidth: -1, minWidth: 50, width: 54.7578125 },
          targetingType: { maxWidth: -1, minWidth: 50, width: 120 },
          dailyBudget: { maxWidth: -1, minWidth: 50, width: 105 },
          cost: { maxWidth: -1, minWidth: 50, width: 57.5859375 },
          attributedRevenue: { maxWidth: -1, minWidth: 50, width: 81 },
          returnOnAdSpend: { maxWidth: -1, minWidth: 50, width: 57.5859375 },
          impressions: { maxWidth: -1, minWidth: 50, width: 50 },
          clicks: { maxWidth: -1, minWidth: 50, width: 61 },
          clickThroughRate: { maxWidth: -1, minWidth: 50, width: 50 },
          orders: { maxWidth: -1, minWidth: 50, width: 66 },
          conversionRate: { maxWidth: -1, minWidth: 50, width: 92 },
          costPerClick: { maxWidth: -1, minWidth: 50, width: 57.5859375 },
          costPerConversion: { maxWidth: -1, minWidth: 50, width: 93 },
          amazonSponsoredProductsDynamicBidding: { maxWidth: -1, minWidth: 50, width: 159.5 }
        }
      },
      preferencePanel: { open: false },
      filter: { filterModel: { items: [], quickFilterValues: [] } },
      sorting: { sortModel: [{ field: 'syncError', sort: 'desc' }] },
      pagination: { paginationModel: { page: 0, pageSize: 250 } }
    }
  },
  AMAZON_CAMPAIGN_BUDGET_DIALOG: {
    SettingsKey: 'AmazonCampaignBudgetDialogGridSetting',
    DefaultSetting: {
      rowGrouping: { model: [] },
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'active', 'name'] },
      columns: {
        columnVisibilityModel: {
          profileId: false,
          campaignId: false,
          syncError: false,
          endDate: false,
          impressions: false,
          costPerConversion: false,
          amazonSponsoredProductsDynamicBidding: false,
          clickThroughRate: false,
          orders: false,
          campaignBidOptimizationGroupId: false
        },
        orderedFields: [
          GRID_CHECKBOX_SELECTION_COL_DEF.field,
          'active',
          'name',
          'startDate',
          'endDate',
          'profileId',
          'campaignId',
          'state',
          'syncError',
          'campaignType',
          'targetingType',
          'dailyBudget',
          'cost',
          'attributedRevenue',
          'returnOnAdSpend',
          'impressions',
          'clicks',
          'clickThroughRate',
          'orders',
          'conversionRate',
          'costPerClick',
          'costPerConversion',
          'amazonSponsoredProductsDynamicBidding'
        ],
        dimensions: {
          __check__: { maxWidth: -1, minWidth: 50, width: 50 },
          active: { maxWidth: -1, minWidth: 50, width: 62 },
          name: { maxWidth: -1, minWidth: 50, width: 299.5 },
          startDate: { maxWidth: -1, minWidth: 50, width: 97.109375 },
          endDate: { maxWidth: -1, minWidth: 50, width: 140 },
          profileId: { maxWidth: -1, minWidth: 50, width: 100 },
          campaignId: { maxWidth: -1, minWidth: 50, width: 100 },
          state: { maxWidth: -1, minWidth: 50, width: 117.8671875 },
          syncError: { maxWidth: -1, minWidth: 50, width: 140 },
          campaignType: { maxWidth: -1, minWidth: 50, width: 54.7578125 },
          targetingType: { maxWidth: -1, minWidth: 50, width: 120 },
          dailyBudget: { maxWidth: -1, minWidth: 50, width: 105 },
          cost: { maxWidth: -1, minWidth: 50, width: 57.5859375 },
          attributedRevenue: { maxWidth: -1, minWidth: 50, width: 81 },
          returnOnAdSpend: { maxWidth: -1, minWidth: 50, width: 57.5859375 },
          impressions: { maxWidth: -1, minWidth: 50, width: 50 },
          clicks: { maxWidth: -1, minWidth: 50, width: 61 },
          clickThroughRate: { maxWidth: -1, minWidth: 50, width: 50 },
          orders: { maxWidth: -1, minWidth: 50, width: 66 },
          conversionRate: { maxWidth: -1, minWidth: 50, width: 92 },
          costPerClick: { maxWidth: -1, minWidth: 50, width: 57.5859375 },
          costPerConversion: { maxWidth: -1, minWidth: 50, width: 93 },
          amazonSponsoredProductsDynamicBidding: { maxWidth: -1, minWidth: 50, width: 159.5 }
        }
      },
      preferencePanel: { open: false },
      filter: { filterModel: { items: [], quickFilterValues: [] } },
      sorting: { sortModel: [{ field: 'syncError', sort: 'desc' }] },
      pagination: { paginationModel: { page: 0, pageSize: 250 } }
    }
  },
  AMAZON_CAMPAIGN_BUDGET_GROUP_OVERVIEW: {
    SettingsKey: 'AmazonCampaignBudgetGroupOverviewGridSetting',
    DefaultSetting: {
      rowGrouping: { model: [] },
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'active', 'name'] },
      columns: {
        columnVisibilityModel: {
          profileId: false,
          campaignId: false,
          syncError: false,
          endDate: false,
          impressions: false,
          costPerConversion: false,
          amazonSponsoredProductsDynamicBidding: false,
          clickThroughRate: false,
          orders: false,
          campaignBidOptimizationGroupId: false
        },
        orderedFields: [
          GRID_CHECKBOX_SELECTION_COL_DEF.field,
          'active',
          'name',
          'startDate',
          'endDate',
          'profileId',
          'campaignId',
          'state',
          'syncError',
          'campaignType',
          'targetingType',
          'dailyBudget',
          'cost',
          'attributedRevenue',
          'returnOnAdSpend',
          'impressions',
          'clicks',
          'clickThroughRate',
          'orders',
          'conversionRate',
          'costPerClick',
          'costPerConversion',
          'amazonSponsoredProductsDynamicBidding'
        ],
        dimensions: {
          __check__: { maxWidth: -1, minWidth: 50, width: 50 },
          active: { maxWidth: -1, minWidth: 50, width: 62 },
          name: { maxWidth: -1, minWidth: 50, width: 299.5 },
          startDate: { maxWidth: -1, minWidth: 50, width: 97.109375 },
          endDate: { maxWidth: -1, minWidth: 50, width: 140 },
          profileId: { maxWidth: -1, minWidth: 50, width: 100 },
          campaignId: { maxWidth: -1, minWidth: 50, width: 100 },
          state: { maxWidth: -1, minWidth: 50, width: 117.8671875 },
          syncError: { maxWidth: -1, minWidth: 50, width: 140 },
          campaignType: { maxWidth: -1, minWidth: 50, width: 54.7578125 },
          targetingType: { maxWidth: -1, minWidth: 50, width: 120 },
          dailyBudget: { maxWidth: -1, minWidth: 50, width: 105 },
          cost: { maxWidth: -1, minWidth: 50, width: 57.5859375 },
          attributedRevenue: { maxWidth: -1, minWidth: 50, width: 81 },
          returnOnAdSpend: { maxWidth: -1, minWidth: 50, width: 57.5859375 },
          impressions: { maxWidth: -1, minWidth: 50, width: 50 },
          clicks: { maxWidth: -1, minWidth: 50, width: 61 },
          clickThroughRate: { maxWidth: -1, minWidth: 50, width: 50 },
          orders: { maxWidth: -1, minWidth: 50, width: 66 },
          conversionRate: { maxWidth: -1, minWidth: 50, width: 92 },
          costPerClick: { maxWidth: -1, minWidth: 50, width: 57.5859375 },
          costPerConversion: { maxWidth: -1, minWidth: 50, width: 93 },
          amazonSponsoredProductsDynamicBidding: { maxWidth: -1, minWidth: 50, width: 159.5 }
        }
      },
      preferencePanel: { open: false },
      filter: { filterModel: { items: [], quickFilterValues: [] } },
      sorting: { sortModel: [{ field: 'syncError', sort: 'desc' }] },
      pagination: { paginationModel: { page: 0, pageSize: 250 } }
    }
  },
  AMAZON_AD: {
    SettingsKey: 'AmazonAdGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field] },
      columns: {
        columnVisibilityModel: {
          amazonSponsoredProductsAsin: false,
          amazonSponsoredProductsSku: false,
          amazonSponsoredBrandsName: false,
          amazonSponsoredDisplayAdAsin: false,
          amazonSponsoredDisplayAdSku: false
        }
      }
    }
  },
  AMAZON_TARGETS: {
    SettingsKey: 'AmazonTargetsGridSetting',
    DefaultSetting: {
      rowGrouping: { model: [] },
      pinnedColumns: {
        left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions', 'name', 'searchableTargetText', 'matchType'],
        right: []
      },
      columns: {
        columnVisibilityModel: {
          syncError: false,
          targetId: false,
          adGroupId: false,
          campaignId: false,
          impressions: false,
          units: false,
          orders: false,
          clickThroughRate: false,
          costPerConversion: false,
          campaignName: false,
          adGroupName: false
        },
        orderedFields: [
          GRID_CHECKBOX_SELECTION_COL_DEF.field,
          'actions',
          'searchableTargetText',
          'matchType',
          'targetType',
          'bid',
          'state',
          'syncError',
          'campaignName',
          'targetId',
          'adGroupId',
          'campaignId',
          'adGroupName',
          'cost',
          'attributedRevenue',
          'returnOnAdSpend',
          'impressions',
          'clicks',
          'clickThroughRate',
          'units',
          'orders',
          'conversionRate',
          'costPerClick',
          'newToBrandOrders',
          'newToBrandRevenue',
          'newToBrandUnits',
          'costPerConversion'
        ],
        dimensions: {
          searchableTargetText: { maxWidth: -1, minWidth: 50, width: 300 },
          matchType: { maxWidth: -1, minWidth: 50, width: 160 },
          actions: { maxWidth: -1, minWidth: 50, width: 80, flex: 0 },
          targetType: { maxWidth: -1, minWidth: 50, width: 160, flex: 0 },
          bid: { maxWidth: -1, minWidth: 50, width: 100 },
          state: { maxWidth: -1, minWidth: 50, width: 140 },
          syncError: { maxWidth: -1, minWidth: 50, width: 140 },
          campaignName: { maxWidth: -1, minWidth: 50, width: 300, flex: 0 },
          targetId: { maxWidth: -1, minWidth: 50, width: 100 },
          adGroupId: { maxWidth: -1, minWidth: 50, width: 100 },
          campaignId: { maxWidth: -1, minWidth: 50, width: 100 },
          adGroupName: { maxWidth: -1, minWidth: 50, width: 100, flex: 0 },
          cost: { maxWidth: -1, minWidth: 50, width: 100 },
          attributedRevenue: { maxWidth: -1, minWidth: 50, width: 100 },
          returnOnAdSpend: { maxWidth: -1, minWidth: 50, width: 100 },
          impressions: { maxWidth: -1, minWidth: 50, width: 100 },
          clicks: { maxWidth: -1, minWidth: 50, width: 100 },
          clickThroughRate: { maxWidth: -1, minWidth: 50, width: 100 },
          units: { maxWidth: -1, minWidth: 50, width: 100 },
          orders: { maxWidth: -1, minWidth: 50, width: 100 },
          conversionRate: { maxWidth: -1, minWidth: 50, width: 100 },
          costPerClick: { maxWidth: -1, minWidth: 50, width: 100 },
          newToBrandOrders: { maxWidth: -1, minWidth: 50, width: 100 },
          newToBrandRevenue: { maxWidth: -1, minWidth: 50, width: 110 },
          newToBrandUnits: { maxWidth: -1, minWidth: 50, width: 100 },
          costPerConversion: { maxWidth: -1, minWidth: 50, width: 100 }
        }
      },
      preferencePanel: { open: false },
      filter: { filterModel: { items: [], quickFilterValues: [] } },
      sorting: { sortModel: [{ field: 'searchableTargetText', sort: 'desc' }] },
      pagination: { paginationModel: { page: 0, pageSize: 250 } }
    }
  },
  AMAZON_KEYWORD: {
    SettingsKey: 'AmazonKeywordGridSetting',
    DefaultSetting: {}
  },
  AMAZON_NEGATIVE_KEYWORD: {
    SettingsKey: 'AmazonNegativeKeywordGridSetting',
    DefaultSetting: {
      pinnedColumns: {
        left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions'],
        right: []
      }
    }
  },
  AMAZON_OVERVIEW_ADGROUPS: {
    SettingsKey: 'AmazonOverviewAdGroupsGridSetting',
    DefaultSetting: {
      aggregation: {
        model: {
          cost: 'sumNoLabel',
          attributedRevenue: 'sumNoLabel',
          returnOnAdSpend: 'avgNoLabel',
          impressions: 'sumNoLabel',
          clicks: 'sumNoLabel',
          clickThroughRate: 'avgNoLabel',
          units: 'sumNoLabel',
          orders: 'sumNoLabel',
          newToBrandOrders: 'sumNoLabel',
          newToBrandRevenue: 'sumNoLabel',
          newToBrandUnits: 'sumNoLabel',
          conversionRate: 'avgNoLabel',
          costPerClick: 'avgNoLabel',
          costPerConversion: 'avgNoLabel'
        }
      },
      rowGrouping: { model: [] },
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions'] },
      columns: {
        columnVisibilityModel: {
          campaignId: false,
          adGroupId: false,
          syncError: false,
          impressions: false,
          clickThroughRate: false,
          units: false,
          newToBrandOrders: false,
          newToBrandRevenue: false,
          newToBrandUnits: false,
          costPerConversion: false
        },
        orderedFields: [
          GRID_CHECKBOX_SELECTION_COL_DEF.field,
          'actions',
          'campaignId',
          'adGroupId',
          'name',
          'state',
          'syncError',
          'defaultBid',
          'cost',
          'attributedRevenue',
          'returnOnAdSpend',
          'impressions',
          'clicks',
          'clickThroughRate',
          'units',
          'orders',
          'newToBrandOrders',
          'newToBrandRevenue',
          'newToBrandUnits',
          'conversionRate',
          'costPerClick',
          'costPerConversion'
        ],
        dimensions: {
          __check__: { maxWidth: -1, minWidth: 50, width: 50 },
          actions: { maxWidth: -1, minWidth: 50, width: 62 },
          campaignId: { maxWidth: -1, minWidth: 50, width: 100 },
          adGroupId: { maxWidth: -1, minWidth: 50, width: 100 },
          name: { maxWidth: -1, minWidth: 50, width: 242.1796875 },
          state: { maxWidth: -1, minWidth: 50, width: 117.8671875 },
          syncError: { maxWidth: -1, minWidth: 50, width: 140 },
          defaultBid: { maxWidth: -1, minWidth: 50, width: 94 },
          cost: { maxWidth: -1, minWidth: 50, width: 100 },
          attributedRevenue: { maxWidth: -1, minWidth: 50, width: 100 },
          returnOnAdSpend: { maxWidth: -1, minWidth: 50, width: 100 },
          impressions: { maxWidth: -1, minWidth: 50, width: 100 },
          clicks: { maxWidth: -1, minWidth: 50, width: 100 },
          clickThroughRate: { maxWidth: -1, minWidth: 50, width: 100 },
          units: { maxWidth: -1, minWidth: 50, width: 100 },
          orders: { maxWidth: -1, minWidth: 50, width: 100 },
          newToBrandOrders: { maxWidth: -1, minWidth: 50, width: 100 },
          newToBrandRevenue: { maxWidth: -1, minWidth: 50, width: 110 },
          newToBrandUnits: { maxWidth: -1, minWidth: 50, width: 100 },
          conversionRate: { maxWidth: -1, minWidth: 50, width: 100 },
          costPerClick: { maxWidth: -1, minWidth: 50, width: 100 },
          costPerConversion: { maxWidth: -1, minWidth: 50, width: 100 }
        }
      },
      preferencePanel: { open: false },
      filter: { filterModel: { items: [], quickFilterValues: [] } },
      sorting: { sortModel: [] },
      pagination: { paginationModel: { page: 0, pageSize: 250 } }
    }
  },
  AMAZON_CAMPAIGN_OVERVIEW_ADS: {
    SettingsKey: 'AmazonCampaignOverviewAdGridSetting',
    DefaultSetting: {
      rowGrouping: { model: [] },
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions'] },
      columns: {
        columnVisibilityModel: {
          adGroupId: false,
          adId: false,
          AmazonSponsoredBrandsName: false,
          newToBrandUnits: false,
          newToBrandRevenue: false,
          newToBrandOrders: false,
          costPerConversion: false,
          units: false,
          clickThroughRate: false,
          impressions: false,
          syncError: false
        },
        orderedFields: [
          GRID_CHECKBOX_SELECTION_COL_DEF.field,
          'actions',
          'asin',
          'amazonSponsoredBrandsCreative',
          'adGroupId',
          'adId',
          'state',
          'syncError',
          'cost',
          'attributedRevenue',
          'returnOnAdSpend',
          'impressions',
          'clicks',
          'clickThroughRate',
          'units',
          'orders',
          'newToBrandOrders',
          'newToBrandRevenue',
          'newToBrandUnits',
          'conversionRate',
          'costPerClick',
          'costPerConversion',
          'AmazonSponsoredBrandsName'
        ],
        dimensions: {
          __check__: { maxWidth: -1, minWidth: 50, width: 50 },
          ad: { maxWidth: -1, minWidth: 50, width: 149.1484375 },
          adGroupId: { maxWidth: -1, minWidth: 50, width: 306.9375 },
          adId: { maxWidth: -1, minWidth: 50, width: 301.2109375 },
          state: { maxWidth: -1, minWidth: 50, width: 117.8671875 },
          syncError: { maxWidth: -1, minWidth: 50, width: 100 },
          cost: { maxWidth: -1, minWidth: 50, width: 52 },
          attributedRevenue: { maxWidth: -1, minWidth: 50, width: 81 },
          returnOnAdSpend: { maxWidth: -1, minWidth: 50, width: 56 },
          impressions: { maxWidth: -1, minWidth: 50, width: 50 },
          clicks: { maxWidth: -1, minWidth: 50, width: 61 },
          clickThroughRate: { maxWidth: -1, minWidth: 50, width: 50 },
          units: { maxWidth: -1, minWidth: 50, width: 56 },
          orders: { maxWidth: -1, minWidth: 50, width: 66 },
          newToBrandOrders: { maxWidth: -1, minWidth: 50, width: 98 },
          newToBrandRevenue: { maxWidth: -1, minWidth: 50, width: 110 },
          newToBrandUnits: { maxWidth: -1, minWidth: 50, width: 88 },
          conversionRate: { maxWidth: -1, minWidth: 50, width: 92 },
          costPerClick: { maxWidth: -1, minWidth: 50, width: 50 },
          costPerConversion: { maxWidth: -1, minWidth: 50, width: 93 },
          amazonSponsoredProductsAsin: { maxWidth: -1, minWidth: 50, width: 109.4609375 },
          amazonSponsoredProductsSku: { maxWidth: -1, minWidth: 50, width: 68 },
          AmazonSponsoredBrandsName: { maxWidth: -1, minWidth: 50, width: 124 },
          amazonSponsoredDisplayAdAsin: { maxWidth: -1, minWidth: 50, width: 72 },
          amazonSponsoredDisplayAdSku: { maxWidth: -1, minWidth: 50, width: 69 }
        }
      },
      preferencePanel: { open: false },
      filter: { filterModel: { items: [], quickFilterValues: [] } },
      sorting: { sortModel: [] },
      pagination: { paginationModel: { page: 0, pageSize: 250 } }
    }
  },
  AMAZON_AD_GROUP_OVERVIEW_ADS: {
    SettingsKey: 'AmazonAdGroupOverviewAdGridSetting',
    DefaultSetting: {
      rowGrouping: { model: [] },
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions'] },
      columns: {
        columnVisibilityModel: {
          adGroupId: false,
          adId: false,
          AmazonSponsoredBrandsName: false,
          newToBrandUnits: false,
          newToBrandRevenue: false,
          newToBrandOrders: false,
          costPerConversion: false,
          units: false,
          clickThroughRate: false,
          impressions: false,
          syncError: false
        },
        orderedFields: [
          GRID_CHECKBOX_SELECTION_COL_DEF.field,
          'actions',
          'asin',
          'amazonSponsoredBrandsCreative',
          'adGroupId',
          'adId',
          'state',
          'syncError',
          'cost',
          'attributedRevenue',
          'returnOnAdSpend',
          'impressions',
          'clicks',
          'clickThroughRate',
          'units',
          'orders',
          'newToBrandOrders',
          'newToBrandRevenue',
          'newToBrandUnits',
          'conversionRate',
          'costPerClick',
          'costPerConversion',
          'AmazonSponsoredBrandsName'
        ],
        dimensions: {
          __check__: { maxWidth: -1, minWidth: 50, width: 50 },
          ad: { maxWidth: -1, minWidth: 50, width: 149.1484375 },
          adGroupId: { maxWidth: -1, minWidth: 50, width: 306.9375 },
          adId: { maxWidth: -1, minWidth: 50, width: 301.2109375 },
          state: { maxWidth: -1, minWidth: 50, width: 117.8671875 },
          syncError: { maxWidth: -1, minWidth: 50, width: 100 },
          cost: { maxWidth: -1, minWidth: 50, width: 52 },
          attributedRevenue: { maxWidth: -1, minWidth: 50, width: 81 },
          returnOnAdSpend: { maxWidth: -1, minWidth: 50, width: 56 },
          impressions: { maxWidth: -1, minWidth: 50, width: 50 },
          clicks: { maxWidth: -1, minWidth: 50, width: 61 },
          clickThroughRate: { maxWidth: -1, minWidth: 50, width: 50 },
          units: { maxWidth: -1, minWidth: 50, width: 56 },
          orders: { maxWidth: -1, minWidth: 50, width: 66 },
          newToBrandOrders: { maxWidth: -1, minWidth: 50, width: 98 },
          newToBrandRevenue: { maxWidth: -1, minWidth: 50, width: 110 },
          newToBrandUnits: { maxWidth: -1, minWidth: 50, width: 88 },
          conversionRate: { maxWidth: -1, minWidth: 50, width: 92 },
          costPerClick: { maxWidth: -1, minWidth: 50, width: 50 },
          costPerConversion: { maxWidth: -1, minWidth: 50, width: 93 },
          amazonSponsoredProductsAsin: { maxWidth: -1, minWidth: 50, width: 109.4609375 },
          amazonSponsoredProductsSku: { maxWidth: -1, minWidth: 50, width: 68 },
          AmazonSponsoredBrandsName: { maxWidth: -1, minWidth: 50, width: 124 },
          amazonSponsoredDisplayAdAsin: { maxWidth: -1, minWidth: 50, width: 72 },
          amazonSponsoredDisplayAdSku: { maxWidth: -1, minWidth: 50, width: 69 }
        }
      },
      preferencePanel: { open: false },
      filter: { filterModel: { items: [], quickFilterValues: [] } },
      sorting: { sortModel: [] },
      pagination: { paginationModel: { page: 0, pageSize: 250 } }
    }
  },
  AMAZON_OVERVIEW_NEGATIVE_TARGETS: {
    SettingsKey: 'AmazonOverviewNegativeTargetsGridSetting',
    DefaultSetting: {
      pinnedColumns: {
        left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions'],
        right: []
      }
    }
  },
  AMAZON_OVERVIEW_CAMPAIGN_NEGATIVE_TARGETS: {
    SettingsKey: 'AmazonOverviewCampaignNegativeTargetsGridSetting',
    DefaultSetting: {
      pinnedColumns: {
        left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions'],
        right: []
      }
    }
  },
  AMAZON_ADGROUP_OVERVIEW_SEARCH_TERM_HARVESTING: {
    SettingsKey: 'AmazonAdGroupOverviewSearchTermHarvestingGridSetting',
    DefaultSetting: {
      rowGrouping: { model: [] },
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'searchTerm'] },
      columns: {
        orderedFields: [
          GRID_CHECKBOX_SELECTION_COL_DEF.field,
          'searchTerm',
          'cost',
          'attributedRevenue',
          'impressions',
          'clicks',
          'units',
          'orders',
          'newToBrandOrders',
          'newToBrandRevenue',
          'newToBrandUnits',
          'conversionRate',
          'clickThroughRate',
          'costPerClick',
          'costPerConversion',
          'returnOnAdSpend'
        ],
        dimensions: {
          searchTerm: { maxWidth: -1, minWidth: 50, width: 300 },
          cost: { maxWidth: -1, minWidth: 50, width: 100 },
          attributedRevenue: { maxWidth: -1, minWidth: 50, width: 100 },
          impressions: { maxWidth: -1, minWidth: 50, width: 100 },
          clicks: { maxWidth: -1, minWidth: 50, width: 100 },
          units: { maxWidth: -1, minWidth: 50, width: 100 },
          orders: { maxWidth: -1, minWidth: 50, width: 100 },
          newToBrandOrders: { maxWidth: -1, minWidth: 50, width: 100 },
          newToBrandRevenue: { maxWidth: -1, minWidth: 50, width: 100 },
          newToBrandUnits: { maxWidth: -1, minWidth: 50, width: 100 },
          conversionRate: { maxWidth: -1, minWidth: 50, width: 100 },
          clickThroughRate: { maxWidth: -1, minWidth: 50, width: 100 },
          costPerClick: { maxWidth: -1, minWidth: 50, width: 100 },
          costPerConversion: { maxWidth: -1, minWidth: 50, width: 100 },
          returnOnAdSpend: { maxWidth: -1, minWidth: 50, width: 100 }
        }
      },
      preferencePanel: { open: false },
      filter: { filterModel: { items: [], quickFilterValues: [] } },
      sorting: { sortModel: [] },
      pagination: { paginationModel: { page: 0, pageSize: 250 } }
    }
  },
  AMAZON_OVERVIEW_TARGETS: {
    SettingsKey: 'AmazonOverviewTargetsGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions'] }
    }
  },
  AMAZON_OVERVIEW_PLACEMENT_PERFORMANCE: {
    SettingsKey: 'AmazonOverviewPlacementPerformanceGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'placement', 'strategy', 'percentage'] }
    }
  },
  AMAZON_PRODUCTS: {
    SettingsKey: 'AmazonProductsGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'name'] }
    }
  },
  AMAZON_AUDIT_LOG_CAMPAIGN: {
    SettingsKey: 'AmazonAuditLogCampaignGridSetting',
    DefaultSetting: {
      columns: {
        columnVisibilityModel: {
          id: false,
          profileId: false,
          campaignId: false,
          adGroupId: false,
          entityName: false
        }
      }
    }
  },
  AMAZON_AUDIT_LOG_AD_GROUP: {
    SettingsKey: 'AmazonAuditLogAdGroupGridSetting',
    DefaultSetting: {
      columns: {
        columnVisibilityModel: {
          id: false,
          profileId: false,
          campaignId: false,
          adGroupId: false,
          entityName: false
        }
      }
    }
  },
  AMAZON_AUDIT_LOG_AD: {
    SettingsKey: 'AmazonAuditLogAdGridSetting',
    DefaultSetting: {
      columns: {
        columnVisibilityModel: {
          id: false,
          profileId: false,
          campaignId: false,
          adGroupId: false,
          entityName: false
        }
      }
    }
  },
  AMAZON_AUDIT_LOG_TARGET: {
    SettingsKey: 'AmazonAuditLogTargetGridSetting',
    DefaultSetting: {
      columns: {
        columnVisibilityModel: {
          id: false,
          profileId: false,
          campaignId: false,
          adGroupId: false,
          entityName: false
        }
      }
    }
  },
  AMAZON_AUDIT_LOG_NEGATIVE_TARGET: {
    SettingsKey: 'AmazonAuditLogNegativeTargetGridSetting',
    DefaultSetting: {
      columns: {
        columnVisibilityModel: {
          id: false,
          profileId: false,
          campaignId: false,
          adGroupId: false,
          entityName: false
        }
      }
    }
  },
  AMAZON_BID_GROUPS: {
    SettingsKey: 'AmazonBidGroupsGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: ['name', 'state'] },
      columns: {
        columnVisibilityModel: {
          id: false,
          [GRID_CHECKBOX_SELECTION_COL_DEF.field]: false
        }
      }
    }
  },
  AMAZON_CAMPAIGN_BID_OPTIMIZATION_GROUPS: {
    SettingsKey: 'AmazonCampaignBidOptimizationGroupsGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'active', 'name'] },
      columns: {
        columnVisibilityModel: {
          id: false,
          profileId: false,
          campaignBudgetGroupId: false,
          campaignBudgetGroupName: false,
          campaignBidOptimizationGroupId: false,
          campaignBidOptimizationGroupName: false
        }
      }
    }
  },
  BID_GROUP_TARGET_HISTORY_VIEW: {
    SettingsKey: 'BidGroupTargetHistoryViewGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: ['campaignName'] },
      sorting: {
        sortModel: [
          { field: 'createdAt', sort: 'desc' },
          { field: 'campaignName', sort: 'asc' }
        ]
      },
      columns: {
        columnVisibilityModel: {
          [GRID_CHECKBOX_SELECTION_COL_DEF.field]: false,
          campaignState: false,
          targetId: false,
          id: false,
          campaignBidOptimizationGroupId: false
        }
      }
    }
  },

  BID_GROUP_PLACEMENT_HISTORY_VIEW: {
    SettingsKey: 'BidGroupPlacementHistoryViewGridSetting',
    DefaultSetting: {
      pinnedColumns: { left: ['campaignName'] },
      sorting: {
        sortModel: [
          { field: 'createdAt', sort: 'desc' },
          { field: 'campaignName', sort: 'asc' }
        ]
      },
      columns: {
        columnVisibilityModel: {
          [GRID_CHECKBOX_SELECTION_COL_DEF.field]: false,
          campaignState: false,
          targetId: false,
          id: false,
          campaignBidOptimizationGroupId: false
        }
      }
    }
  }
};
