import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useCallback, useState } from 'react';
import { useAmazonApi } from '../../hooks/use-amazon-api-v2';
import CampaignsGridForDialog from '../budget/campaigns-grid-for-dialog';

interface AddCampaignsToBidGroupWithGridDialogProps {
  toggle: () => void;
  isShowing: boolean;
  dynamicKey: number;
  refreshCampaigns?: () => void;
}

export const AddCampaignsToBidGroupWithGridDialog = (props: AddCampaignsToBidGroupWithGridDialogProps) => {
  const { toggle, isShowing, dynamicKey, refreshCampaigns } = props;
  const { getCampaignsWithoutBidGroup } = useAmazonApi();

  const [backDropOpen, setBackDropOpen] = useState<boolean>(false);

  const handleAddCampaigns = useCallback(async () => {
    setBackDropOpen(true);

    refreshCampaigns?.();

    setBackDropOpen(false);
    toggle();
  }, [refreshCampaigns, toggle]);

  return (
    <Dialog onClose={toggle} open={isShowing} fullWidth maxWidth="lg">
      <DialogTitle>Create Bid Group</DialogTitle>
      <Divider />
      <DialogContent key={dynamicKey}>
        <CampaignsGridForDialog
          getCampaigns={getCampaignsWithoutBidGroup}
          handleAddCampaigns={handleAddCampaigns}
          isBidGroup
          gridHeight={500}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};
