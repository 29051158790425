import { Box, Container, Tab, Tabs } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid-premium';
import { useMemo, useState } from 'react';
import useAmazonApi from '../../hooks/use-amazon-api';
import AuditLogGrid from './audit-log-grid';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

const threeDaysAgo = dayjs().subtract(3, 'day').format('YYYY-MM-DD');
const today = dayjs().format('YYYY-MM-DD');

const campaignColumns: GridColDef[] = [
  { field: 'id', headerName: 'id', width: 100 },
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    width: 200,
    type: 'DateTime',
    valueFormatter: ({ value }) => new Date(value).toLocaleString()
  },
  { field: 'userEmail', headerName: 'Email', width: 200 },
  { field: 'systemReference', headerName: 'System Reference', width: 200 },
  { field: 'profileId', headerName: 'ProfileId', width: 150 },
  { field: 'profileName', headerName: 'Profile Name', width: 200 },
  { field: 'campaignId', headerName: 'CampaignId', width: 150 },
  { field: 'campaignName', headerName: 'Campaign Name', width: 200 },
  { field: 'action', headerName: 'Action', width: 100 },
  { field: 'entityName', headerName: 'Entity Name', width: 200 },
  { field: 'propertyName', headerName: 'Property Name', width: 150 },
  { field: 'oldValue', headerName: 'Old Value', width: 150 },
  { field: 'newValue', headerName: 'New Value', width: 150 }
];

const adGroupColumns: GridColDef[] = [
  ...campaignColumns,
  { field: 'adGroupId', headerName: 'AdGroupId', width: 150 },
  { field: 'adGroupName', headerName: 'AdGroup Name', width: 200 }
];

const adColumns: GridColDef[] = [...adGroupColumns, { field: 'adId', headerName: 'AdId', width: 150 }];

const targetsColumns: GridColDef[] = [...adGroupColumns, { field: 'target', headerName: 'Targets', width: 150 }];

const negativeTargetsColumns: GridColDef[] = [
  ...adGroupColumns,
  { field: 'negativeTargetId', headerName: 'Negative Target Id', width: 150 }
];

type AuditLogTypeKey = 'campaign' | 'adGroup' | 'ad' | 'targets' | 'negativeTargets';

export default function AuditLogs() {
  const [selectedTab, setSelectedTab] = useState<AuditLogTypeKey>('campaign'); // Set the default tab value to "campaign"

  const [localDateSettings, setLocalDateSettings] = useState<{ beginDate: string; endDate: string }>({
    beginDate: threeDaysAgo,
    endDate: today
  });
  const [dateSettingsLoading, setDateSettingsLoading] = useState(false);

  const { getCampaignAuditLogs, getAdGroupAuditLogs, getAdAuditLogs, getTargetsAuditLogs, getNegativeTargetsAuditLogs } =
    useAmazonApi();

  const auditLogTypes = {
    campaign: {
      label: 'Campaign',
      columns: campaignColumns,
      fetchFunction: getCampaignAuditLogs,
      gridConfigSetting: GridConfigSettings.AMAZON_AUDIT_LOG_CAMPAIGN,
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'timestamp', 'campaignName'] }
    },
    adGroup: {
      label: 'Ad Group',
      columns: adGroupColumns,
      fetchFunction: getAdGroupAuditLogs,
      gridConfigSetting: GridConfigSettings.AMAZON_AUDIT_LOG_AD_GROUP,
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'timestamp', 'adGroupName'] }
    },
    ad: {
      label: 'Ad',
      columns: adColumns,
      fetchFunction: getAdAuditLogs,
      gridConfigSetting: GridConfigSettings.AMAZON_AUDIT_LOG_AD,
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'timestamp', 'adId'] }
    },
    targets: {
      label: 'Targets',
      columns: targetsColumns,
      fetchFunction: getTargetsAuditLogs,
      gridConfigSetting: GridConfigSettings.AMAZON_AUDIT_LOG_TARGET,
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'timestamp', 'target'] }
    },
    negativeTargets: {
      label: 'Negative Targets',
      columns: negativeTargetsColumns,
      fetchFunction: getNegativeTargetsAuditLogs,
      gridConfigSetting: GridConfigSettings.AMAZON_AUDIT_LOG_NEGATIVE_TARGET,
      pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'timestamp', 'negativeTargetId'] }
    }
  };

  const selectedAuditLogTypeConfig = useMemo(() => {
    const auditLogConfig = auditLogTypes[selectedTab] || auditLogTypes.campaign;
    return {
      columns: auditLogConfig.columns,
      pinnedColumns: auditLogConfig.pinnedColumns,
      fetchFunction: auditLogConfig.fetchFunction,
      gridConfigSetting: auditLogConfig.gridConfigSetting
    };
  }, [selectedTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDateChange = async (dateRange: DateRange<Dayjs>) => {
    if (!dateRange?.[0] || !dateRange?.[1]) return;

    setDateSettingsLoading(true);

    const newDateSettings = {
      beginDate: dateRange[0].format('YYYY/MM/DD'),
      endDate: dateRange[1].format('YYYY/MM/DD')
    };

    setLocalDateSettings(newDateSettings);
    setDateSettingsLoading(false);
  };

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '1rem' }}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {Object.entries(auditLogTypes).map(([key, { label }]) => (
            <Tab key={key} label={label} value={key} />
          ))}
        </Tabs>
      </Box>
      <AuditLogGrid
        key={selectedTab}
        entityType={selectedTab || 'campaign'}
        selectedAuditLogTypeConfig={selectedAuditLogTypeConfig}
        localDateSettings={localDateSettings}
        dateSettingsLoading={dateSettingsLoading}
        handleDateChange={handleDateChange}
      />
    </Container>
  );
}
