import { Box } from '@mui/material';
import { DataGridPremium, GridAggregationModel, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { sumNoLabel } from '../../../../shared/components/grid-components/aggregationFunctions';
import VectorPerformanceGrid from '../../../../shared/components/performance-grid/vector-performance-grid';
import PlacementPerformanceChart from '../../../../shared/components/placement-performance-chart';
import useVectorPerformanceGrid from '../../../../shared/hooks/use-vector-performance-grid';
import { Pageable } from '../../../../shared/types/pageable';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import { caseInsensitiveEquals } from '../../../../shared/utilities/string-utilities';
import { PLACEMENT_PERFORMANCE_COLUMNS } from '../../../components/grid-components/column-configurations/placement-performance-columns';
import useAmazonApi from '../../../hooks/use-amazon-api';
import { BreadCrumbParams, useAmazonApi as useAmazonApi_v2 } from '../../../hooks/use-amazon-api-v2';
import {
  BackendDynamicBidding,
  BackendPlacementBidding,
  BiddablePlacements,
  Campaign,
  NonbiddablePlacements,
  StrategyType
} from '../../../types/campaign';
import { UnifiedCampaignBuilderRequest } from '../../../types/campaign-builder-request';
import { PlacementMetrics } from '../../../types/campaign-overview';

interface PlacementPerformanceRow extends PlacementMetrics {
  strategy: StrategyType | null;
  percentage: number | null;
  rowNumber: number;
}

const PLACEMENT_ORDER: { [placement: string]: number } = {
  [BiddablePlacements.TopOfSearch.value]: 0,
  [BiddablePlacements.RestOfSearch.value]: 1,
  [BiddablePlacements.ProductPages.value]: 2,
  [NonbiddablePlacements.OffSite.value]: 3
};

const buildPlacementPerformanceRows = (
  placementMetrics: PlacementMetrics[],
  campaignDynamicBidding?: BackendDynamicBidding
): PlacementPerformanceRow[] => {
  // Combine placement metrics with dynamic bidding from campaign
  const rows: PlacementPerformanceRow[] = placementMetrics.map((placementMetric, index) => {
    let placementBidding: BackendPlacementBidding | undefined = undefined;

    if (campaignDynamicBidding && campaignDynamicBidding.placementBidding && campaignDynamicBidding.placementBidding.length > 0) {
      placementBidding = campaignDynamicBidding.placementBidding.find(
        (bidding) => bidding.placement === placementMetric.placement
      );
    }

    return {
      ...placementMetric,
      strategy: campaignDynamicBidding?.strategy ?? null,
      percentage: placementBidding?.percentage ?? (placementMetric.placement in BiddablePlacements ? 0 : null),
      rowNumber: index + 1
    };
  });

  return rows;
};

interface PlacementPerformanceProps {
  campaign: Campaign;
  setCampaign: (campaign: Campaign) => void;
  refresh?: boolean;
}

export const PlacementPerformance = (props: PlacementPerformanceProps) => {
  const { campaign, setCampaign, refresh } = props;

  const { updateCampaign } = useAmazonApi();
  const { getPlacementMetrics } = useAmazonApi_v2();

  const [isLoading, setIsLoading] = useState(true);
  const [placementMetrics, setPlacementMetrics] = useState<PlacementMetrics[]>([]);
  const [placementPerformanceRows, setPlacementPerformanceRows] = useState<PlacementPerformanceRow[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState<any>([]);

  const [searchParams] = useSearchParams();
  const campaignId = useMemo(() => searchParams.get('campaignId'), [searchParams]);

  const breadcrumbParams: BreadCrumbParams[] = useMemo(() => [{ campaignId }], [campaignId]);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  } = useVectorPerformanceGrid(GridConfigSettings.AMAZON_OVERVIEW_PLACEMENT_PERFORMANCE);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchPlacementMetrics
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchPlacementMetrics = async (pageable: Pageable, beginDate: string, endDate: string) => {
    if (!breadcrumbParams) {
      return;
    }

    setIsLoading(true);

    const response = await getPlacementMetrics([...breadcrumbParams], pageable, campaignId, beginDate, endDate, true);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      setIsLoading(false);
      return;
    }

    setPlacementMetrics(response.body.dataGrid);

    const rows = buildPlacementPerformanceRows(response.body.dataGrid, campaign.amazonSponsoredProductsDynamicBidding);
    setTimeSeriesData(response.body.timeSeriesData);

    const sortedRows: PlacementPerformanceRow[] = rows
      .filter((row) => row.placement in PLACEMENT_ORDER)
      .sort((a, b) => {
        return PLACEMENT_ORDER[a.placement] - PLACEMENT_ORDER[b.placement];
      });

    setPlacementPerformanceRows(sortedRows);
    setRowCount(sortedRows.length);

    setIsLoading(false);
  };

  const processRowUpdate = useCallback(async (newRow: PlacementPerformanceRow, oldRow: PlacementPerformanceRow) => {
    if (newRow['percentage'] === oldRow['percentage']) {
      return newRow;
    }

    if (!caseInsensitiveEquals(campaign.campaignType ?? '', 'sponsoredProduct')) {
      enqueueSnackbar('Dynamic bidding is only available for Sponsored Products campaigns', { variant: 'error' });
      return oldRow;
    }

    const newDynamicBidding: BackendDynamicBidding = { ...campaign.amazonSponsoredProductsDynamicBidding };
    const placementBidding = newDynamicBidding.placementBidding.find((bidding) => bidding.placement === newRow.placement);

    if (placementBidding === undefined) {
      newDynamicBidding.placementBidding.push({
        placement: newRow.placement,
        percentage: newRow.percentage ?? 0
      });
    } else {
      placementBidding.percentage = newRow.percentage ?? 0;
    }

    const updateCampaignRequest: UnifiedCampaignBuilderRequest = {
      campaignId: campaignId ?? '',
      amazonSponsoredProductsDynamicBidding: newDynamicBidding
    };

    const response = await updateCampaign(updateCampaignRequest);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setCampaign({ ...campaign, amazonSponsoredProductsDynamicBidding: newDynamicBidding });
    enqueueSnackbar('Bid adjustment updated successfully', { variant: 'success' });

    return newRow;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refresh === null || refresh === undefined) {
      return;
    }

    handleRefreshEntities(settingsLoaded, dateSettings, apiRef, fetchPlacementMetrics);
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignContent={'center'} sx={{ my: '0.5rem' }}></Box>
      {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
        <DataGridPremium
          rows={[]}
          disableRowGrouping
          columns={PLACEMENT_PERFORMANCE_COLUMNS}
          loading={true}
          processRowUpdate={processRowUpdate}
          initialState={{}}
        />
      ) : (
        <VectorPerformanceGrid
          apiRef={apiRef}
          processRowUpdate={processRowUpdate}
          loading={isLoading}
          rows={placementPerformanceRows}
          columns={PLACEMENT_PERFORMANCE_COLUMNS}
          initialState={initialSettings?.config}
          saveGridConfig={saveGridConfig}
          dateConfig={{
            dateSettings: dateSettings,
            dateSettingsLoading: dateSettingsLoading,
            saveDateConfig: (dateRange: DateRange<Dayjs>) =>
              handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchPlacementMetrics, setDateSettings)
          }}
          getRowId={(row: PlacementPerformanceRow) => `${row.rowNumber}`}
          handleFilterModelChange={(newModel: GridFilterModel) => {
            handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchPlacementMetrics);
          }}
          handleSortModelChange={(newModel: GridSortModel) => {
            handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchPlacementMetrics);
          }}
          handlePageChange={(page: number) => {
            handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchPlacementMetrics);
          }}
          handlePageSizeChange={(pageSize: number) => {
            handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchPlacementMetrics);
          }}
          rowCount={rowCount}
          otherDataGridProps={{
            disableRowSelectionOnClick: true,
            autoHeight: true
          }}
          customProps={{
            customAggregationFunctions: {
              sumNoLabel: sumNoLabel
            },
            customAggregationModel: {
              impressions: 'sumNoLabel',
              clicks: 'sumNoLabel',
              cost: 'sumNoLabel',
              orders: 'sumNoLabel',
              attributedRevenue: 'sumNoLabel',
              units: 'sumNoLabel'
            } as GridAggregationModel
          }}
          aggregatedTotals={{
            impressions: placementMetrics.reduce(
              (totalImpressions, currentPlacement) => totalImpressions + currentPlacement.impressions,
              0
            ),
            clicks: placementMetrics.reduce((totalClicks, currentPlacement) => totalClicks + currentPlacement.clicks, 0),
            cost: placementMetrics.reduce((totalCost, currentPlacement) => totalCost + currentPlacement.cost, 0),
            attributedRevenue: placementMetrics.reduce(
              (totalRevenue, currentPlacement) => totalRevenue + currentPlacement.attributedRevenue,
              0
            ),
            clickThroughRate:
              placementMetrics.reduce((totalClicks, currentPlacement) => totalClicks + currentPlacement.clicks, 0) /
                placementMetrics.reduce(
                  (totalImpressions, currentPlacement) => totalImpressions + currentPlacement.impressions,
                  0
                ) || null,
            orders: placementMetrics.reduce((totalOrders, currentPlacement) => totalOrders + currentPlacement.orders, 0),
            conversionRate:
              placementMetrics.reduce((totalOrders, currentPlacement) => totalOrders + currentPlacement.orders, 0) /
                placementMetrics.reduce((totalClicks, currentPlacement) => totalClicks + currentPlacement.clicks, 0) || null,
            returnOnAdSpend:
              placementMetrics.reduce((totalRevenue, currentPlacement) => totalRevenue + currentPlacement.attributedRevenue, 0) /
                placementMetrics.reduce((totalCost, currentPlacement) => totalCost + currentPlacement.cost, 0) || null,
            costPerClick:
              placementMetrics.reduce((totalCost, currentPlacement) => totalCost + currentPlacement.cost, 0) /
                placementMetrics.reduce((totalClicks, currentPlacement) => totalClicks + currentPlacement.clicks, 0) || null,
            costPerConversion:
              placementMetrics.reduce((totalCost, currentPlacement) => totalCost + currentPlacement.cost, 0) /
                placementMetrics.reduce((totalOrders, currentPlacement) => totalOrders + currentPlacement.orders, 0) || null,
            advertisingCostOfSale:
              placementMetrics.reduce((totalCost, currentPlacement) => totalCost + currentPlacement.cost, 0) /
                placementMetrics.reduce(
                  (totalRevenue, currentPlacement) => totalRevenue + currentPlacement.attributedRevenue,
                  0
                ) || null,
            revenuePerClick:
              placementMetrics.reduce((totalRevenue, currentPlacement) => totalRevenue + currentPlacement.attributedRevenue, 0) /
                placementMetrics.reduce((totalClicks, currentPlacement) => totalClicks + currentPlacement.clicks, 0) || null
          }}
        />
      )}
      <PlacementPerformanceChart timeSeriesData={timeSeriesData} />
    </Box>
  );
};
