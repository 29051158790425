import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import {} from '@mui/x-data-grid';
import {
  GRID_DATETIME_COL_DEF,
  GRID_DATE_COL_DEF,
  GridCellParams,
  GridColTypeDef,
  GridEditModes,
  GridFilterInputValueProps,
  GridFilterItem,
  GridRenderEditCellParams,
  useGridApiContext,
  useGridRootProps
} from '@mui/x-data-grid-premium';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import locale from 'date-fns/locale/en-US';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

function buildApplyDateFilterFn(
  filterItem: GridFilterItem,
  compareFn: (value1: number, value2: number) => boolean,
  showTime: boolean = false
) {
  if (!filterItem.value) {
    return null;
  }

  const filterValueMs = filterItem.value.getTime();

  return ({ value }: GridCellParams<Date, any, any>): boolean => {
    if (!value) {
      return false;
    }

    // Make a copy of the date to not reset the hours in the original object
    const dateCopy = new Date(value);
    dateCopy.setHours(showTime ? value.getHours() : 0, showTime ? value.getMinutes() : 0, 0, 0);
    const cellValueMs = dateCopy.getTime();

    return compareFn(cellValueMs, filterValueMs);
  };
}

function getDateFilterOperators(showTime: boolean = false): GridColTypeDef['filterOperators'] {
  return [
    {
      value: 'is',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 === value2, showTime);
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime }
    },
    {
      value: 'not',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 !== value2, showTime);
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime }
    },
    {
      value: 'after',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 > value2, showTime);
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime }
    },
    {
      value: 'onOrAfter',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 >= value2, showTime);
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime }
    },
    {
      value: 'before',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 < value2, showTime);
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime }
    },
    {
      value: 'onOrBefore',
      getApplyFilterFn: (filterItem) => {
        return buildApplyDateFilterFn(filterItem, (value1, value2) => value1 <= value2, showTime);
      },
      InputComponent: GridFilterDateInput,
      InputComponentProps: { showTime }
    },
    {
      value: 'isEmpty',
      getApplyFilterFn: () => {
        return ({ value }): boolean => {
          return value == null;
        };
      },
      requiresFilterValue: false
    },
    {
      value: 'isNotEmpty',
      getApplyFilterFn: () => {
        return ({ value }): boolean => {
          return value != null;
        };
      },
      requiresFilterValue: false
    }
  ];
}

const dateAdapter = new AdapterDateFns({ locale });

/**
 * `date` column
 */

export const formatRawDate = (rawDate: any) => {
  if (typeof rawDate === 'string') {
    const values = rawDate.split('-');

    if (values[0] === '9999') {
      values[0] = '2099';
    }

    // put date in format mm/dd/yyyy
    return `${values[1]}/${values[2]}/${values[0]}`;
  }

  if (rawDate) {
    return dateAdapter.format(rawDate, 'keyboardDate');
  }

  return '';
};

export const dateColumnType: GridColTypeDef = {
  ...GRID_DATE_COL_DEF,
  renderEditCell: (params) => {
    return <GridEditDateCell {...params} />;
  },
  filterOperators: getDateFilterOperators(),
  valueFormatter: (params) => formatRawDate(params.value)
};

interface GridEditDateCellWithMinDate extends GridRenderEditCellParams {
  setMinimumEndDate?: React.Dispatch<React.SetStateAction<Dayjs | undefined>>;
  minDate?: Dayjs;
}

export function GridEditDateCell({ id, field, value, colDef, minDate, setMinimumEndDate }: GridEditDateCellWithMinDate) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const isDateTime = colDef.type === 'dateTime';
  const [isValidDate, setIsValidState] = useState(true);
  const Component = isDateTime ? DateTimePicker : DatePicker;

  const handleChange = (newValue: unknown) => {
    const newDayjsValue = dayjs(newValue as Date);
    setIsValidState(!newDayjsValue.isBefore(minDate, 'date'));

    if (!!setMinimumEndDate) {
      setMinimumEndDate(newDayjsValue);
    }

    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  const handleAccept = async (newValue: unknown) => {
    const isValid = await apiRef.current.setEditCellValue({ id, field, value: newValue });

    if (isValid && rootProps.editMode === GridEditModes.Cell) {
      apiRef.current.stopCellEditMode({ id, field, cellToFocusAfter: 'below' });
    }
  };

  let reformattedDate;
  if (value && typeof value === 'string' && value.includes('-')) {
    const values = value?.split('-');

    if (values[0] === '9999') {
      values[0] = '2099';
    }

    reformattedDate = `${values[1]}/${values[2]}/${values[0]}`;
  }

  return (
    <Component
      minDate={minDate}
      value={reformattedDate || value}
      onChange={handleChange}
      onAccept={handleAccept}
      slotProps={{
        textField: {
          fullWidth: true,
          autoFocus: true,
          error: !isValidDate
        } as any
      }}
    />
  );
}

function GridFilterDateInput(props: GridFilterInputValueProps & { showTime?: boolean }) {
  const { item, showTime, applyValue, apiRef } = props;

  const Component = showTime ? DateTimePicker : DatePicker;

  const handleFilterChange = (newValue: unknown) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Component
      value={item.value || null}
      onChange={handleFilterChange}
      slotProps={{
        textField: {
          variant: 'standard',
          label: apiRef.current.getLocaleText('filterPanelInputLabel')
        },
        inputAdornment: {
          sx: {
            '& .MuiButtonBase-root': {
              marginRight: -1
            }
          }
        }
      }}
    />
  );
}

/**
 * `dateTime` column
 */

const dateTimeColumnType: GridColTypeDef = {
  ...GRID_DATETIME_COL_DEF,
  resizable: false,
  renderEditCell: (params) => {
    return <GridEditDateCell {...params} />;
  },
  filterOperators: getDateFilterOperators(true),
  valueFormatter: (params) => {
    if (typeof params.value === 'string') {
      return params.value;
    }
    if (params.value) {
      return dateAdapter.format(params.value, 'keyboardDateTime');
    }
    return '';
  }
};
