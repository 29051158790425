import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import useAmazonApi from '../../../../hooks/use-amazon-api';
import { useSearchParams } from 'react-router-dom';
import ProductSearchResultsList from './product-search-results-list';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Tabs, Tab, TextField, InputAdornment, Box, Typography, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface ProductSearchContainerProps {
  negativeTargetingClauses: any;
  setNegativeTargetingClauses: (negativeTargetingClauses: any) => void;
  isCampaignNegativeTarget: boolean;
  setIsProcessing: (isProcessing: boolean) => void;
}

interface CatalogItem {
  asin: string;
  imageUrl: string;
  itemName: string;
}

export const ProductSearchContainer = (props: ProductSearchContainerProps) => {
  const { negativeTargetingClauses, setNegativeTargetingClauses, isCampaignNegativeTarget, setIsProcessing } = props;
  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [pastedSearchTerms, setPastedSearchTerms] = useState('');
  const [productSearchResults, setProductSearchResults] = useState<any[]>([]);
  const { searchCatalogItemsKeywords, searchCatalogItems } = useAmazonApi();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const [pageTokens, setPageTokens] = useState<string[]>([]);
  const [numberOfResults, setNumberOfResults] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const MAX_ASINS_PER_BATCH = 20;

  let profileId = searchParams.get('profileId');
  let campaignId = searchParams.get('campaignId');
  let adGroupId = searchParams.get('adGroupId');

  const isProductAlreadyAdded = (asin: string) => {
    return negativeTargetingClauses.some((clause: any) =>
      clause.expression.some((exp: any) => exp.type === 'AsinSameAs' && exp.value === asin)
    );
  };

  const processAsinInput = async (input: string) => {
    const rawAsins = input
      .split(/[\s,\n]+/)
      .map((asin) => asin.trim())
      .filter((asin) => asin.length > 0);

    if (rawAsins.length === 0) {
      enqueueSnackbar('No ASINs provided', { variant: 'warning' });
      return;
    }

    const validAsinRegex = /^[A-Z0-9]{10}$/;
    const invalidAsins = rawAsins.filter((asin) => !validAsinRegex.test(asin));
    const validAsins = rawAsins.filter((asin) => validAsinRegex.test(asin));

    if (invalidAsins.length > 0) {
      enqueueSnackbar(`Invalid ASIN format detected. ASINs must be 10 characters long and contain only letters and numbers.`, {
        variant: 'warning',
        persist: true
      });

      if (validAsins.length === 0) {
        return;
      }
    }

    setIsLoading(true);
    setIsProcessing(true);

    try {
      // Split ASINs into batches to handle Foreman API limits
      const asinBatches = [];
      for (let i = 0; i < validAsins.length; i += MAX_ASINS_PER_BATCH) {
        asinBatches.push(validAsins.slice(i, i + MAX_ASINS_PER_BATCH));
      }

      let totalProcessedAsins = 0;
      let totalAddedClauses = 0;
      let totalMissingAsins: string[] = [];
      let allNewClauses: any[] = [];

      for (let i = 0; i < asinBatches.length; i++) {
        const batch = asinBatches[i];
        const response = await searchCatalogItems(batch);

        if (!response.success) {
          throw new Error(response.errorMessage || 'Failed to search catalog items');
        }

        const catalogItems = response.body;

        if (Array.isArray(catalogItems) && catalogItems.length > 0) {
          const isValidCatalogItem = (item: any): item is CatalogItem => {
            return (
              item && typeof item.asin === 'string' && typeof item.itemName === 'string' && typeof item.imageUrl === 'string'
            );
          };

          const validItems = catalogItems.filter(isValidCatalogItem);
          const foundAsins = validItems.map((item) => item.asin);
          const missingAsins = batch.filter((asin) => !foundAsins.includes(asin));

          totalProcessedAsins += batch.length;
          totalMissingAsins = [...totalMissingAsins, ...missingAsins];

          const batchClauses = validItems.map((item) => ({
            negativeTargetType: 'TargetingClause',
            retailer: 'Amazon',
            campaignId: isCampaignNegativeTarget ? campaignId : undefined,
            adGroupId: !isCampaignNegativeTarget ? adGroupId : undefined,
            state: 'Enabled',
            imageUrl: item.imageUrl,
            expression: [
              {
                type: 'AsinSameAs',
                value: item.asin
              }
            ],
            resolvedExpression: [
              {
                type: 'AsinSameAs',
                value: item.itemName
              }
            ]
          }));

          allNewClauses = [...allNewClauses, ...batchClauses];
          totalAddedClauses += batchClauses.length;
        }
      }

      const existingAsins = new Set(negativeTargetingClauses.map((clause: any) => clause.expression[0].value));
      const uniqueNewClauses = allNewClauses.filter((clause) => !existingAsins.has(clause.expression[0].value));

      if (uniqueNewClauses.length > 0) {
        setNegativeTargetingClauses([...negativeTargetingClauses, ...uniqueNewClauses]);
        setPastedSearchTerms('');

        const messages = [
          `Successfully processed ${totalProcessedAsins} ASINs`,
          `Added ${uniqueNewClauses.length} new negative targets`
        ];

        if (totalProcessedAsins - uniqueNewClauses.length > 0) {
          messages.push(`${totalProcessedAsins - uniqueNewClauses.length} duplicates skipped`);
        }

        if (totalMissingAsins.length > 0) {
          messages.push(`${totalMissingAsins.length} ASINs not found`);

          if (totalMissingAsins.length <= 10) {
            enqueueSnackbar(`ASINs not found: ${totalMissingAsins.join(', ')}`, { variant: 'warning', persist: true });
          }
        }

        enqueueSnackbar(messages.join('. '), { variant: 'success', persist: true });
      } else {
        enqueueSnackbar('No new ASINs to add - all were duplicates or invalid', { variant: 'warning', autoHideDuration: 5000 });
      }
    } catch (error) {
      enqueueSnackbar(error instanceof Error ? error.message : 'Failed to process ASINs', { variant: 'error' });
    } finally {
      setIsLoading(false);
      setIsProcessing(false);
    }
  };

  const handleExcludeProduct = (product: any) => {
    if (!product?.asin || !product?.itemName) {
      enqueueSnackbar('Invalid product data', { variant: 'error' });
      return;
    }

    if (isProductAlreadyAdded(product.asin)) {
      enqueueSnackbar(`Product "${product.itemName}" is already in negative targeting`, { variant: 'warning' });
      return;
    }

    const negativeTargetingClause = {
      negativeTargetType: 'TargetingClause',
      retailer: 'Amazon',
      campaignId: isCampaignNegativeTarget ? campaignId : undefined,
      adGroupId: !isCampaignNegativeTarget ? adGroupId : undefined,
      state: 'Enabled',
      imageUrl: product.imageUrl,
      expression: [
        {
          type: 'AsinSameAs',
          value: product.asin
        }
      ],
      resolvedExpression: [
        {
          type: 'AsinSameAs',
          value: product.itemName
        }
      ]
    };

    setNegativeTargetingClauses([...negativeTargetingClauses, negativeTargetingClause]);
    enqueueSnackbar(`Added "${product.itemName}" to negative targeting`, { variant: 'success' });
  };

  const fetchProductSearchResults = async (searchTerm: string, pageToken?: string) => {
    if (!profileId) {
      enqueueSnackbar('Profile ID is required', { variant: 'error' });
      return;
    }

    setIsLoading(true);

    if (!searchTerm) {
      setProductSearchResults([]);
      setIsLoading(false);
      return;
    }

    try {
      const response = await searchCatalogItemsKeywords(searchTerm, pageToken);

      if (!response?.success) {
        throw new Error(response?.errorMessage || 'Failed to search catalog items');
      }

      if (response.body) {
        setProductSearchResults(response.body.items || []);
        setNumberOfResults(response.body.numberOfResults || 0);
        if (response.body.nextToken && !pageTokens.some((token) => token === response.body.nextToken)) {
          setPageTokens((prev) => [...prev, response.body.nextToken]);
        }
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
      enqueueSnackbar(error instanceof Error ? error.message : 'Failed to fetch search results', { variant: 'error' });
      setProductSearchResults([]);
      setNumberOfResults(0);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageBack = () => {
    if (pageTokens.length > 2) {
      const previousPageToken = pageTokens[pageTokens.length - 3];
      setPage(page - 1);
      fetchProductSearchResults(activeTab === 0 ? productSearchTerm : pastedSearchTerms, previousPageToken);
      let tokenArrayCopy = [...pageTokens];
      tokenArrayCopy.pop();
      tokenArrayCopy.pop();
      setPageTokens(tokenArrayCopy);
    } else {
      setPage(1);
      setPageTokens([]);
      fetchProductSearchResults(activeTab === 0 ? productSearchTerm : pastedSearchTerms);
    }
  };

  const handlePageForward = () => {
    setPage(page + 1);
    fetchProductSearchResults(activeTab === 0 ? productSearchTerm : pastedSearchTerms, pageTokens[pageTokens.length - 1]);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    setPastedSearchTerms(pastedText);
    processAsinInput(pastedText);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPastedSearchTerms(event.target.value);
  };

  return (
    <>
      <Tabs
        sx={{ mb: 2 }}
        value={activeTab}
        onChange={(e, newValue) => {
          setActiveTab(newValue);
          setPage(1);
          setPageTokens([]);
          setNumberOfResults(0);
          setProductSearchResults([]);
        }}
      >
        <Tab sx={{ fontSize: '12px' }} label="Search Products" />
        <Tab sx={{ fontSize: '12px' }} label="Enter List" />
      </Tabs>

      {activeTab === 0 && (
        <>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            value={productSearchTerm}
            onChange={(event) => setProductSearchTerm(event.target.value)}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                setPage(1);
                setPageTokens([]);
                setNumberOfResults(0);
                fetchProductSearchResults(productSearchTerm);
              }
            }}
            placeholder="Search by product name"
            size="small"
            fullWidth
            sx={{ mb: 1 }}
          />
          <ProductSearchResultsList
            products={productSearchResults}
            addedNegativeTargetingClauses={negativeTargetingClauses}
            handleExcludeProduct={handleExcludeProduct}
            isLoading={isLoading}
            height={298}
          />
          <Box border={1} borderColor={'divider'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            <Typography>
              {page * pageSize - pageSize + 1}-{page * pageSize} of {numberOfResults}
            </Typography>
            <IconButton disabled={page === 1} onClick={handlePageBack}>
              <ArrowBackIcon />
            </IconButton>
            <IconButton onClick={handlePageForward} disabled={page * pageSize >= numberOfResults}>
              <ArrowForwardIcon />
            </IconButton>
          </Box>
        </>
      )}

      {activeTab === 1 && (
        <>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            value={pastedSearchTerms}
            onChange={handleChange}
            onPaste={handlePaste}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                processAsinInput(pastedSearchTerms);
              }
            }}
            placeholder="Paste ASINs here"
            size="small"
            fullWidth
            multiline
            minRows={1}
            maxRows={15}
          />
        </>
      )}

      <Box>
        <Typography variant="caption" color="text.secondary" component={'div'}>
          <strong>Negative targets added: </strong>
          {negativeTargetingClauses?.length}
        </Typography>
      </Box>
    </>
  );
};
